import { Injectable } from '@angular/core';
import { LineModel } from '../../../models/Invoice/line.model';
import {BehaviorSubject, catchError, firstValueFrom, map, Observable, of} from 'rxjs';

import * as _ from 'lodash';
import { TvaTableModel } from '../../../models/builling/tva-table-model';
import { Constants } from '../../../../../environments/constants';
import { NgToastService } from 'ng-angular-popup';
import { MatDialog } from '@angular/material/dialog';
import writtenNumber from 'written-number';
import jsPDF from 'jspdf';
import { DatePipe } from '@angular/common';
import autoTable from 'jspdf-autotable';
import { StockService } from '../../backend/stock/stock.service';
import { DmsService } from '../../backend/Dms/dms.service';
import { MatTableDataSource } from '@angular/material/table';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { SerialNumberList } from 'app/shared/models/serialnumberlist';
import { TransactionStatus } from 'app/shared/enums/billing/TransactionStatus';
import { TranslocoService } from '@ngneat/transloco';
import { salaryDepositStatus } from 'app/shared/enums/Payroll/salaryDepositStatus';
import { salaryDeposit } from 'app/shared/enums/Payroll/salaryDeposit';
import {BuillingService} from "../../backend/facturation/builling.service";
import { PurchaseType } from 'app/shared/enums/billing/purchase-type_enum';
import { InvoiceNatureEnum } from 'app/shared/enums/billing/invoice-nature-enum';
import { ExportOptions } from 'app/shared/constants/exportOptions';
import { BankService } from '../bank/bank';
import { traductionLanguage } from 'app/shared/enums/language/traduction';
import { NationalitiesService } from '../nationalities/nationalities';
import html2canvas from 'html2canvas';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {environment} from "../../../../../environments/environment";
import { InvoiceTypeEnum } from 'app/shared/enums/billing/invoice-type.enum';
import { RateTypeEnum } from 'app/shared/enums/billing/rate-type-enum';
import { BonLivraisonModel } from 'app/shared/models/builling/BonLivraisonModel';
import { ConfirmationForPopupDto } from 'app/shared/models/commons/ConfirmationForPopupDto';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { ListDevises } from 'app/shared/models/data/list-devises';
import {ModuleTypeEnum} from "../../../enums/billing/ModuleTypeEnum";
import { CommunRegxService } from './commun-regx.service';
import {CancellationStatusEnum} from "../../../enums/leaves/cancellation-status-enum";
@Injectable({
    providedIn: 'root',
})
export class CommonService {
    public fineshedgenerateBR = new BehaviorSubject(null);
    product: any[] = [];
    variant: any[] = [];

    totalNetHT: number = 0;
    serialnumberlist:SerialNumberList[]=[];
    protected readonly PurchaseType = PurchaseType ;
    protected readonly InvoiceNatureEnum : InvoiceNatureEnum;
    protected readonly InvoiceTypeEnum : InvoiceTypeEnum;
    protected readonly RateTypeEnum: RateTypeEnum;
    private monthMap: { [key: string]: number } = {
        jan: 1,
        feb: 2,
        mar: 3,
        avr: 4,
        mai: 5,
        jui: 6,
        juil: 7,
        aou: 8,
        sept: 9,
        oct: 10,
        nov: 11,
        dec: 12
      };

      private nbrNotifAssuranceSubject = new BehaviorSubject<number>(null);
      public nbrNotifAssurance$ = this.nbrNotifAssuranceSubject.asObservable();

      private nbrNotifVidangeSubject = new BehaviorSubject<number>(null);
      public nbrNotifVidange$ = this.nbrNotifVidangeSubject.asObservable();

    constructor(
        private toast: NgToastService,
        public dialog: MatDialog,

        private stockService: StockService,
        private dmsService: DmsService,
        private translocoService: TranslocoService,
        private buillingService :BuillingService,
        private bankService:BankService,
        private nationalitiesService: NationalitiesService,
        private sanitizer: DomSanitizer,
        private _fuseConfirmationService: FuseConfirmationService,
        private regxService: CommunRegxService,
    ) { }

    handleSerialNumberChange(payload: SerialNumberList, serialnumberlist: SerialNumberList[]): SerialNumberList[] {
        if (!Array.isArray(serialnumberlist)) {
            serialnumberlist = [];
        }

        const exists = serialnumberlist.some(item =>
            item.minserialnumber === payload.minserialnumber && item.uuid === payload.uuid
        );

        if (!exists) {
            serialnumberlist.push(payload);
        }

        return serialnumberlist;
    }

    getSommeHorsTax(lines: LineModel[]): number {
        const somme = lines

            .filter(line => line.productname === 'line')
            .map(line => Number(line.prixnet))
            .reduce((a, b) => a + b);
        return somme;
    }

    getTableData(form: any): { [key: string]: { fieldValue: any, formDataId: string, formFieldId: string } } {
        const dataMap: { [key: string]: any } = {};
        const startIndex = form.pageIndex * form.pageSize;
        const endIndex = startIndex + form.pageSize;

        if (!form.isFiltering) {
          form.formDataList.slice(startIndex, endIndex).forEach((fieldData: any) => {
            fieldData.formFieldDataList.forEach((el: any) => {

              if (!dataMap[el.formData]) {
                dataMap[el.formData] = { fieldValue: {}, formDataId: el.formData, formFieldId: el.id };
              }
              const fieldName = form.formFieldList.find((field: any) => field.id === el.formField)?.fieldName;
              if (fieldName) {
                dataMap[el.formData].fieldValue[fieldName] = el.fieldValue;
              }
            });

            form.formFieldList.forEach((formField: any) => {
              const existingField = fieldData.formFieldDataList.find((el: any) => el.formField === formField.id);
              if (!existingField) {
                const newFormFieldData = {
                  id: null,
                  uuid: null,
                  fieldValue: null,
                  formField: formField.id,
                  formData: fieldData.id
                };
                fieldData.formFieldDataList.push(newFormFieldData);
                const fieldName = formField.fieldName;
                if (!dataMap[fieldData.id]) {
                  dataMap[fieldData.id] = { fieldValue: {}, formDataId: fieldData.id, formFieldId: newFormFieldData.formField };
                }
                dataMap[fieldData.id].fieldValue[fieldName] = null;
              }
            });
          });

          form.totalItems = form.formDataList.length;
        }

        return dataMap;
    }

    generateCode(res: any): void {
        if (res && typeof res.pdfCode === 'string') {
            const binaryString = window.atob(res.pdfCode);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            const blob = new Blob([bytes], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'barcode.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } else {
            console.error('Invalid Base64 data');
        }
    }
    calculeTTC(lines): number {
        let prixtotalGlobal = 0;

        lines.forEach((line) => {
            let prixnet = line.prixunitaire * line.quantite;
            if (line.remise) {
                prixnet -= prixnet * (line.remise / 100);
            }
            let prixtotalLigne = prixnet;
            if (line.tvaAmount) {
                prixtotalLigne += prixnet * (line.tvaAmount / 100);
            }
            prixtotalGlobal += prixtotalLigne;
        });
        return prixtotalGlobal;
    }
    calculeTTCForAvoir(lines): number {
        let prixtotalGlobal = 0;

        lines.forEach((line) => {
            let prixnet = line.prixunitaire * line.returnedQuantity;

            if (line.remise) {
                prixnet -= prixnet * (line.remise / 100);
            }

            let prixtotalLigne = prixnet;

            if (line.tvaAmount) {
                prixtotalLigne += prixnet * (line.tvaAmount / 100);
            }

            prixtotalGlobal += prixtotalLigne;
        });
        return prixtotalGlobal;
    }

    getProduct(lines: LineModel[]) {
        lines.forEach((element) => {
            if (element.uuidProduct != null) {
                this.stockService
                    .getProductOrService(element.uuidProduct)
                    .subscribe((response) => {
                        element.product = response;
                    });
            }
        });
    }
    getVariant(lines) {
        lines.forEach((element) => {
            if (element.uuidVariant != null) {
                this.stockService
                    .getVariantByUuid(element.uuidVariant)
                    .subscribe((response) => {
                        element.variant = response;
                    });
            }
        });
    }
    getSommeHorsTaxWithRemise(lines: LineModel[]): number {
        let somme = 0;
        lines.forEach((element) => {
            somme +=
                element.prixunitaire * element.quantite -
                element.prixunitaire *
                    element.quantite *
                    (element.remise / 100);
        });
        return somme;
    }
    exportPdfCapture(element:HTMLElement,fileName:string){
        html2canvas(element, { useCORS: true, scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');

            const imgWidth = 210;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            pdf.save(fileName);
        });

    }
    getSommeHorsTaxWithRemiseForAvoir(lines: LineModel[]): number {
        let somme = 0;
        lines.forEach((element) => {
            somme +=
                element.prixunitaire * element.returnedQuantity -
                element.prixunitaire *
                    element.returnedQuantity *
                    (element.remise / 100);
        });
        return somme;
    }
    translatedNationality(collabSavedNationality:string,currentLanguage:traductionLanguage|string){
        const isfrenchSavedNationality = this.nationalitiesService.nationalities.includes(collabSavedNationality)

        //returning TRANSLATED field value
        if (isfrenchSavedNationality && currentLanguage !== traductionLanguage.FR) {
            const nationalityIndex = this.nationalitiesService.nationalities.indexOf(collabSavedNationality)
            return this.nationalitiesService.arabNationalities[nationalityIndex]
        }
        else if (!isfrenchSavedNationality && currentLanguage === traductionLanguage.FR) {
            const nationalityIndex = this.nationalitiesService.arabNationalities.indexOf(collabSavedNationality)
            return this.nationalitiesService.nationalities[nationalityIndex]
        }
        return collabSavedNationality
    }

    getSommeTVA(lines: LineModel[]): number {
        const somme = lines
            .filter((line) => line.productname === 'line')
            .map((line) => Number(line.prixtotal))
            .reduce((a, b) => a + b, 0); // Provide 0 as the initial value
        return somme;
    }
    getTVA(lines: LineModel[]): number {
        const somme = lines
            .filter((line) => line.productname === 'line')
            .map((line) => Number(line.tvaAmount))
            .reduce((a, b) => a + b, 0); // Provide 0 as the initial value
        return somme;
    }
    definedBankSort(bankList: { [key: string]: string }) {
        //sorting the BZ as first bank
        const banksList = Object.entries(bankList);

        const sortedEntries = banksList.sort(([keyA], [keyB]) => {
            if (keyA === 'BZ') return -1;
            if (keyB === 'BZ') return 1;
            return 0;
        });
        return sortedEntries
    }
    bankOrder(currentLanguage:traductionLanguage|string,origin : string){
        //Sorting TRANSLATED Banks the way Zitouna 's the first
        let banksList;
        switch (currentLanguage) {
            case traductionLanguage.FR:
                banksList = this.bankService.getBanksByOrigin(origin);
                break;
            case traductionLanguage.AR:
                banksList = this.bankService.arabBanks;
                break;
            case traductionLanguage.EN:
                banksList = this.bankService.tunisianBanksInEnglish;
                break;
        }
        return this.definedBankSort(banksList)
    }

    convertTotalToWords(word, deviseInvoice) {
        const wordtnd = word.toString().split('.')[0];
        const wordmil = word.toString().split('.')[1];
        const wordentnd = writtenNumber(wordtnd, { lang: 'fr' });
        const wordenmil = writtenNumber(wordmil, { lang: 'fr' });
        if (deviseInvoice.devise === 'USD') {
            return wordentnd + ' Dollars et ' + wordenmil + ' Cents ';
        } else if (deviseInvoice.devise === 'EUR') {
            return wordentnd + ' Euros et ' + wordenmil + ' Centimes ';
        } else {
            return wordentnd + ' Dinars et ' + wordenmil + ' Millimes ';
        }
    }
    splitLines(linestotallist: LineModel[], model?: number) {
        this.product = [];
        this.variant = [];
        this.getProduct(linestotallist);
        this.getVariant(linestotallist);
        let filtredLine = linestotallist.filter((line) => {
            return line.productname == 'line';
        });
        let listTVAtable = this.generateTableTvaRemise(filtredLine);
        let paginatedArray = [];
        for (
            let i = 0;
            i < linestotallist.length;
            i += model == 3 ? 30 : Constants.DISPLAY_PAGE_SIZE
        ) {
            const page = linestotallist.slice(
                i,
                i + (model == 3 ? 30 : Constants.DISPLAY_PAGE_SIZE)
            );
            paginatedArray.push(page);
        }
        return [listTVAtable, paginatedArray];
    }

    splitLinesForAvoir(linestotallist: LineModel[], model?: number) {
        this.product = [];
        this.variant = [];
        this.getProduct(linestotallist);
        this.getVariant(linestotallist);
        let filtredLine = linestotallist.filter((line) => {
            return line.productname == 'line';
        });
        let listTVAtable = this.generateTableTvaRemiseForAvoir(filtredLine);
        let paginatedArray = [];
        for (
            let i = 0;
            i < linestotallist.length;
            i += model == 3 ? 30 : Constants.DISPLAY_PAGE_SIZE
        ) {
            const page = linestotallist.slice(
                i,
                i + (model == 3 ? 30 : Constants.DISPLAY_PAGE_SIZE)
            );
            paginatedArray.push(page);
        }
        return [listTVAtable, paginatedArray];
    }
    generateTableTva(filtredLine: LineModel[]) {
        let listTVAtable: Array<TvaTableModel> = [];
        var groupedLinesByTva = _.groupBy(
            filtredLine,
            (line) => line.tvaAmount
        );
        Object.entries(groupedLinesByTva).forEach((el) => {
            let tvaTable = new TvaTableModel(0, 0, 0, 0);
            tvaTable.taux = el[0];
            el[1].forEach((line) => {
                tvaTable.base += line.prixnet;
            });
            tvaTable.montant = (tvaTable.base * Number(el[0])) / 100;
            listTVAtable.push(tvaTable);
        });
        return listTVAtable;
    } //End of Invoice Models Common Part

    generateTableTvaRemise(filtredLine: LineModel[]) {
        let listTVAtable: Array<TvaTableModel> = [];
        var groupedLinesByTva = _.groupBy(
            filtredLine,
            (line) => line.tvaAmount
        );
        Object.entries(groupedLinesByTva).forEach((el) => {
            let tvaTable = new TvaTableModel(0, 0, 0, 0);
            tvaTable.taux = el[0];
            el[1].forEach((line) => {
                if (line.remise == null) {
                    line.remise = 0;
                    tvaTable.base +=
                        line.prixnet - (line.prixnet * line.remise) / 100;
                } else {
                    tvaTable.base +=
                        line.prixnet - (line.prixnet * line.remise) / 100;
                }
            });
            tvaTable.montant = (tvaTable.base * Number(el[0])) / 100;
            listTVAtable.push(tvaTable);
        });
        return listTVAtable;
    }
    generateTableTvaRemiseForAvoir(filtredLine: LineModel[]) {
        let listTVAtable: Array<TvaTableModel> = [];
        var groupedLinesByTva = _.groupBy(
            filtredLine,
            (line) => line.tvaAmount
        );
        Object.entries(groupedLinesByTva).forEach((el) => {
            let tvaTable = new TvaTableModel(0, 0, 0, 0);
            tvaTable.taux = el[0];
            el[1].forEach((line) => {
                if (line.remise == null) {
                    line.remise = 0;
                    tvaTable.base +=
                        line.prixunitaire * line.returnedQuantity -
                        (line.prixunitaire *
                            line.returnedQuantity *
                            line.remise) /
                            100;
                } else {
                    tvaTable.base +=
                        line.prixunitaire * line.returnedQuantity -
                        (line.prixunitaire *
                            line.returnedQuantity *
                            line.remise) /
                            100;
                }
            });
            tvaTable.montant = (tvaTable.base * Number(el[0])) / 100;
            listTVAtable.push(tvaTable);
        });
        return listTVAtable;
    }

    TransfererBCommande(invoice, invoiceType, status, type, factType) {
        const BonCommandeModel = {
            color: invoice.color,
            customer: invoice.customer,
            customerAdress: invoice.customerAdress,
            customerRib: invoice.customerRib,
            deliveryDate: invoice.deliveryDate,
            devise: invoice.devise,
            dueDate: invoice.dueDate,
            invoiceDate: invoice.invoiceDate,
            invoiceNumber: '',
            invoicetype: invoiceType,
            isdeleted: invoice.isdeleted,
            langue: invoice.langue,
            left_to_pay: invoice.left_to_pay,
            lineModels: invoice.lineModels,
            model: invoice.model,
            note: invoice.note,
            partner: invoice.partner,
            partnerAdress: invoice.partnerAdress,
            partnerRIB: invoice.partnerRIB,
            purchaseType: invoice.purchaseType,
            timbre: invoice.timbre,
            totalHtt: invoice.totalHtt,
            totalTtc: invoice.totalTtc,
            tva: invoice.tva,
            status: status,
            taxList: invoice.taxList,
            uuid: '',
            id: null,
            devis: factType == 'devis' ? invoice.uuid : null,
            has_purshase: true,
            type: type,
            remise: invoice.remise,
            taxes: invoice.taxes,
            isPartial: invoice.isPartial,
            paymentStatus : invoice.paymentStatus,
            descriptionInvoice : invoice.descriptionInvoice,
            currencyId : invoice.currencyId,
            realNumber : invoice.realNumber,
        };
        return BonCommandeModel;
    }

    TransfererBLivraison(invoice, invoiceType, status, type, factType) {
        return {
            color: invoice.color,
            customer: invoice.customer,
            customerAdress: invoice.customerAdress,
            customerRib: invoice.customerRib,
            deliveryDate: invoice.deliveryDate,
            devise: invoice.devise,
            dueDate: invoice.dueDate,
            invoiceDate: invoice.invoiceDate,
            invoiceNumber: '',
            uuidCollaborator: invoice.uuidCollaborator,
            invoicetype: invoiceType,
            isdeleted: invoice.isdeleted,
            langue: invoice.langue,
            left_to_pay: invoice.left_to_pay,
            lineModels: invoice.lineModels,
            model: invoice.model,
            note: invoice.note,
            partner: invoice.partner,
            partnerAdress: invoice.partnerAdress,
            partnerRIB: invoice.partnerRIB,
            purchaseType: invoice.purchaseType,
            taxList: invoice.taxList,
            timbre: invoice.timbre,
            totalHtt: invoice.totalHtt,
            totalTtc: invoice.totalTtc,
            tva: invoice.tva,
            status: status,
            paymentStatus:
                invoice.paymentStatus != null
                    ? invoice.paymentStatus
                    : 'NON_PAYE',
            uuid: '',
            id: null,
            devis: factType == 'devis' ? invoice.uuid : null,

            purshase_order: factType == InvoiceNatureEnum.BON_COMMANDE ? invoice.uuid : null,
            has_invoice: invoice.has_invoice,
            has_delivery: true,
            type: type,
            remise: invoice.remise,
            taxes: invoice.taxes,
            isPartial: invoice.partial,
            descriptionInvoice : invoice.descriptionInvoice,
            currencyId : invoice.currencyId,
            realNumber : invoice.realNumber,
        };
    }

    TransfererInvoice(invoice, invoiceType, status, type, factType) {
        let deliveryList = [];
        let purshaseList = [];
        let devisList = [];
        if (factType == InvoiceNatureEnum.BON_LIVRAISON) {
            deliveryList.push(invoice.uuid);
            devisList.push(invoice.uuid);
        }

        if (factType == InvoiceNatureEnum.BON_COMMANDE) {
            purshaseList.push(invoice.uuid);
            devisList.push(invoice.uuid);
        }
        if (factType == InvoiceNatureEnum.DEVIS) {
            devisList.push(invoice.uuid);
        }
        return {
            color: invoice.color,
            customer: invoice.customer,
            customerAdress: invoice.customerAdress,
            customerRib: invoice.customerRib,
            deliveryDate: invoice.deliveryDate,
            devise: invoice.devise,
            dueDate: invoice.dueDate,
            invoiceDate: invoice.invoiceDate,
            invoiceNumber: '',
            invoicetype: invoiceType,
            isdeleted: invoice.isdeleted,
            langue: invoice.langue,
            left_to_pay: invoice.left_to_pay,
            lineModels: invoice.lineModels,
            model: invoice.model,
            note: invoice.note,
            partner: invoice.partner,
            partnerAdress: invoice.partnerAdress,
            partnerRIB: invoice.partnerRIB,
            purchaseType: invoice.purchaseType,
            timbre: invoice.timbre,
            totalHtt: invoice.totalHtt,
            totalTtc: invoice.totalTtc,
            tva: invoice.tva,
            status: status,
            uuid: '',
            id: null,
            taxList: invoice.taxList,
            devis: factType == InvoiceNatureEnum.DEVIS ? invoice.uuid : null,
            purshaseOrder: factType ==InvoiceNatureEnum.BON_COMMANDE ? invoice.uuid : null,
            purshaseOrderList: factType == InvoiceNatureEnum.BON_COMMANDE ? purshaseList : null,
            deliveryList: factType == InvoiceNatureEnum.BON_LIVRAISON ? deliveryList : null,
            devisList: factType == InvoiceNatureEnum.DEVIS ? devisList : null,
            has_invoice: true,
            has_delivery: invoice.has_delivery,
            has_Mouvement: type == "STOCK" && factType != InvoiceNatureEnum.BON_LIVRAISON ? false : true,
            type: type,
            remise: invoice.remise ?? 0,
            taxes: invoice.taxes,
            isPartial: invoice.partial,
            pdfGenerationType : null,
            realNumber : invoice.realNumber,
            descriptionInvoice : invoice.descriptionInvoice,
            currencyId : invoice.currencyId
        };
    }


    //Generate Template for Retenue Source
    generateSpecificDocRetenueSource(invoice, partner, partnerAddress, montantNet, fournisseur, defaultFournisseurAddress) {
        const font = Constants.Times_Font
        const doc = new jsPDF({
            orientation: 'p',
            unit: 'pt',
            format: 'a4',
            putOnlyUsedFonts: true
        });
        doc.addFileToVFS("Times-Regular.ttf", font)
        doc.addFont("Times-Regular.ttf", "Times", "normal")

        doc.setFont("Times", "bold")
        doc.setFontSize(9);
        doc.text("REPUBLIQUE TUNISIENNE", 160, 60, {
            maxWidth: 200,
            align: 'center'
        })
        doc.text("MINISTERE DU PLAN ET DES FINANCES DIRECTION GENERALE DU CONTROLE FISCAL", 160, 75, {
                maxWidth: 200,
                align: 'center',
            lineHeightFactor: 1.6
        })

        doc.setFont("Times", "bold")
        doc.setFontSize(10);
        doc.text("CERTIFICAT DE RETENUE D'IMPOT SUR LE REVENU OU D'IMPORT SUR LES SOCIETES", 430, 65, {
                maxWidth: 180,
                align: 'center',
            lineHeightFactor: 1.5
        })

        doc.setFont("Times", "normal")
        doc.setFontSize(10);
        doc.text(`Retenue effectuée le : ${new DatePipe('fr-FR').transform(invoice.invoice.invoiceDate, "dd/MM/yyyy")}`,
            doc.internal.pageSize.width / 2, 140,
            {
                align: 'center',
            })

        doc.setLineWidth(2.5)
        doc.rect(60, 180, 475, 160, 'S')

        doc.setFont("Times", "bold")
        doc.setFontSize(10);
        doc.text("A - PERSONNE OU ORGANISME PAYEUR : ", 65, 205)

        doc.setFont("Times", "normal")
        doc.setFontSize(10);
        doc.text("IDENTIFIANT", 375, 205)

        autoTable(doc, {
            styles: { font: "Times", fontSize: 10, halign: 'center' },
            startY: 210,
            margin: { left: 290 },
            columnStyles: {
                0: { cellWidth: 60, valign: 'middle', lineColor: [0, 0, 0], lineWidth: 1.2 },
                1: { cellWidth: 60, valign: 'middle', lineColor: [0, 0, 0], lineWidth: 1.2 },
                2: { cellWidth: 60, valign: 'middle', lineColor: [0, 0, 0], lineWidth: 1.2 },
                3: { cellWidth: 60, valign: 'middle', lineColor: [0, 0, 0], lineWidth: 1.2 }
            },
            body: [
                ['Matricule Fiscal', 'Code T.V.A', 'Code Catégorie', 'N° Etab. Sécondaire'],
                //[{content: "A",styles : {fontStyle :"bold"}}]
                [partner.patent, "--", "--", "--"]
            ],
            theme: 'grid'
        })

        doc.text("Dénomination de la personne ou de l'organisme payeur :", 65, 290)

        doc.setFont("Times", "bold")
        doc.text(`Société ${partner?.company_name}`, 295, 290, {
            maxWidth: 240
        })

        doc.setFont("Times", "normal")
        doc.text(`Adresse : ${partnerAddress?.text}`, 65, 315)

        doc.rect(60, 340, 475, 30, 'S')

        doc.setFont("Times", "bold")
        doc.setFontSize(10);
        doc.text("B - RETENUES EFFECTUEES SUR : ", 65, 357)

        doc.rect(465, 340, 70, 30, 'S')
        doc.rect(395, 340, 70, 30, 'S')
        doc.rect(325, 340, 70, 30, 'S')

        doc.setFont("Times", "normal")
        doc.text("MONTANT BRUT", 360, 352, {
            maxWidth: 65,
            align: "center"
        })

        doc.text(`RETENUE ${invoice.retenue.percentage}%`, 430, 352, {
            maxWidth: 65,
            align: "center"
        })

        doc.text("MONTANT NET", 500, 352, {
            maxWidth: 65,
            align: 'center',
        });

        doc.rect(60, 370, 475, 100, 'S');

        doc.text(
            '- Honoraire, commissions, courtages, vacations et loyers .......',
            67,
            385,
            {
                maxWidth: 250,
            }
        );
        doc.text(
            `- Factures N°${invoice.invoice.invoiceNumber} R-S ${invoice.retenue.percentage}%`,
            67,
            400,
            {
                maxWidth: 250,
            }
        );
        doc.text(
            "- Revenus des comptes spéciaux d'eparne ouverts auprés des banques.",
            67,
            415,
            {
                maxWidth: 250,
            }
        );
        doc.text('- Revenus des capitaux mobilers .......', 67, 445, {
            maxWidth: 250,
        });
        doc.text('- Revenus des bons de caisse au porteur ......', 67, 460, {
            maxWidth: 250,
        });

        doc.rect(465, 370, 70, 100, 'S');
        doc.rect(395, 370, 70, 100, 'S');
        doc.rect(325, 370, 70, 100, 'S');

        doc.text(
            `${invoice.invoice.totalTtc.toFixed(3).replace('.', ',')}`,
            360,
            400,
            {
                maxWidth: 65,
                align: 'center',
            }
        );

        doc.text(
            `${invoice.retenue.amount.toFixed(3).replace('.', ',')}`,
            430,
            400,
            {
                maxWidth: 65,
                align: 'center',
            }
        );

        doc.text(`${montantNet.toFixed(3).replace('.', ',')}`, 500, 400, {
            maxWidth: 65,
            align: 'center',
        });

        doc.rect(60, 470, 475, 30, 'S');

        doc.rect(465, 470, 70, 30, 'S');
        doc.rect(395, 470, 70, 30, 'S');
        doc.rect(325, 470, 70, 30, 'S');

        doc.setFont('Times', 'bold');
        doc.text('Total Général', 260, 487);
        doc.text(
            `${invoice.invoice.totalTtc.toFixed(3).replace('.', ',')}`,
            360,
            487,
            {
                maxWidth: 65,
                align: 'center',
            }
        );

        doc.text(
            `${invoice.retenue.amount.toFixed(3).replace('.', ',')}`,
            430,
            487,
            {
                maxWidth: 65,
                align: 'center',
            }
        );

        doc.text(`${montantNet.toFixed(3).replace('.', ',')}`, 500, 487, {
            maxWidth: 65,
            align: 'center',
        });

        doc.rect(60, 500, 475, 175, 'S');

        doc.text('C - BENEFICIAIRE :', 65, 525);

        doc.setFont('Times', 'normal');
        doc.text(
            "N° de la carte d'identité ou de séjour pour les étrangers",
            67,
            550,
            {
                maxWidth: 110,
            }
        );

        doc.setLineWidth(1);
        doc.rect(185, 545, 70, 15);

        doc.text('IDENTIFIANT', 375, 525);

        autoTable(doc, {
            styles: { font: 'Times', fontSize: 10, halign: 'center' },
            startY: 530,
            margin: { left: 290 },
            columnStyles: {
                0: {
                    cellWidth: 60,
                    valign: 'middle',
                    lineColor: [0, 0, 0],
                    lineWidth: 1.2,
                },
                1: {
                    cellWidth: 60,
                    valign: 'middle',
                    lineColor: [0, 0, 0],
                    lineWidth: 1.2,
                },
                2: {
                    cellWidth: 60,
                    valign: 'middle',
                    lineColor: [0, 0, 0],
                    lineWidth: 1.2,
                },
                3: {
                    cellWidth: 60,
                    valign: 'middle',
                    lineColor: [0, 0, 0],
                    lineWidth: 1.2,
                },
            },
            body: [
                [
                    'Matricule Fiscal',
                    'Code T.V.A',
                    'Code Catégorie',
                    'N° Etab. Sécondaire',
                ],
                //[{content: "A",styles : {fontStyle :"bold"}}]
                ['--', '--', '--', '--'],
            ],
            theme: 'grid',
        });

        doc.text(
            `Nom et Prénoms ou Raison Sociale : ${
                fournisseur?.name + ' ' + fournisseur?.alternative_Name
            }`,
            65,
            600,
            {
                maxWidth: 250,
            }
        );

        doc.text(`Adresse professionnelle : -- / --`, 65, 625, {
            maxWidth: 350,
        });

        doc.text(`Adresse de Résidence : `, 65, 650, {
            maxWidth: 350,
        });

        doc.setLineWidth(2.5);
        doc.rect(60, 675, 475, 125, 'S');

        doc.text(
            "Je soussigné, certifie exacts les renseignements figurant sur le présent certificat et m'expose aux sanctions prévues par la loi pour toute inexactitude",
            doc.internal.pageSize.width / 2,
            700,
            {
                align: 'center',
                maxWidth: doc.internal.pageSize.width / 1.7,
            }
        );

        doc.setFont('Times', 'bold');
        doc.text(
            `A ${partnerAddress?.country.toUpperCase()} le : ${new DatePipe(
                'fr-FR'
            ).transform(invoice.retenue.created, 'dd/MM/yyyy')}`,
            365,
            745,
            {
                align: 'center',
            }
        );

        doc.text('Cachet et signature du payeur', 350, 770);

        //window.open(doc.output('bloburl'))

        doc.save(`RS_Factures N°${invoice.invoice.invoiceNumber}.pdf`);
    }
    initTab(
        isDevisChecked,
        isBonCommandeChecked,
        isBonLivraisonChecked,
        isFactureChecked,
        selectedTab,
        disponible
    ) {
        switch (true) {
            case isDevisChecked:
                selectedTab = 0;
                break;
            case isBonCommandeChecked:
                selectedTab = 1;
                break;
            case isBonLivraisonChecked:
                selectedTab = 2;
                break;
            case isFactureChecked:
                selectedTab = 3;
                break;
            default:
                disponible = false;
                break;
        }
    }

    formatLabel(value: number): string {
        if (value >= 1000) {
            return Math.round(value / 1000) + 'k';
        }
        return `${value}`;
    }
    exportToCSV(dataToExport: any[]): void {
        const TicketExcel = dataToExport.map((ticket) => ({
            reference: ticket.reference,
            title: ticket.title,
            url: ticket.url,
            description: this.convertDescription(ticket.description),
            type: this.convertType(ticket.type),
            status: this.convertStatus(ticket.status),
            priority: this.convertPriority(ticket.priority),
            category: this.convertCategory(ticket.category),
            erpModule: this.convertErpModule(ticket.erpModule),
        }));

        const csvData = this.convertToCSV(TicketExcel);

        if (csvData) {
            const blob = new Blob([csvData], {
                type: 'text/csv;charset=utf-8;',
            });
            const fileName = 'Tickets.csv';
            const link = document.createElement('a');

            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                alert(
                    'Cliquez sur le lien suivant pour télécharger le fichier : ' +
                        fileName
                );
            }
        } else {
            this.toast.error({
                detail: "Erreur! Vous n'avez aucun Ticket",
                duration: 5000,
            });
        }
    }

    convertToCSV(data: any[]) {
        const separator = ';';
        if (data.length != 0) {
            const header = Object.keys(data[0]);
            const csv = [header.join(separator)];

            for (const row of data) {
                const values = header.map((key) => {
                    let value = row[key];
                    if (typeof value === 'string') {
                        value = value.replace(/"/g, '""');
                        if (value.includes(separator)) {
                            value = `"${value}"`;
                        }
                    }
                    return value;
                });
                csv.push(values.join(separator));
            }

            return csv.join('\n');
        }
    }

    exportToPDF(dataToExport: any[]): void {
        const doc = new jsPDF('l', 'mm', 'a4');

        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');

        this.generatePDF(doc, dataToExport);
    }

    generatePDF(doc, dataToExport) {
        if (dataToExport && dataToExport.length > 0) {
            const headers = [
                'reference',
                'title',
                'url',
                'description',
                'type',
                'status',
                'priority',
                'category',
                'erpModule',
            ];
            const tableData = dataToExport.map((ticket) =>
                this.createTableRow(ticket)
            );
            autoTable(doc, {
                columnStyles: {},
                body: tableData,
                columns: headers.map((header) => {
                    return {
                        header: header,
                        dataKey: header,
                    };
                }),
            });

            doc.save('Tickets.pdf');
        } else {
        }
    }

    createTableRow(ticket) {
        const maxDescriptionLength = 20;
        const truncatedDescription = this.truncateField(
            ticket.description,
            maxDescriptionLength
        );

        return {
            reference: ticket.reference,
            title: ticket.title,
            url: ticket.url,
            description: this.convertDescription(truncatedDescription),
            type: this.convertType(ticket.type),
            status: this.convertStatus(ticket.status),
            priority: this.convertPriority(ticket.priority),
            category: this.convertCategory(ticket.category),
            erpModule: this.convertErpModule(ticket.erpModule),
        };
    }

    truncateField(field: string, maxLength: number) {
        return field.length > maxLength
            ? `${field.substring(0, maxLength)}...`
            : field;
    }

    convertDescription(description) {
        return description.replace(/<p>|<\/p>/g, '');
    }

    convertErpModule(erpModule) {
        switch (erpModule) {
            case 'SIRH':
                return 'Gestion employés';
            case 'LEAVES':
                return 'Absence et Pointage';
            case 'BILLING':
                return 'Facturation';
            case 'FUND':
                return 'Caisse';
            case 'PAYROLL':
                return 'Paie';
            case 'PROJECT_MANAGEMENT':
                return 'Gestion de projet';
            case 'RECRUITMENT':
                return 'Recrutement';
            case 'STOCK':
                return 'Stock';
            default:
                return erpModule;
        }
    }

    convertCategory(category) {
        switch (category) {
            case 'BUG':
                return 'Bug';
            case 'UNEXPECTED':
                return 'Incendie';
            case 'IMPROVEMENT':
                return 'Amélioration';
            default:
                return category;
        }
    }

    convertPriority(priority) {
        switch (priority) {
            case 'HIGH':
                return 'Haute';
            case 'MEDIUM':
                return 'Moyenne';
            case 'LOW':
                return 'Faible';
            default:
                return priority;
        }
    }

    convertStatus(status) {
        switch (status) {
            case 'DONE':
                return 'Résolu';
            case 'IN_PROGRESS':
                return 'En cours';
            case 'STAND_BY':
                return 'En attente';
            case 'CLOSED':
                return 'Fermé';
            default:
                return status;
        }
    }

    convertType(type) {
        switch (type) {
            case 'COMMERCIAL':
                return 'Commerciale';
            case 'TECHNICAL':
                return 'Technique';
            default:
                return type;
        }
    }

    private sendDataSubject = new BehaviorSubject<any>(null);
    sendDataSubject$ = this.sendDataSubject.asObservable();

    setDataSubjectData(data: any) {
        this.sendDataSubject.next(data);
    }

    private sendData = new BehaviorSubject<any>(null);
    sendData$ = this.sendData.asObservable();

    sendListData(data: any) {
        this.sendData.next(data);
    }


    getTotalRemise(lines: LineModel[]) {
        let totalRemise = 0;
        lines.forEach(element => {
            totalRemise += ((element.prixunitaire * element.quantite) * (element.remise / 100))
        })
        return totalRemise;
    }

    getTotalRemiseForAvoir(lines: LineModel[]) {
        let totalRemise = 0;
        lines.forEach(element => {
            totalRemise += (element.prixunitaire * element.returnedQuantity * (element.remise / 100))
        })
        return totalRemise;
    }

    checkIfIntervalIsIncluded(calendarProductList) {
        return calendarProductList.some((el, i) =>
            calendarProductList.some((otherEl, j) => {
                if (i !== j) {
                    return (new Date(el.startDate) < new Date(otherEl.endDate) && new Date(el.startDate) > new Date(otherEl.startDate))  ||
                    (new Date(el.endDate) > new Date(otherEl.startDate) && new Date(el.endDate) < new Date(otherEl.endDate));
                }
                return false;
            })
        );
    }

    checkAdjacentDates(calendarProductList) {
        return calendarProductList.some((el, i) =>
            calendarProductList.some((otherEl, j) =>
                    i !== j &&
                new Date(el.endDate).getTime() === new Date(otherEl.startDate).getTime()
            )
        );
    }

    updateListsAfterPayer(
        resp: any,
        listInvoice: any[],
        listBonLivraison: any[],
        listBonCommande: any[],
        devisList: any[],
        dataSourceFactureList: MatTableDataSource<any>,
        dataSourceBonLivraisonList: MatTableDataSource<any>,
        dataSourceBonCommandeList: MatTableDataSource<any>,
        dataSourceDevisList: MatTableDataSource<any>,
        type:any
    ) {
        if (resp.type === "BILLING" || resp.type === "STOCK") {
            const statusKey = resp.type === "STOCK" ? "status" : "paymentStatus";

            if(resp.status==TransactionStatus.PARTIELLEMENT_PAYE){
            this.updateListItem(listInvoice, 'uuid', resp.invoice  , resp, statusKey,false,type);
            }
            if(type=='INVOICE'){
            this.updateListItem(listInvoice, 'uuid', resp.uuid  , resp, statusKey,false,type);
            this.updateListItem(listBonLivraison, 'uuid', resp.deliveryList, resp, 'paymentStatus',true,type);
            this.updateListItem(listBonCommande, 'uuid', resp.purshaseOrderList, resp, 'paymentStatus',true,type);
            this.updateListItem(devisList, 'uuid', resp.uuid, resp, 'paymentStatus',true,type);

            this.refreshDataSources(dataSourceFactureList, listInvoice);
            this.refreshDataSources(dataSourceBonLivraisonList, listBonLivraison);
            this.refreshDataSources(dataSourceBonCommandeList, listBonCommande);
            this.refreshDataSources(dataSourceDevisList, devisList);}
            else{
                this.updateListItem(listBonLivraison, 'uuid', resp.uuid, resp, 'paymentStatus',true,type);
                this.updateListItem(listBonCommande, 'uuid', resp.purshase_order, resp, 'paymentStatus',true,type);
                this.updateListItem(devisList, 'uuid', resp.uuid, resp, 'paymentStatus',true,type);

                this.refreshDataSources(dataSourceBonLivraisonList, listBonLivraison);
                this.refreshDataSources(dataSourceBonCommandeList, listBonCommande);
                this.refreshDataSources(dataSourceDevisList, devisList);
            }
        }
    }


    updateListItem(list: any[], key: string, ids: any | any[], resp: any, statusKey: string, isBLorBC: boolean,type:String) {
        const idList = Array.isArray(ids) ? ids : [ids];

        list.forEach((elem) => {
            if (idList.includes(elem[key])) {
                if (isBLorBC) {
                    if(type=='DELIVERY'){
                        elem[statusKey] = resp[statusKey];
            }
                    else{
                        elem[statusKey] = resp["status"];
            }
                    elem.left_to_pay = elem[statusKey] === 'PAYE' ? 0 : (resp.left_to_pay ? resp.left_to_pay : resp.invoiceLeftToPay);
                } else {
                    elem[statusKey] = resp[statusKey];
                    elem.left_to_pay = elem[statusKey] === 'PAYE' ? 0 : (resp.left_to_pay ? resp.left_to_pay : resp.invoiceLeftToPay);
            }
            }
        });
            }


    hasDuplicates(typedSerialNumbers: string[]): boolean {
        const uniqueValues = new Set(typedSerialNumbers);
        return uniqueValues.size !== typedSerialNumbers.length;
            }


    refreshDataSources(dataSource: MatTableDataSource<any>, list: any[]) {
        dataSource.data = [...list];
        }


    getListOfProductsAccordingType(typeAchat: string, achatOuVente?: string): Observable<any[]> {
        if (typeAchat === PurchaseType.Produit) {
            return this.stockService.getAllProductsServices('ALL_PRODUCTS', achatOuVente);
        } else if (typeAchat === PurchaseType.Produit_service) {
            return this.stockService.getAllProductsServices('ALL_PRODUCTS_SERVICES', achatOuVente);
        } else if (typeAchat === PurchaseType.Service) {
            return this.stockService.getAllProductsServices('ALL_SERVICES', achatOuVente);
        } else {
            return of([]);
        }
    }

    getListOfProductsServicesWithStartsWith(
        typeAchat: string,
        achatOuVente: string,
        startsWithName: string,
        startsWithReference: string,
        startsWithBarCode: string
    ): Observable<any[]> {
        if (typeAchat === PurchaseType.Produit) {
            return this.stockService.getAllProductsServicesWithStartsWith('ALL_PRODUCTS', achatOuVente, startsWithName, startsWithReference, startsWithBarCode);
        } else if (typeAchat === PurchaseType.Produit_service) {
            return this.stockService.getAllProductsServicesWithStartsWith('ALL_PRODUCTS_SERVICES', achatOuVente, startsWithName, startsWithReference, startsWithBarCode);
        } else if (typeAchat === PurchaseType.Service) {
            return this.stockService.getAllProductsServicesWithStartsWith('ALL_SERVICES', achatOuVente, startsWithName, startsWithReference, startsWithBarCode);
        } else {
            return of([]);
        }
    }


    formatPrice(form: FormGroup, controlName: string): void {
        const control = form.get(controlName) || form.get(`productPrice.${controlName}`);
        if (control && control.value) {
          let value = Number(control.value);
          if (!isNaN(value)) {
            control.setValue(value.toFixed(3));
          }
        }
    }


    fetchLineModels(element: any): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!element.lineModels || element.lineModels.length === 0) {
                if (element.nature === "Bon_Commande" || element.creationNature === "FROM_PURSHASE"|| (element.creationNature === "FROM_DELIVERY")) {
                    this.buillingService.getLineModelsByPurshaseOrder(element.id).subscribe({
                        next: (res: any) => {
                            if (!element.lineModels) {
                                element.lineModels = [];
                            }
                            element.lineModels.push(...res);
                },
                        error: (err) => reject(err),
                        complete: () => resolve(),
                    });
                } else if (element.nature === "Bon_Livraison" ) {
                    this.buillingService.getLineModelsByDeliveryId(element.id).subscribe({
                        next: (res: any) => {
                            if (!element.lineModels) {
                                element.lineModels = [];
                            }
                            element.lineModels.push(...res);
                },

                        error: (err) => reject(err),
                        complete: () => resolve(),
                    });
                } else if (element.nature === "Facture") {
                    this.buillingService.getLineModelsByInvoiceId(element.id).subscribe({
                        next: (res: any) => {
                            if (!element.lineModels) {
                                element.lineModels = [];
                            }
                            element.lineModels.push(...res);
                },
                        error: (err) => reject(err),
                        complete: () => resolve(),
                    });
                } else if (element.nature === "Devis" || (element.invoiceNumber?.includes('DEV'))) {
                    this.buillingService.getLineModelsByDevisId(element.id).subscribe({
                        next: (res: any) => {
                            if (!element.lineModels) {
                                element.lineModels = [];
                            }
                            element.lineModels.push(...res);
                },
                        error: (err) => reject(err),
                        complete: () => resolve(),
                    });
                } else {
                    resolve();
    }
            } else {
                resolve();}
        });

    }

    // ----------------------------- export options ----------------------------------------

    //csv export
    exportDataToCSV(
        data: any[],
        fileName: string,
        errorMsg: string,
        total?: number
    ): void {
        // If total is provided
        if (total !== undefined) {
            const lastHeader = Object.keys(data[0]).pop();
            const secondLastHeader = Object.keys(data[0]).slice(-2, -1)[0];

            const totalRow = { ...data[0] };
            Object.keys(totalRow).forEach((key) => {
                totalRow[key] =
                    key === lastHeader
                        ? total
                        : key === secondLastHeader
                        ? 'Total'
                        : '';
            });
            data.push(totalRow);
        }

        const csvData = this.convertDataToCSV(data);

        if (csvData) {
            const blob = new Blob([csvData], {
                type: 'text/csv;charset=utf-8;',
            });
            const link = document.createElement('a');

            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                alert(
                    'Cliquez sur le lien suivant pour télécharger le fichier : ' +
                        fileName
                );
            }
        } else {
            this.toast.error({
                detail: errorMsg,
                duration: 5000,
            });
        }
    }

    convertDataToCSV(data: any[]) {
        const separator = ';';
        if (data.length != 0) {
            const header = Object.keys(data[0]);
            const csv = [header.join(separator)];

            for (const row of data) {
                const values = header.map((key) => {
                    let value = row[key];
                    if (typeof value === 'string') {
                        value = value.replace(/"/g, '""');
                        if (value.includes(separator)) {
                            value = `"${value}"`;
                        }
                    }
                    return value;
                });
                csv.push(values.join(separator));
            }

            return csv.join('\n');
        }
    }

    //pdf export
    exportDataToPDF(
        data: any[],
        headers: string[],
        fileName: string,
        errorMsg: string,
        total?: number
    ): void {
        const doc = new jsPDF('l', 'mm', 'a4');

        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');

        this.convertDataToPDF(doc, data, headers, fileName, errorMsg, total);
    }

    convertDataToPDF(
        doc: jsPDF,
        data: any[],
        headers: string[],
        fileName: string,
        errorMsg: string,
        total?: number
    ) {
        // If total is provided
        const columns = headers.map((header) => ({
            header: header,
            dataKey: header,
        }));

        if (total) {
            data.push(
                headers.reduce((acc, header, index) => {
                    acc[header] =
                        index === headers.length - 1
                            ? total
                            : index === headers.length - 2
                            ? 'Total'
                            : '';
                    return acc;
                }, {})
            );
        }

        if (data && data.length > 0) {
            autoTable(doc, {
                columns: columns,
                body: data,
            });

            doc.save(fileName);
        } else {
            this.toast.error({
                detail: errorMsg,
                duration: 5000,
            });
        }
    }

    //print option
    printData(
        data: any[],
        headers: string[],
        errorMsg: string,
        total?: number
    ): void {
        const doc = new jsPDF('l', 'mm', 'a4');

        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');

        if (data && data.length > 0) {
            const columns = headers.map((header) => ({
                header: header,
                dataKey: header,
            }));

            // If total is provided
            if (total) {
                data.push(
                    headers.reduce((acc, header, index) => {
                        acc[header] =
                            index === headers.length - 1
                                ? total
                                : index === headers.length - 2
                                ? 'Total'
                                : '';
                        return acc;
                    }, {})
                );
            }

            autoTable(doc, {
                columns: columns,
                body: data,
            });

            const pdfUrl = doc.output('bloburl');
            window.open(pdfUrl, '_blank');
        } else {
            this.toast.error({
                detail: errorMsg,
                duration: 5000,
            });
        }
    }

    //numbers to letters

    convertNumberToLetter(n: number): string {
        const ones: string[] = [
            '',
            'un',
            'deux',
            'trois',
            'quatre',
            'cinq',
            'six',
            'sept',
            'huit',
            'neuf',
        ];
        const teens: string[] = [
            'dix',
            'onze',
            'douze',
            'treize',
            'quatorze',
            'quinze',
            'seize',
            'dix-sept',
            'dix-huit',
            'dix-neuf',
        ];
        const tens: string[] = [
            '',
            '',
            'vingt',
            'trente',
            'quarante',
            'cinquante',
            'soixante',
            'soixante-dix',
            'quatre-vingts',
            'quatre-vingt-dix',
        ];
        const thousands: string[] = ['mille', 'million', 'milliard'];

        if (n === 0) return 'zéro';

        const convertHundreds = (num: number): string => {
            if (num === 0) return '';
            if (num < 10) return ones[num];
            if (num < 20) return teens[num - 10];
            if (num < 100) {
                const ten = Math.floor(num / 10);
                const one = num % 10;
                return one === 0 ? tens[ten] : `${tens[ten]}-${ones[one]}`;
            }
            const hundred = Math.floor(num / 100);
            const rest = num % 100;
            return hundred === 1
                ? rest === 0
                    ? 'cent'
                    : `cent ${convertHundreds(rest)}`
                : `${ones[hundred]} cent ${rest === 0 ? '' : convertHundreds(rest)
                  }`;
        };

        const convert = (num: number): string => {
            if (num < 1000) return convertHundreds(num);

            let result = '';
            let index = 0;

            while (num > 0) {
                const chunk = num % 1000;
                if (chunk !== 0) {
                    const chunkString = convertHundreds(chunk);
                    result =
                        chunkString +
                        (thousands[index] ? ` ${thousands[index]} ` : '') +
                        result;
                }
                num = Math.floor(num / 1000);
                index++;
            }

            return result.trim().replace(/-$/, '');
        };

        return convert(n);
    }

    markFormGroupPristineAndUntouched(formGroup: FormGroup | FormArray): void {
        Object.keys(formGroup.controls).forEach((key) => {
          const control = formGroup.get(key);

          if (control instanceof FormControl) {
            control.markAsPristine();
            control.markAsUntouched();
          } else if (control instanceof FormGroup || control instanceof FormArray) {
            this.markFormGroupPristineAndUntouched(control);
          }
        });
      }

    applyDateInputMask(event: any, mask: string): void {
        let input = event.target.value.replace(/\D/g, '');
        let formatted = '';

        if (mask === 'DD/MM/YYYY') {
            if (input.length > 0) {
                formatted = input.substring(0, 2);
            }
            if (input.length >= 3) {
                formatted += `/${input.substring(2, 4)}`;
            }
            if (input.length >= 5) {
                formatted += `/${input.substring(4, 8)}`;
            }

            const day = parseInt(input.substring(0, 2), 10);
            if (day > 31) {
                formatted = `31${formatted.substring(2)}`;
            }

            if (formatted.length >= 5) {
                const month = parseInt(input.substring(2, 4), 10);
                if (month > 12) {
                    formatted = `${formatted.substring(
                        0,
                        3
                        )}12${formatted.substring(5)}`;
                }
            }
        } else if (mask === 'MM/YYYY') {
            if (input.length > 0) {
                formatted = input.substring(0, 2);
            }
            if (input.length >= 3) {
                formatted += `/${input.substring(2, 6)}`;
            }

            const month = parseInt(input.substring(0, 2), 10);
            if (month > 12) {
                formatted = `12${formatted.substring(2)}`;
            }
        } else if (mask === 'YYYY') {
            if (input.length > 0) {
                formatted = input.substring(0, 4);
            }
        }

        event.target.value = formatted;
    }


    // decimal input

    applyDecimalInput(event: Event): void {
        const input = event.target as HTMLInputElement;
        let value = input.value;

        value = value.replace(/[^0-9.]/g, '');

        const parts = value.split('.');
        if (parts.length > 2) {
            value = parts[0] + '.' + parts[1];
        }

        if (parts[1]?.length > 2) {
            value = parts[0] + '.' + parts[1].substring(0, 2);
        }

        input.value = value;
    }

    // integer input

    applyIntegerInput(event: Event): void {
        const input = event.target as HTMLInputElement;
        let value = input.value;
        value = value.replace(/[^0-9]/g, '');
        input.value = value;
    }
    downloadPDF(response: Blob, fileName: string) {
        const url = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    // export files
    handleExport(paylaod: {
        exportType: string;
        data: any;
        fileName: string;
        total?: number;
    }) {
        if (paylaod.exportType === ExportOptions.pdf) {
            this.downloadPDF(paylaod.data, paylaod.fileName);
        }

        if (paylaod.exportType === ExportOptions.csv) {
            this.exportDataToCSV(
                paylaod.data,
                `${paylaod.fileName}.csv`,
                this.translocoService.translate('commons.downloadCSVError'),
                paylaod.total
            );
        }

        if (paylaod.exportType === ExportOptions.print) {
            this.openPDF(paylaod.data);
        }
    }
    openPDF(response: Blob) {
        const url = window.URL.createObjectURL(response);
        const windowFeatures =
            'width=800,height=600,left=100,top=100,location=no,scrollbars=yes,status=yes';
        window.open(url, '_blank', windowFeatures);
    }

    //ship Attributes


    shipText(status: string): string {
        switch (status) {
            case salaryDepositStatus.DENIED:
                return this.translocoService.translate('commons.salaryDepositStatus.denied');
            case salaryDepositStatus.APPROVED:
            case CancellationStatusEnum.ACCEPTED:
                return this.translocoService.translate('commons.salaryDepositStatus.approved');
            case salaryDepositStatus.PENDING:
                return this.translocoService.translate('commons.salaryDepositStatus.pending');
            case salaryDepositStatus.CANCELLED:
                return this.translocoService.translate('commons.salaryDepositStatus.cancelled');
            default:
                return this.translocoService.translate('commons.salaryDepositStatus.refused');
        }
    }
    shipTextColor(status: string): string {
        switch (status) {
            case salaryDepositStatus.REFUSED:
                return 'text-yellow-400';
            case salaryDepositStatus.DENIED:
            case CancellationStatusEnum.DECLINED:
                return 'text-red-500';
            case salaryDepositStatus.CANCELLED:
            case salaryDepositStatus.APPROVED:
            case CancellationStatusEnum.ACCEPTED:
                return 'text-black';
            default :
                return 'text-white';
        }
    }
    shipBackGround(status: string): string {
        switch (status) {
            case salaryDepositStatus.REFUSED:
                return 'bg-gray-900';
            case salaryDepositStatus.APPROVED:
            case CancellationStatusEnum.ACCEPTED:
                return 'bg-yellow-400';
            case salaryDepositStatus.PENDING:
                return 'bg-amber-500';
            case salaryDepositStatus.REFUSED:
                return 'bg-green-500';
            default:
                return 'bg-red-200';
        }
    }
    applyDepositAmountInput(event: Event, maxValue: number, type: salaryDeposit, duration: number | null): void {

        const input = event.target as HTMLInputElement;
        let value = input.value;
        value = value.replace(/[^0-9.]/g, '');

        const parts = value.split('.');
        if (parts.length > 2) {
            value = parts[0] + '.' + parts[1];
        }
        if (parts[1]?.length > 2) {
            value = parts[0] + '.' + parts[1].substring(0, 2);
        }
        const numericValue = parseFloat(value);
        if (type === salaryDeposit.ADVANCE) {
            if (!isNaN(numericValue) && numericValue > maxValue) {
                value = maxValue.toFixed(2);
            }
        } else {
            //credit case
            if (!isNaN(numericValue) && duration && numericValue / duration > maxValue) {
                value = maxValue.toFixed(2);
            }
        }
        input.value = value;
    }

    // integer input duration

    applyDepositDurationInput(event: Event, minValue: number): void {
        const input = event.target as HTMLInputElement;
        let value = input.value;

        value = value.replace(/[^0-9]/g, '');

        const numericValue = parseInt(value, 10);
        if (!isNaN(numericValue) && numericValue < minValue) {
            value = minValue.toString();
        }

        input.value = value;
    }
    // three digit after comma (not round)
    formatFloatToThreeDecimals(value: number): number {
        return Math.trunc(value * 1000) / 1000;
    }


  getModulesList (): Map<string, any>{
   return new Map([
        ["facturation_menu", { module: "isBillingChecked", isChecked: false, price: 0, module_id: "facturation_menu", isValid: false, img: "/assets/imgs/crm_module.svg" }],
        ["crm_management_menu", { module: "isCrmChecked", isChecked: false, price: 0, module_id: "crm_management_menu", isValid: false, img: "/assets/imgs/crm_module.svg" }],
        ["project_management_menu", { module: "isProjectManagementChecked", isChecked: false, price: 0, module_id: "project_management_menu", isValid: false, img: "/assets/imgs/project-managment-module.svg" }],
        ["gestion_employes_menu", { module: "isSirhChecked", isChecked: false, price: 0, module_id: "gestion_employes_menu", isValid: false, img: "/assets/imgs/sirh_module.svg" }],
        ["caisse_menu", { module: "isCaisseChecked", isChecked: false, price: 0, module_id: "caisse_menu", isValid: false, img: "/assets/imgs/caisse_module.svg" }],
        ["auth_menu", { module: "isAuthChecked", isChecked: false, price: 0, module_id: "auth_menu", isValid: false, img: "/assets/imgs/auth_module.svg" }],
        ["commercial_menu", { module: "isCommercialChecked", isChecked: false, price: 0, module_id: "commercial_menu", isValid: false, img: "/assets/imgs/module_commercial.svg" }],
        ["recrutment_menu", { module: "isRecruitmentChecked", isChecked: false, price: 0, module_id: "recrutment_menu", isValid: false, img: "/assets/imgs/recrutement_module.svg" }],
        ["stock_menu", { module: "isStockChecked", isChecked: false, price: 0, module_id: "stock_menu", isValid: false, img: "/assets/imgs/stock_component.png" }],
        ["ecommerce_menu", { module: "isEcommerceChecked", isChecked: false, price: 0, module_id: "ecommerce_menu", isValid: false, img: "/assets/imgs/ecommerce_module.svg" }],
        ["paie_menu", { module: "isPayrollChecked", isChecked: false, price: 0, module_id: "paie_menu", isValid: false, img: "/assets/imgs/paie_module.svg" }],
        ["absence_pointage_menu", { module: "isLeavesChecked", isChecked: false, price: 0, module_id: "absence_pointage_menu", isValid: false, img: "/assets/imgs/leaves_module.svg" }],
        ["cra_menu", { module: "isCraChecked", isChecked: false, price: 0, module_id: "cra_menu", isValid: false, img: "/assets/imgs/cra_module.svg" }],
        ["upgrade_payment_menu", { module: "isFinancialIaChecked", isChecked: false, price: 0, module_id: "upgrade_payment_menu", isValid: false, img: "/assets/imgs/finacial_ia.svg" }]
    ]);

  }


  loadImage(uuidUser: any): Observable<any> {
    return this.dmsService.loadProfilePhoto(uuidUser).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
  extractNodeId(url: string): string | null {
    const match = url.match(/nodes\/([^\/]+)\/content/);
    return match ? match[1] : null;
}
    monthlyDeduction(currentAmount: number, currentDuration: number) {
        if (currentAmount && currentDuration) {
            return this.formatFloatToThreeDecimals(
                currentAmount / currentDuration
            );
        }
        return null;
    }
    formatDuration(months: number): string {
        const years = Math.floor(months / 12);
        const remainingMonths = months % 12;

        let result = '';
        if (years > 0) {
            result += `${years} ${this.translocoService.translate(
                years > 1 ? 'commons.years' : 'commons.year'
            )}`;
        }
        if (remainingMonths > 0) {
            result +=
                (result ? this.translocoService.translate('commons.and') : '') +
                `${remainingMonths} ${this.translocoService.translate(
                    remainingMonths > 1 ? 'commons.months' : 'commons.month'
                )}`;
        }

        return result;
    }

    loadAlfrescoImage(imagePath: string, index: number): Observable<{ url: SafeUrl; index: number }> {
        const imageUrl = `${environment.dmsUrl}${imagePath}`;
        return this.stockService.loadImage(imageUrl).pipe(
            map((res) => {
                const blobUrl = URL.createObjectURL(res);
                const sanitizedUrl: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
                return { url: sanitizedUrl, index };
            })
        );
    }
    loadAlfrescoImage1(imagePath: string, index: string): Observable<{ url: SafeUrl; index: string }> {
        const imageUrl = `${environment.dmsUrl}${imagePath}`;
        return this.stockService.loadImage(imageUrl).pipe(
            map((res) => {
                const blobUrl = URL.createObjectURL(res);
                const sanitizedUrl: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
                return { url: sanitizedUrl, index };
            })
            );
        }
    getDefaultImage(): Observable<{ url: string; index: null }> {
        return of({ url: './assets/imgs/producthunt.png', index: null });
    }

    onFirstStepValidate(
        routeData: { typePaper: string },
        rateType: string,
        deviseInvoice: any,
        isConvertableChecked: boolean
      ): Observable<any> | null {
        if (!isConvertableChecked) {
        return null;
    }

        const typePaperMapping: { [key: string]: InvoiceNatureEnum } = {
          BonCommande: InvoiceNatureEnum.BON_COMMANDE,
          BonLivraison: InvoiceNatureEnum.BON_LIVRAISON,
          devis: InvoiceNatureEnum.DEVIS,
          Facture: InvoiceNatureEnum.FACTURE,
        };

        const invoiceNatureEnum = typePaperMapping[routeData.typePaper];

        return rateType === RateTypeEnum.manual
          ? this.getLastCurrency(rateType, deviseInvoice)
          : this.addNewCurrencyExchangeRate(invoiceNatureEnum, deviseInvoice);
      }

      private getLastCurrency(rateType: string, deviseInvoice: any): Observable<any> {
        return this.buillingService.getLastCurrency(
          rateType,
          deviseInvoice.deviseConverted,
          deviseInvoice.deviseTarget
        );
      }

      private addNewCurrencyExchangeRate(
        invoiceNatureEnum: InvoiceNatureEnum,
        deviseInvoice: any
      ): Observable<any> {
        return this.buillingService.addNewCurrencyExchangeRate(
          InvoiceTypeEnum.ACHAT,
          invoiceNatureEnum,
          deviseInvoice.deviseConverted,
          [deviseInvoice.deviseTarget]
        );
    }

      restoreCheckedBls(bls:BonLivraisonModel[],selectedIds:Set<number>){
        bls.map((bl)=>{
            if(selectedIds.has(bl.id)){
                bl.isChecked = true
            }
            return bl
        })
                            }


//-------------------- Start Popup confirmation  --------------------------------------

    prepareConfirmationModel(confirmationForPopupDto:ConfirmationForPopupDto) {

        return {
            "title": this.translocoService.translate('popup.'+confirmationForPopupDto.title),
            "message": this.translocoService.translate('popup.'+confirmationForPopupDto.message),
            "icon":
            confirmationForPopupDto.icon?
            {
            "show": confirmationForPopupDto.icon.show,
            "name": confirmationForPopupDto.icon.name,
            "color": confirmationForPopupDto.icon.color
            }:
            {},
            "actions":
            confirmationForPopupDto.actions?
            {
            "confirm":
            confirmationForPopupDto.actions.confirm?
            {
                "show": confirmationForPopupDto.actions.confirm?.show,
                "label": this.translocoService.translate('popup.'+confirmationForPopupDto.actions.confirm?.label),
                "color": confirmationForPopupDto.actions.confirm?.color
            }:
            {

            },
            "cancel": {
                "show": confirmationForPopupDto.actions.cancel.show,
                "label": this.translocoService.translate('popup.'+confirmationForPopupDto.actions.cancel.label)
                    }
            }:
            {},
            "dismissible": confirmationForPopupDto.dismissible
        };
                    }

    loadPopupOFConfirmation(confirmationForPopupDto:ConfirmationForPopupDto):Observable<any>{
        let configValid = null
        this.translocoService.langChanges$.subscribe(() => {
            configValid = this.prepareConfirmationModel(confirmationForPopupDto)
                });
        const dialogRef = this._fuseConfirmationService.open(configValid);
       return dialogRef.afterClosed()
        }

    applyConfirmationForPopup(
        popupData:{
            popupTile:string,
            popupMessage:string,
            iconName:string,
            iconColor:string,
            confirmLabel?:string,
            confirmMessage?:string,
            confirmColor?:string,
            cancelLabel?:string,
            isConfirm?:boolean,
            isCancel?:boolean
    }
    ):Observable<any>{
       let confirmationForPopupDto = new ConfirmationForPopupDto();

       confirmationForPopupDto.title = popupData.popupTile;
       confirmationForPopupDto.message = popupData.popupMessage;
       confirmationForPopupDto.icon.show = true;
       confirmationForPopupDto.icon.name = popupData.iconName;
       confirmationForPopupDto.icon.color = popupData.iconColor;

       confirmationForPopupDto.actions.confirm.show = true
       confirmationForPopupDto.actions.cancel.show = true

       if(!popupData.isConfirm){
           confirmationForPopupDto.actions.confirm.show = popupData.isConfirm;
       }
       confirmationForPopupDto.actions.confirm.label = popupData.confirmLabel?popupData.confirmLabel:'confirm';
       confirmationForPopupDto.actions.confirm.color = popupData.confirmColor;

       confirmationForPopupDto.actions.cancel.label = popupData.cancelLabel?popupData.cancelLabel:'cancel';
       if(!popupData.isConfirm){
        confirmationForPopupDto.actions.cancel.show = popupData.isCancel;
    }
       confirmationForPopupDto.dismissible = true;

      return this.loadPopupOFConfirmation(confirmationForPopupDto)
    }
    processStockLines(routeData ,linesTotalList): { lines: any; hasDuplicateRemise: boolean } {
        if (routeData.type === ModuleTypeEnum.STOCK) {
            return this.mergeDuplicateLines(linesTotalList);
        }
        return { lines: linesTotalList, hasDuplicateRemise: false };
    }


    mergeDuplicateLines(lines: LineModel[]): { lines: LineModel[], hasDuplicateRemise: boolean } {
        const lineMap = new Map<string, LineModel>();
        const duplicateRemiseMap = new Map<string, Set<number>>();
        let hasDuplicateRemise = false;
        lines.forEach(line => {
            const remiseValue = line.remise ?? 0;
            const key = `${line.uuidProduct}-${line.uuidVariant}`;
            if (!duplicateRemiseMap.has(key)) {
                duplicateRemiseMap.set(key, new Set());
        }
            const remiseSet = duplicateRemiseMap.get(key)!;
            remiseSet.add(remiseValue);
            if (remiseSet.size > 1) {
                hasDuplicateRemise = true;
        }
            const uniqueKey = `${key}-${remiseValue}`;
            if (lineMap.has(uniqueKey)) {
                const existingLine = lineMap.get(uniqueKey)!;
                existingLine.quantite += line.quantite || 0;
                existingLine.prixtotal += line.prixtotal || 0;
                existingLine.subtotalprice += line.subtotalprice || 0;
                existingLine.prixnet += line.prixnet || 0;
            } else {
                lineMap.set(uniqueKey, { ...line, remise: remiseValue });
    }
        });

        if (hasDuplicateRemise) {
            this.toast.warning({
                detail: this.translocoService.translate('toast_msg.merge_error'),
                duration: 5000
            });
        }
        return { lines: Array.from(lineMap.values()), hasDuplicateRemise };
    }
    getCurrencyLabel(currencyCode: string): string {
        const devise = ListDevises.find(d => d.value === currencyCode);
        return devise ? devise.label : currencyCode;
    }
    getAllModelesAffected(): Observable<any[]> {
        return this.buillingService.getTemplateDocumentPerCurrentPartner().pipe(
            map((res: any) => [
                ...res.billTemplateBCAchat,
                ...res.billTemplateBLAchat,
                ...res.billTemplateInvoiceAchat,
                ...res.billTemplateDevisAchat,
                ...res.billTemplateDevisVente,
                ...res.billTemplateBCVente,
                ...res.billTemplateBLVente,
                ...res.billTemplateInvoiceVente,
            ])
        );
    }

    async translateTypeAchat(typeachat) {
        const keysLabels = [
            'stepper_achat.produit',
            'stepper_achat.service',
            'stepper_achat.both'
        ];

        const translations = await Promise.all(
            keysLabels.map(key => firstValueFrom(this.translocoService.selectTranslate(key)))
        );

        typeachat.forEach((item, index) => {
            item.label = translations[index];
        });
    }
    getMonthNumber(month: string): number | undefined {
        return this.monthMap[month.toLowerCase()];
    }

    KeyPress(event: any) {
        return this.numberInputKeyPress(event);
      }

    numberInputKeyPress(event: KeyboardEvent): void {
        let inputChar = String.fromCharCode(event.charCode || event.keyCode);
        if (event.keyCode !== 8 && !this.regxService.patternNumber.test(inputChar)) {
          event.preventDefault();
        }
      }

    setNbrNotifAssurance(value: number): void{
        this.nbrNotifAssuranceSubject.next(value);
    }
    setNbrNotifVidange(value: number): void{
        this.nbrNotifVidangeSubject.next(value);
    }
}
