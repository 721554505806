export class TvaTableModel {
    base : any
    taux : any
    montant : any
    remise:any
    public constructor(base : any , taux : any , montant : any, remise:any) {
        this.base = base;
        this.taux = taux;
        this.montant = montant;
        this.remise =remise
    }

}
