export enum ElementIdEnum {
    absence_pointage_menu = 'absence_pointage_menu',
    absence_submenu = 'absence_submenu',
    demandes_conge_submenu = 'demandes_conge_submenu',
    pointage_submenu = 'pointage_submenu',
    solde_conges_submenu = 'solde_conges_submenu',
    validation_absence_submenu = 'validation_absence_submenu',
    caisse_menu = 'caisse_menu',
    etat_caisse_submenu = 'etat_caisse_submenu',
    caisses_tab = 'caisses_tab',
    affecter_caisse_to_collaborator_btn = 'affecter_caisse_to_collaborator_btn',
    add_caisse_button = 'add_caisse_button',
    voir_caisse_btn = 'voir_caisse_btn',
    open_close_fund_btn = 'open_close_fund_btn',
    prelevement_fund_btn = 'prelevement_fund_btn',
    etat_caisse_generale_tab = 'etat_caisse_generale_tab',
    comptes_bancaires_submenu = 'comptes_bancaires_submenu',
    add_compte_bancaire_button = 'add_compte_bancaire_button',
    etat_tiers_caisse_submenu = 'etat_tiers_caisse_submenu',
    suivi_cheque_caisse_submenu = 'suivi_cheque_caisse_submenu',
    Suivi_Subvention_submenu = 'Suivi_Subvention_submenu',
    suivi_virement_caisse_submenu = 'suivi_virement_caisse_submenu',
    transactions_caisse_submenu = 'transactions_caisse_submenu',
    virement_caisse_submenu = 'virement_caisse_submenu',
    virement_internes_tab = 'virement_internes_tab',
    virement_salaire_tab = 'virement_salaire_tab',
    commercial_menu = 'commercial_menu',
    commercial_submenu = 'commercial_submenu',
    configuration_menu = 'configuration_menu',
    config_abscence = 'config_abscence',
    config_external_api = 'config_external_api',
    config_caisse = 'config_caisse',
    config_compte = 'config_compte',
    config_entreprise = 'config_entreprise',
    config_facturation = 'config_facturation',
    config_inventory = 'config_inventory',
    config_paie = 'config_paie',
    config_paiement = 'config_paiement',
    config_recrutement = 'config_recrutement',
    config_stock = 'config_stock',
    config_type_tiers = 'config_type_tiers',
    cra_menu = 'cra_menu',
    ajout_cra_btn = 'ajout_cra_btn',
    cra_collab_table = 'cra_collab_table',
    enregistrer_cra_btn = 'enregistrer_cra_btn',
    cra_collab_filter_tab = 'cra_collab_filter_tab',
    cra_partner_filter = 'cra_partner_filter',
    cra_liste = 'cra_liste',
    cra_liste_valide = 'cra_liste_valide',
    cra_partner_table = 'cra_partner_table',
    valider_cra_btn = 'valider_cra_btn',
    voir_cra_btn = 'voir_cra_btn',
    DonneesEmploye_menu = 'DonneesEmploye_menu',
    donnees_entreprise_menu = 'donnees_entreprise_menu',
    facturation_menu = 'facturation_menu',
    achat_facturation_submenu = 'achat_facturation_submenu',
    contact_facturation_submenu = 'contact_facturation_submenu',
    vente_facturation_submenu = 'vente_facturation_submenu',
    facturation_sp_menu = 'facturation_sp_menu',
    avances_sp_submenu = 'avances_sp_submenu',
    fact_sp_factures_submenu = 'fact_sp_factures_submenu',
    annulation_facture_sp_btn = 'annulation_facture_sp_btn',
    fact_sp_products_submenu = 'fact_sp_products_submenu',
    tare_track_submenu = 'tare_track_submenu',
    fact_sp_tiers_submenu = 'fact_sp_tiers_submenu',
    crm_management_menu = 'crm_management_menu',
    crm_contacts_submenu = 'crm_contacts_submenu',
    crm_client_tab = 'crm_client_tab',
    crm_add_client_btn = 'crm_add_client_btn',
    crm_modify_client_btn = 'crm_modify_client_btn',
    crm_delete_client_btn = 'crm_delete_client_btn',
    crm_fournisseur_tab = 'crm_fournisseur_tab',
    crm_add_frn_btn = 'crm_add_frn_btn',
    crm_modify_foutnisseur_btn = 'crm_modify_foutnisseur_btn',
    crm_delete_fournisseur_btn = 'crm_delete_fournisseur_btn',
    crm_dynamic_form = 'crm_dynamic_form',
    suivi_client_crm_submenu = 'suivi_client_crm_submenu',
    gestion_tickets_menu = 'gestion_tickets_menu',
    statiqtiques_ticket_submenu = 'statiqtiques_ticket_submenu',
    ticket_submenu = 'ticket_submenu',
    gestion_employes_menu = 'gestion_employes_menu',
    collaborator_employe_tab = 'collaborator_employe_tab',
    add_collab_button = 'add_collab_button',
    contrats_employee_tab = 'contrats_employee_tab',
    add_contract_button = 'add_contract_button',
    fin_contrats_employee_tab = 'fin_contrats_employee_tab',
    upgrade_payment_menu = 'upgrade_payment_menu',
    paie_menu = 'paie_menu',
    fiche_paie_sub_menu = 'fiche_paie_sub_menu',
    renumeration_submenu = 'renumeration_submenu',
    fiche_paie_validation_sub_menu = 'fiche_paie_validation_sub_menu',
    variable_paie_submenu = 'variable_paie_submenu',
    recrutment_menu = 'recrutment_menu',
    agents_recrutment_submenu = 'agents_recrutment_submenu',
    demandes_recrutement_submenu = 'demandes_recrutement_submenu',
    recrutment_submenu = 'recrutment_submenu',
    btn_Identification = 'identificationSecurity',
    statistiques_recrutement_submenu = 'statistiques_recrutement_submenu',
    stock_menu = 'stock_menu',
    appro_submenu = 'appro_submenu',
    achat_submenu = 'achat_submenu',
    bon_commande_achat_tab = 'bon_commande_achat_tab',
    add_bc_achat_button = 'add_bc_achat_button',
    link_to_depot_achat_bc_btn = 'link_to_depot_achat_bc_btn',
    modify_bc_achat_button = 'modify_bc_achat_button',
    transfert_bc_options_btn = 'transfert_bc_options_btn',
    bon_livraison_achat_tab = 'bon_livraison_achat_tab',
    add_bonl_livraison_achat_button = 'add_bonl_livraison_achat_button',
    confirm_livraison_achat_btn = 'confirm_livraison_achat_btn',
    transfert_bon_livraison_achat_btn = 'transfert_bon_livraison_achat_btn',
    voir_bon_livraison_achat_btn = 'voir_bon_livraison_achat_btn',
    facture_achat_tab = 'facture_achat_tab',
    add_facture_achat_btn = 'add_facture_achat_btn',
    annulation_facture_achat_btn = 'annulation_facture_achat_btn',
    payement_facture_achat_btn = 'payement_facture_achat_btn',
    voir_facture_achat_btn = 'voir_facture_achat_btn',
    order_submenu = 'order_submenu',
    contact_submenu = 'contact_submenu',
    client_stock_tab = 'client_stock_tab',
    add_client_stock_button = 'add_client_stock_button',
    modify_client_stock_button = 'modify_client_stock_button',
    update_type_client_fidelity_stock_button = 'update_type_client_fidelity_stock_button',
    delete_client_stock_button = 'delete_client_stock_button',
    fournisseur_stock_tab = 'fournisseur_stock_tab',
    add_fournisseur_stock = 'add_fournisseur_stock',
    modify_fournisseur_stock_button = 'modify_fournisseur_stock_button',
    delete_fournisseur_stock_button = 'delete_fournisseur_stock_button',
    ecommerce_submenu = 'ecommerce_submenu',
    avoir_sub_menu = 'avoir_sub_menu',
    avoir_commercial_tab = 'avoir_commercial_tab',
    avoir_financier_tab = 'avoir_financier_tab',
    dashbord_stock = 'dashbord_stock',
    demande_approvement_submenu = 'demande_approvement_submenu',
    gestion_alerte_stock = 'gestion_alerte_stock',
    alerte_submenu_stock = 'alerte_submenu_stock',
    add_alerte_button = 'add_alerte_button',
    modify_alerte_button = 'modify_alerte_button',
    delete_alerte_button = 'delete_alerte_button',
    etat_stock_submenu_stock = 'etat_stock_submenu_stock',
    gestion_depot_stock = 'gestion_depot_stock',
    transfere_depot_stock = 'transfere_depot_stock',
    demandes_transfert_tab = 'demandes_transfert_tab',
    demande_transfere_btn = 'demande_transfere_btn',
    transfert_tab = 'transfert_tab',
    depot_stock = 'depot_stock',
    add_depot_button = 'add_depot_button',
    modify_default_depot_button = 'modify_default_depot_button',
    suivi_depot = 'suivi_depot',
    historique_depot_tab = 'historique_depot_tab',
    niveau_depot_tab = 'niveau_depot_tab',
    suivi_depot_category_tab = 'suivi_depot_category_tab',
    inventory_sub_menu = 'inventory_sub_menu',
    add_inventory_button = 'add_inventory_button',
    inventory_details_button = 'inventory_details_button',
    product_menu = 'product_menu',
    category_sub_menu = 'category_sub_menu',
    vehicle_maintenance_sub_menu = 'vehicle_maintenance_sub_menu',
    add_category_button = 'add_category_button',
    modify_category_button = 'modify_category_button',
    delete_category_button = 'delete_category_button',
    product_sub_menu = 'product_sub_menu',
    update_prices_tab = 'update_prices_tab',
    products_tab = 'products_tab',
    add_product_composed_button = 'add_product_composed_button',
    assemplage_product_btn = 'assemplage_product_btn',
    kit_product_btn = 'kit_product_btn',
    add_product_button = 'add_product_button',
    matriere_premiere_btn = 'matriere_premiere_btn',
    simple_product_for_rent_btn = 'simple_product_for_rent_btn',
    add_simple_product_for_sell_btn = 'add_simple_product_for_sell_btn',
    category_detail_product_button = 'category_detail_product_button',
    imprimer_sku_btn = 'imprimer_sku_btn',
    modify_product_button = 'modify_product_button',
    options_variant_button = 'options_variant_button',
    variants_button = 'variants_button',
    services_tab = 'services_tab',
    add_service_button = 'add_service_button',
    modify_service_button = 'modify_service_button',
    delete_service_button = 'delete_service_button',
    r_source_stock_submenu = 'r_source_stock_submenu',
    retours_submenu = 'retours_submenu',
    vente_menu = 'vente_menu',
    factures_submenu = 'factures_submenu',
    location_submenu = 'location_submenu',
    promotion_submenu = 'promotion_submenu',
    vente_submenu = 'vente_submenu',
    bon_commande_vente_tab = 'bon_commande_vente_tab',
    add_bon_commande_vente_btn = 'add_bon_commande_vente_btn',
    modify_bon_commande_vente_btn = 'modify_bon_commande_vente_btn',
    pay_bon_commande_vente_btn = 'pay_bon_commande_vente_btn',
    transfert_bon_commande_vente_btn = 'transfert_bon_commande_vente_btn',
    bon_livraison_vente_tab = 'bon_livraison_vente_tab',
    add_bon_livraison_vente_btn = 'add_bon_livraison_vente_btn',
    confirm_delivery_vente_btn = 'confirm_delivery_vente_btn',
    transfert_bon_livraison_vente_btn = 'transfert_bon_livraison_vente_btn',
    facture_vente_tab = 'facture_vente_tab',
    add_facture_client_vente_btn = 'add_facture_client_vente_btn',
    annulation_facture_vente_btn = 'annulation_facture_vente_btn',
    avoir_facture_vente_btn = 'avoir_facture_vente_btn',
    payement_facture_vente_btn = 'payement_facture_vente_btn',
    voir_facture_vente_btn = 'voir_facture_vente_btn',
    config_type_payment_popup = 'config_type_payment_popup',
    AVANCE = 'AVANCE',
    AVOIR = 'AVOIR',
    CRD = 'CRD',
    CHQ = 'CHQ',
    CREDIT_STEG = 'CREDIT_STEG',
    ESPECE = 'ESPECE',
    REMISE = 'REMISE',
    SUBVENTION = 'SUBVENTION',
    TRAITE = 'TRAITE',
    VIR = 'VIR',

    payrollMenu = 'paie2_menu',
    payroll_journal_submenu = 'payroll_journal_submenu',
    export_payroll_journal_button = 'export_payroll_journal_button',
    export_pdf_payroll_journal_button = 'export_pdf_payroll_journal_button',
    export_excel_payroll_journal_button = 'export_excel_payroll_journal_button',
    print_payroll_journal_btn = 'print_payroll_journal_button',

    bank_transfer_submenu = 'bank_transfer_submenu',
    export_bank_transfers_button = 'export_bank_transfers_button',
    export_pdf_bank_transfers_button = 'export_pdf_bank_transfers_button',
    export_excel_bank_transfers_button = 'export_excel_bank_transfers_button',
    print_bank_transfers_button = 'print_bank_transfers_button',

    payment_payslip_submenu = 'payment_payslip_submenu',
    export_payment_payslip_button = 'export_payment_payslip_button',
    export_pdf_payment_payslip_button = 'export_pdf_payment_payslip_button',
    export_image_payment_payslip_button = 'export_image_payment_payslip_button',

    credit_salary_submenu = 'credit_salary_submenu',
    add_credit_salary_button = 'add_credit_salary_button',
    delete_credit_salary_button = 'delete_credit_salary_button',
    view_credit_salary_button = 'view_credit_salary_button',
    edit_credit_salary_button = 'edit_credit_salary_button',
    confirm_credit_salary_button = 'confirm_credit_salary_button',
    export_credit_salary_button = 'export_credit_salary_button',
    export_pdf_credit_salary_button = 'export_pdf_credit_salary_button',
    export_excel_credit_salary_button = 'export_excel_credit_salary_button',
    print_credit_salary_button = 'print_credit_salary_button',
    download_credit_salary_button = 'download_credit_salary_button',

    advance_salary_submenu = 'advance_salary_submenu',
    add_advance_salary_button = 'add_advance_salary_button',
    delete_advance_salary_button = 'delete_advance_salary_button',
    view_advance_salary_button = 'view_advance_salary_button',
    edit_advance_salary_button = 'edit_advance_salary_button',
    confirm_advance_salary_button = 'confirm_advance_salary_button',
    export_advance_salary_button = 'export_advance_salary_button',
    export_pdf_salary_advance_button = 'export_pdf_salary_advance_button',
    export_excel_salary_advance_button = 'export_excel_salary_advance_button',
    print_salary_advance_button = 'print_salary_advance_button',
    download_advance_salary_button = 'download_advance_salary_button',

    bonus_submenu = 'bonus_submenu',
    add_bonus_button = 'add_bonus_button',
    edit_bonus_button = 'edit_bonus_button',
    export_bonuses_button = 'export_bonuses_button',
    my_bonus_submenu = 'my_bonuses_submenu',
    export_my_bonuses_button = 'export_my_bonuses_button',
    export_pdf_my_bonuses_button = 'export_pdf_my_bonuses_button',
    export_excel_my_bonuses_button = 'export_excel_my_bonuses_button',
    print_my_bonuses_button = 'print_my_bonuses_button',

    my_paySlips_submenu = 'my_paySlips_submenu',
    view_paySlip_button = 'view_paySlip_button',
    downLoad_paySlip_button = 'downLoad_paySlip_button',
    ask_for_paySlip_button = 'ask_for_paySlip_button',
    view_paySlip_button_partner = 'Show_partner_payslip',
    downLoad_paySlip_For_button_partner = 'download_partner_payroll',

    paySlip_requests_submenu = 'paySlip_requests_submenu',
    export_paySlip_request_button = 'export_paySlip_request_button',
    export_paySlip_request_zip_button = 'export_paySlip_request_zip_button',
    export_pdf_paySlip_request_button = 'export_pdf_paySlip_request_button',
    export_excel_paySlip_request_button = 'export_excel_paySlip_request_button',
    print_paySlip_request_button = 'print_paySlip_request_button',
    confirm_paySlip_request_button = 'confirm_paySlip_request_button',

    ask_for_leave = "ask_for_leave",


    edit_collaborator_button = "edit_collab_button",
    resent_mail_button = "resent_mail_button",
    leaves_rules_collabs_button = "leaves_rules_collabs_button",
    view_collaborator_button = "view_collaborator_button",
    export_collabs_list_excel = "export_collabs_list_excel",


    pointage_details_button = "pointage_details_button",
    pointage_validate_button = "pointage_validate_button",
    pointage_statistics_button = "pointage_statistics_button",
    daily_pointage = "daily_pointage",
    reglement_achat = "reglement_achat",
    reglement_vente = "reglement_vente",
    add_reglement = "add_reglement",
    add_reglement_vente = "add_reglement_vente",

    bon_livraison_achat_reglements = "bon_livraison_achat_reglements",
    facture_achat_reglements = "facture_achat_reglements",
    devis_vente_reglements = "devis_vente_reglements",
    bon_commande_vente_reglements = "bon_commande_vente_reglements",
    bon_livraison_vente_reglements = "bon_livraison_vente_reglements",
    facture_vente_reglements = "facture_vente_reglements",
    add_reglement_sp = "add_reglement_sp",
    add_reglement_vente_sp = "add_reglement_vente_sp",

    courbe_variation_prix_button = "courbe_variation_prix_button",
    etat_planification_button = "etat_planification_button",
    statistiques_button = "statistiques_button",
    statistiques_categories_button = "statistiques_catégories_button",
    statistiques_produit_button = "statistiques_produit_button",
    valeur_stock_button = "valeur_stock_button",
    variation_stock_button = "Variation_stock_button",
    export_Cloture_Paie = 'exporter_variable_paie_boutton',
    export_excel_Cloture_Paie_button = 'export_excel_variable_paie_boutton',
    export_pdf_Cloture_Paie_button = 'exporter_pdf_varible_paie_boutton',
    export_pdf_Monthly_Pointage_Partner = 'export_pdf_monthly_Pointage_Partner',
    add_hotel_button = 'add_hotel_button',
    details_hotel_button = 'details_hotel_button',
    export_pdf_Monthly_Pointage_Collab = 'export_pdf_monthly_Pointage_Collab',
    PartnerAddAdvance = "Partne_add_advance_button",
    export_pdf_daily_Pointage = "export_pdf_daily_Pointage",
    upload_file_pointage = 'upload_file_pointage',
    calculate_score_pointage = 'calculate_score_pointage',
    leave_dashboard = 'leave_dashboard',

}
