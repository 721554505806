import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {catchError, map} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {ModuleElementIdDto} from "../../../models/module/ModuleElementIdDto";
import {CraActivityEnum} from "../../../enums/cra/CraActivityEnum";
import {CraTypeEnum} from "../../../enums/cra/cra-type-enum";
import { MaxNumberOfflineDto } from 'app/shared/models/module/MaxNumberOfflineDto';
import {HasInvThresholdDto} from "../../../models/module/HasInvThresholdDto";

@Injectable({
  providedIn: 'root'
})
export class ModuleService {
  updateFreePriceStockParams(payload: { uuid: string; hasFreePrice: any; authorityForFreePrice: string; }) {
    return this.http.put(`${this.baseUrl}/setting/updateFreePricesOptions`, payload).pipe(
        map((response: any) => {
            return response;
        })
    );
  }

    baseUrl = environment.moduleUrl;

    constructor(private http: HttpClient) {
    }

    updateModule(module: any) {
        return this.http.put(`${this.baseUrl}/modules/update`,module).pipe(map(response => {
            return response;
        }));
    }
    deleteModule(uuid: any) {
        return this.http.delete(`${this.baseUrl}/modules/delete/${uuid}`).pipe(map(response => {
            return response;
        }));
    }

    getAllModules(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/modules/getAllModules`).pipe(map(response => {
            return  response;
        }));
    }

    getAllParentModules(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/modules/parent-modules`).pipe(map(response => {
            return  response;
        }));
    }
    getAllModuleAclsByUserRoleAdmin(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/modules/getAllModuleAclsByUserRoleAdmin`).pipe(map(response => {
            return  response;
        }));
    }


    getSideBarMenuElements(userUuid: any): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/modules/getSideBarMenuElements/${userUuid}`).pipe(map(response => {
            return  response;
        }));
    }

    getAllSubModulesByModule(id : number): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/sub-modules/${id}`).pipe(map(response => {
            return  response;
        }));
    }

    getAllChildModules(parentModuleId: number): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/modules/child-modules/${parentModuleId}`).pipe(map(response => {
            return  response;
        }));
    }

    getSelectedOptionsByRole(uuid: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/modules/getSelectedOptionsByRole/${uuid}`).pipe(map(response => {
            return  response;
        }));
    }

    getSelectedOptionsByUser(uuid: string): Observable<any> {
        return this.http.get<ModuleElementIdDto>(`${this.baseUrl}/modules/getSelectedOptionsByUser/${uuid}`).pipe(map(response => {
            return  response;
        }));
    }

    isUserPermitted(payload) : Observable<boolean>{
        return this.http.post<boolean>(`${this.baseUrl}/modules/isUserPermitted`, payload);
    }

    getModule(uuid: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/modules/${uuid}`);
    }
    getModuleById(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/modules/getById/${id}`);
    }

    getSubModule(uuid: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/sub-modules/get/${uuid}`);
    }


    getConfig(): Observable<any>{
        return this.http.get<any>(`${this.baseUrl}/setting/getAllSetting`).pipe(map(response => {
            return response;
        }))
    }

    updateSkuSetting( data :any ): Observable<any>{
        return this.http.post<any>(`${this.baseUrl}/setting/updateSkuSetting` , data).pipe(map(response => {
            return response;
        }))
    }

    getConfigSku(): Observable<any>{
        return this.http.get<any>(`${this.baseUrl}/setting/getSkuConfig`).pipe(map(response => {
            return response;
        }))
    }

    getConfigContract(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/setting/config/contract/getConfigContract`).pipe(map(response => {
            return  response;
        }))
    }
    updateConfig(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/config/contract/updateConfig`, payload)
      };
    getPackModulesSideBar(uuid:any): Observable<any>{
        return this.http.get<any>(`${this.baseUrl}/packModuleSideBar/getByUuid/${uuid}`).pipe(map(response => {
            return response;
        }));
    }


    updateFacturationConfig(payload: any) {
        return this.http.put(`${this.baseUrl}/setting`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    updateGeneralFacturationParams(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/generalParams`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    updateFacturationOptions(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/facturationOptions`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    updateAdvanceUpdateOption(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/advanceOption`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    updatePaymentOptions(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/paymentOptions`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    updateStockProductOptions(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/productOptions`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    updateStockPaymentOptions(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/paymentStockOptions`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    updatePrelevementOptions(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/updatePrelevementOptions`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    updateArrivalOptions(payload: any) {
        return this.http.post(`${this.baseUrl}/setting/updateArrivalOptions`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    updatelocationOptions(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/updateLocationOptions`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    updateStockFacturationOptions(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/stockFacturationOptions`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    updateStockCancelDays(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/updateStockCancelDays`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    updateGeneralStockParams(payload: any) {
        return this.http.put(`${this.baseUrl}/setting/updateGeneralStockParams`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    createFacturationConfig(payload: any) {
        return this.http.post(`${this.baseUrl}/setting`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    getAllFacturationConfig(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/setting/`).pipe(map(response => {
            return  response;
        }));
    }

    createModule(payload: any) {
        return this.http.post(`${this.baseUrl}/modules/create`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    // getChildElements(payload) : Observable<any> {
    //     return this.http.get<any>(`${this.baseUrl}/modules/getChildElements/`, payload).pipe(map(response => {
    //         return  response;
    //     }));
    // }

    getChildElements(payload) : Observable<any> {
        let elementId = payload.elementId;
        return this.http.get<any>(`${this.baseUrl}/modules/getChildElements/${elementId}`).pipe(map(response => {
            return  response;
        }));
    }

    getTabsByUser(payload) : Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("userUuid", payload.userUuid);
        queryParams = queryParams.append("elementId", payload.elementId);
        return this.http.get(`${this.baseUrl}/modules/getTabsByUser/`, {params: queryParams});
    }

    getButtonsOfPage(payload) : Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("userUuid", payload.userUuid);
        queryParams = queryParams.append("elementId", payload.elementId);
        return this.http.get(`${this.baseUrl}/modules/getButtonsOfPage/`, {params: queryParams});
    }

    getAllModuleByPage(options: any):Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("pageNum", options.pageNum);
        queryParams = queryParams.append("pageSize", options.pageSize);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this.http.get(`${this.baseUrl}/modules/getAllByPage/`, {params: queryParams});
    }

    getAllMenus(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/modules/getAllModulesByMenuType`).pipe(map(response => {
            return  response;
        }));
    }

    updateModelPaie(payload): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("modelPaie", payload);
        return this.http.post(`${this.baseUrl}/setting/updateModelePaie`, null, { params: queryParams });
    }



    updateGracePeriode(gracePeriod: number): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("gracePeriod", gracePeriod);
        return this.http.post(`${this.baseUrl}/setting/gracePeriod`, queryParams);
    }


    createPasswordFormatRegex(payload): Observable<any> {
        return this.http.post(`${this.baseUrl}/setting/createPasswordFormatRegex`, payload);
    }

    getSystemSettingById(systemSettingId:number): Observable<any> {
        return this.http.get(`${this.baseUrl}/setting/getSystemSettingById/${systemSettingId}`);
    }

    createModulesPartnerPrice(partnerModulePrice:any): Observable<any> {
        return this.http.post(`${this.baseUrl}/modulesPrices/createModulesPartnerPrice`,partnerModulePrice);
    }
    getPartnerModulesPrice(userUuid:any): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("userUuid", userUuid);
        return this.http.get(`${this.baseUrl}/modulesPrices/getPartnerModulesPrice`,{params:queryParams});
    }
    getPartnerListModulesPrice(userUuid:string): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("userUuid", userUuid);
        return this.http.get(`${this.baseUrl}/modulesPrices/getPartnerListModulesPrice`,{params:queryParams});
    }
    deleteModulePartnerPrice(modulePriceId:string,userUuid:string): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("userUuid", userUuid);
        queryParams = queryParams.append("modulePriceId", modulePriceId);
        return this.http.delete(`${this.baseUrl}/modulesPrices/deleteModulePartnerPrice`,{params:queryParams});
    }
    updateCaisseOfPartner(payload:any): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("userUuid", payload.userUuid);
        queryParams = queryParams.append("caissePrice", payload.caissePrice);
        queryParams = queryParams.append("numberOfCaisse", payload.numberOfCaisse);
        return this.http.put(`${this.baseUrl}/modulesPrices/updateCaisseOfPartner`,null,{params:queryParams});
    }


    updateHsWaiterStatus(isWithServer: boolean): Observable<any> {
        const serverPresentString = isWithServer ? 'true' : 'false';
        return this.http.put<any>(`${this.baseUrl}/setting/updateHsWaiterStatus`, null, {
            params: new HttpParams().set('serverPresent', serverPresentString),
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }).pipe(
            map(response => response),
            catchError(error => {
                console.error('Erreur lors de la mise à jour du statut :', error);
                return throwError(() => new Error('Erreur lors de la mise à jour du statut'));
            })
        );
    }

    getHasWaiterStatus(): Observable<boolean> {
        return this.http.get<boolean>(`${this.baseUrl}/setting/hasWaiter`);
      }


getCraStatus(): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/setting/craMonthly`);
}

getCraAndActivityStatus(): Observable<any> {
    return  this.http.get<any>(`${this.baseUrl}/setting/cra/status`);
}
    getActivityCraStatus(): Observable<any> {
        return  this.http.get<any>(`${this.baseUrl}/setting/cra/activityEnum`);
    }
getPaydHolidaysStatus(): Observable<any> {
        return  this.http.get<any>(`${this.baseUrl}/setting/cra/holidays/status`);
}
    updateCraEnum(craActivityEnum: CraActivityEnum): Observable<any> {
        return this.http.put<any>(
            `${this.baseUrl}/setting/update-cra-enum?activity=${craActivityEnum}`,
            {}
        );
    }

    updateCra( craMonthly: boolean , withLeaves:boolean,  type: CraTypeEnum ): Observable<any> {
    const body = {
        craMonthly: craMonthly,
        withLeaves: withLeaves,
        type: type

    };
    return this.http.put<any>(`${this.baseUrl}/setting/update/cra`, body, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
    updatePayedHolidaysStatus(payedHolidays: boolean): Observable<any> {
        const params = new HttpParams().set('payedHolidaysStatus', payedHolidays.toString());

        return this.http.put<any>(`${this.baseUrl}/setting/cra/status/payed`, {}, {
            params: params,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
  updateTolerance(tolerance: any): Observable<any> {
    const params = new HttpParams().set('tolerance', tolerance);
    return this.http.put<any>(`${this.baseUrl}/setting/updateTolerance`, null, { params });
}


    CraUpdateStatus(craStatus: boolean): Observable<any> {
    const craMonthly =craStatus ? 'true' : 'false';
    return this.http.put<any>(`${this.baseUrl}/setting/update/cra`, null, {
        params: new HttpParams().set('craMonthly', craMonthly),
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }).pipe(
        map(response => response),
        catchError(error => {
            return throwError(() => new Error('Erreur lors de la mise à jour du statut'));
        })
    );
}


      createModulePrices(payload): Observable<any> {

        return this.http.post(`${this.baseUrl}/modulesPrices/createModulePrices`, payload);
    }
    updateColumnName(payload){
        let params = new HttpParams()
        .set("oldColumnName", payload.oldColumnName)
        .set("newColumnName", payload.newColumnName);
        return this.http.put(`${this.baseUrl}/modulesPrices/updateColumnName`,null,{params:params});
    }
    deleteColumn(columnName){
        let params = new HttpParams()
        .set("columnName", columnName);
        return this.http.delete(`${this.baseUrl}/modulesPrices/deleteColumn`,{params:params});
    }
    getAllModulePrices(): Observable<any> {
        return this.http.get(`${this.baseUrl}/modulesPrices/getAllModulePrices`);
    }
    removeModulePrice(moduleId:number): Observable<any> {
        return this.http.delete(`${this.baseUrl}/modulesPrices/deleteModulePrice/${moduleId}`);
    }
    getCaissePriceById(systemCaisseId:number): Observable<any> {
        return this.http.get(`${this.baseUrl}/modulesPrices/getCaissePriceById/${systemCaisseId}`);
    }
    updateCaissePrice(caisse:any): Observable<any> {
        return this.http.put(`${this.baseUrl}/modulesPrices/updateCaissePrice`,caisse);
    }
getCurrentTolerance(): Observable<number> {
    return this.http.get<number>(`${this.baseUrl}/setting/tolerance`);
  }

updateCapacité(capacite: any): Observable<any> {
const params = new HttpParams().set('maxLotCapacity', capacite);
return this.http.put<any>(`${this.baseUrl}/setting/updateMaxLotCapacity`, null, { params });
}

getCurrentCapacité(): Observable<number> {
    return this.http.get<number>(`${this.baseUrl}/setting/maxLotCapacity`);
  }


    getAllUserNotified(){
        return this.http.get<any>(`${this.baseUrl}/users-notified/get-all-by-tenant`);

    }
    addUserNotified(payload){
        return this.http.post(`${this.baseUrl}/users-notified/create`, payload);
    }
    deleteUserNotified(uuid){
        return this.http.delete(`${this.baseUrl}/users-notified/delete/${uuid}`);
    }

    updateUserNotified(payload){
        return this.http.put(`${this.baseUrl}/users-notified/update`, payload);
    }

updateCommission(commission: string): Observable<any> {
  const params = new HttpParams().set('commission', commission);
  return this.http.put<any>(`${this.baseUrl}/setting/updateComission`, null, { params });
}

getCurrentCommission(): Observable<number> {
     return this.http.get<number>(`${this.baseUrl}/setting/commission`);
 }

    updateHasPriceVerification(hasPriceVerification: boolean): Observable<any> {
        const params = new HttpParams().set('hasPriceVerification', hasPriceVerification.toString());
        return this.http.put<any>(`${this.baseUrl}/setting/updateHasPriceVerification`, null, { params });
    }
    getPriceVerification(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/setting/hasPriceVerification`);
    }
    gethasCarLocationType(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/setting/hasCarLocationType`);
    }

    getMaxNumberOfflineRequest(partnerUuid: string): Observable<MaxNumberOfflineDto> {
        const params = new HttpParams().set('partnerUuid', partnerUuid);
        return this.http.get<MaxNumberOfflineDto>(`${this.baseUrl}/setting/max-number-offline-request`, { params });
    }

    updateMaxNumberOfflineRequest(maxNumberOfflineRequest: MaxNumberOfflineDto): Observable<MaxNumberOfflineDto> {
        return this.http.put<MaxNumberOfflineDto>(`${this.baseUrl}/setting/max-number-offline-request`, maxNumberOfflineRequest);
    }
    getEnterpriseOrigin(partnerUuid: string): Observable<any> {
        const params = new HttpParams().set('partnerUuid', partnerUuid);
        return this.http.get<any>(`${this.baseUrl}/setting/get-enterprise-origin`, { params });
    }

    updateEnterpriseOrigin(payload): Observable<any> {
        return this.http.put<any>(`${this.baseUrl}/setting/update-enterprise-origin`, payload);
    }

    getHasInvThreshold(): Observable<HasInvThresholdDto> {
        return this.http.get<HasInvThresholdDto>(`${this.baseUrl}/setting/has-inv-threshold`);
    }

    updateHasInvThreshold(hasInvThreshold: HasInvThresholdDto): Observable<HasInvThresholdDto> {
        return this.http.put<HasInvThresholdDto>(`${this.baseUrl}/setting/has-inv-threshold`, hasInvThreshold);
    }


    updateSteLivParams(payload:{uuid:string;steLivraisonNames:string[];hasSteLivraison:boolean}){
        return this.http.patch(`${this.baseUrl}/setting/update-ste-livraison-Params`, payload).pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

}

