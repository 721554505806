import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class NationalitiesService {
  nationalities = [

'Tunisienne',
'Palestinienne',
'Française',
'Libyenne',
'Italienne',
'Bahreïnienne',
'Canadienne',
'Afghane',
'Albanaise',
'Algérienne',
'Américaine',
'Andorrane',
'Angolaise',
'Antiguaise',
'Argentine',
'Arménienne',
'Australienne',
'Autrichienne',
'Azérie',
'Bahamienne',
'Bahreïnienne',
'Bangladaise',
'Barbadienne',
'Bélarusse',
'Belge',
'Bélizienne',
'Béninoise',
'Bhoutanaise',
'Bolivienne',
'Bosnienne',
'Brésilienne',
'Britannique',
'Brunéienne',
'Bulgare',
'Burkinabée',
'Birmane',
'Burundaise',
'Cambodgienne',
'Camerounaise',
'Cap-verdienne',
'Centrafricaine',
'Tchadienne',
'Chilienne',
'Chinoise',
'Colombienne',
'Comorienne',
'Congolaise',
'Costaricaine',
'Croate',
'Cubain(e)',
'Chypriote',
'Tchèque',
'Danoise',
'Djiboutienne',
'Dominicaine',
'Néerlandaise',
'Timoraise',
'Équatorienne',
'Égyptienne',
'Émiratie',
'Guinéenne-Équatoriale',
'Érythréenne',
'Estonienne',
'Éthiopienne',
'Fidjienne',
'Philippine',
'Finlandaise',
'Gabonaise',
'Gambienne',
'Géorgienne',
'Allemande',
'Ghanéenne',
'Grecque',
'Grenadienne',
'Guatémaltèque',
'Bissaoguinéenne',
'Guinéenne',
'Guyanaise',
'Haïtienne',
'Hondurienne',
'Hongroise',
'Kiribatienne',
'Islandaise',
'Indienne',
'Indonésienne',
'Iranienne',
'Irakienne',
'Irlandaise',
'Ivoirienne',
'Jamaïcaine',
'Japonaise',
'Jordanienne',
'Kazakhstanaise',
'Kenyan(ne)',
'Kittitienne-Nevisienne',
'Koweïtienne',
'Kirghize',
'Laotienne',
'Lettonne',
'Libanaise',
'Libérienne',
'Libyenne',
'Liechtensteinoise',
'Lituanienne',
'Luxembourgeoise',
'Macédonienne',
'Malgache',
'Malawienne',
'Malaisienne',
'Maldivienne',
'Malienne',
'Maltaise',
'Marshallaise',
'Mauritanienne',
'Mauricienne',
'Mexicaine',
'Micronésienne',
'Moldave',
'Monégasque',
'Mongole',
'Monténégrinne',
'Mozambicaine',
'Namibienne',
'Nauruanne',
'Népalaise',
'Néo-Zélandaise',
'Nicaraguayenne',
'Nigérianne',
'Nigérienne',
'Nord-coréenne',
'Irlandaise du Nord',
'Norvégienne',
'Omanaise',
'Pakistanaise',
'Paluanne',
'Panaméenne',
'Papouasienne-Néo-Guinéenne',
'Paraguayenne',
'Péruvienne',
'Polonaise',
'Portugaise',
'Qatarie',
'Roumaine',
'Russe',
'Rwandaise',
'Saint-Lucienne',
'Salvadorienne',
'Samoane',
'Saint-Marinaise',
'Santoméenne',
'Saoudienne',
'Écossaise',
'Sénégalaise',
'Serbe',
'Seychelloise',
'Sierra-Léonaise',
'Singapourienne',
'Slovaque',
'Slovène',
'Salomonienne',
'Somalienne',
'Sud-Africaine',
'Sud-Coréenne',
'Espagnole',
'Sri-Lankaise',
'Soudanaise',
'Surinamienne',
'Swazie',
'Suédoise',
'Suisse',
'Syrienne',
'Taïwanaise',
'Tadjike',
'Tanzanienne',
'Thaïlandaise',
'Togolaise',
'Tonguienne',
'Trinidadienne-Tobagonienne',
'Turcque',
'Tuvaluanne',
'Ougandaise',
'Ukrainienne',
'Uruguayenne',
'Ouzbekouzbek',
'Vénézuélienne',
'Vietnamienne',
'Galloise',
'Yéménite',
'Zambienne',
'Zimbabwéenne',

  ];

  arabNationalities = [
    'تونسية',
    'فلسطينية',
    'فرنسية',
    'ليبية',
    'إيطالية',
    'بحرينية',
    'كندية',
    'أفغانية',
    'ألبانية',
    'جزائرية',
    'أمريكية',
    'أندورية',
    'أنغولية',
    'أنتيغوا',
    'أرجنتينية',
    'أرمنية',
    'أسترالية',
    'نمساوية',
    'أذرية',
    'باهامية',
    'بحرينية',
    'بنغلاديشية',
    'بربادوسية',
    'بيلاروسية',
    'بلجيكية',
    'بليزية',
    'بنينية',
    'بوتانية',
    'بوليفية',
    'بوسنية',
    'برازيلية',
    'بريطانية',
    'بروناوية',
    'بلغارية',
    'بوركينية',
    'بورمية',
    'بوروندية',
    'كمبودية',
    'كاميرونية',
    'رأس أخضرية',
    'أفريقيا الوسطى',
    'تشادية',
    'تشيليانية',
    'صينية',
    'كولومبية',
    'قمورية',
    'كونغولية',
    'كوستاريكية',
    'كرواتية',
    'كوبية',
    'قبرصية',
    'تشيكية',
    'دانماركية',
    'جيبوتية',
    'دومينيكية',
    'هولندية',
    'تيمورية',
    'إكوادورية',
    'مصرية',
    'إماراتية',
    'غينية استوائية',
    'إريترية',
    'إستونية',
    'إثيوبية',
    'فيجية',
    'فلبينية',
    'فنلندية',
    'غابونية',
    'غامبية',
    'جورجية',
    'ألمانية',
    'غانية',
    'يونانية',
    'غرينادية',
    'غواتيمالية',
    'غينيا بيساوية',
    'غينية',
    'غويانية',
    'هايتية',
    'هندوراسية',
    'هنغارية',
    'كيريباتية',
    'أيسلندية',
    'هندية',
    'إندونيسية',
    'إيرانية',
    'عراقية',
    'إيرلندية',
    'ساحل العاج',
    'جامايكية',
    'يابانية',
    'أردنية',
    'كازاخستانية',
    'كينية',
    'سانت كيتسية نيفيسية',
    'كويتية',
    'قيرغيزية',
    'لاوية',
    'لاتفية',
    'لبنانية',
    'ليبيرية',
    'ليبية',
    'ليختنشتاينية',
    'لتوانية',
    'لوكسمبورغية',
    'مقدونية',
    'مدغشقرية',
    'مالاوية',
    'ماليزية',
    'مالديفية',
    'مالية',
    'مالطية',
    'مارشالية',
    'موريتانية',
    'موريشيوسية',
    'مكسيكية',
    'ميكرونيزية',
    'مولدوفية',
    'موناكية',
    'منغولية',
    'مونتينيغرية',
    'موزمبيقية',
    'ناميبية',
    'ناوروية',
    'نيبالية',
    'نيوزيلندية',
    'نيكاراغوية',
    'نيجيرية',
    'نيجرية',
    'شمالية كورية',
    'شمالية أيرلندية',
    'نرويجية',
    'عمانية',
    'باكستانية',
    'بالاوية',
    'بنمية',
    'بابواسية غينية',
    'باراغوية',
    'بيروفية',
    'بولندية',
    'برتغالية',
    'قطرية',
    'رومانية',
    'روسية',
    'رواندية',
    'سانت لوسية',
    'سلفادورية',
    'ساموانية',
    'سانت مارينية',
    'ساوتومية',
    'سعودية',
    'إسكتلندية',
    'سنغالية',
    'صربية',
    'سيشيلية',
    'سيراليونية',
    'سنغافورية',
    'سلوفاكية',
    'سلوفينية',
    'سليمانية',
    'صومالية',
    'جنوب أفريقية',
    'جنوب كورية',
    'إسبانية',
    'سريلانكية',
    'سودانية',
    'سورينامية',
    'سوازية',
    'سويدية',
    'سويسرية',
    'سورية',
    'تايوانية',
    'طاجيكية',
    'تنزانية',
    'تايلاندية',
    'توجولية',
    'تونغية',
    'ترينيداد توباغية',
    'تركية',
    'توفالية',
    'أوغندية',
    'أوكرانية',
    'أوروغوانية',
    'أوزبكية',
    'فنزويلية',
    'فيتنامية',
    'ويلزية',
    'يمنية',
    'زامبية',
    'زيمبابوية'
  ];
  
  constructor() {
  }
}
