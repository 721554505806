export class ConfirmationForPopupDto {
    public title?: string;
    public message?: string;
    public icon: Icon;
    public actions: Action;
    public dismissible?: boolean;

    constructor(
        title?: string,
        message?: string,
        icon?: Icon,
        actions?: Action,
        dismissible?: boolean
    ) {
        this.title = title;
        this.message = message;
        this.icon = icon || new Icon();
        this.actions = actions || new Action();
        this.dismissible = dismissible;

        if (!this.actions.cancel) {
            this.actions.cancel = new Cancelation();
        }
        if (!this.actions.confirm) {
            this.actions.confirm = new Confirmation();
        }
    }
}

 class Action {
    confirm: Confirmation;
    cancel: Cancelation;

    constructor() {
        this.confirm = new Confirmation();
        this.cancel = new Cancelation();
    }
}

 class Confirmation {
    show: boolean;
    label: string;
    color: string;

    constructor(show: boolean = false, label: string = '', color: string = '') {
        this.show = show;
        this.label = label;
        this.color = color;
    }
}

 class Cancelation {
    show: boolean;
    label: string;

    constructor(show: boolean = false, label: string = '') {
        this.show = show;
        this.label = label;
    }
}

 class Icon {
    show: boolean;
    name: string;
    color: string;

    constructor(show: boolean = false, name: string = '', color: string = '') {
        this.show = show;
        this.name = name;
        this.color = color;
    }
}
