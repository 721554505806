import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseVerticalNavigationComponent } from '@fuse/components/navigation/vertical/vertical.component';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { FuseUtilsService } from '@fuse/services/utils/utils.service';
import { ViewOptions } from 'app/components/admin/view-options';
import { StockService } from 'app/shared/services/backend/stock/stock.service';
import { ModuleService } from 'app/shared/services/backend/module/module.service';
import { CommonService } from 'app/shared/services/frontend/commun/common.service';
import { WebsocketService } from 'app/layout/common/notifications/websocket.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { ApiPaths } from 'app/shared/constants/apiPaths';

@Component({
    selector       : 'fuse-vertical-navigation-basic-item',
    templateUrl    : './basic.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseVerticalNavigationBasicItemComponent implements OnInit, OnDestroy
{
    @Input() item: FuseNavigationItem;
    @Input() name: string;

    isActiveMatchOptions: IsActiveMatchOptions;
    private _fuseVerticalNavigationComponent: FuseVerticalNavigationComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    nbrNotifAssurance: number;
    nbrNotifVidange: number;
    options: ViewOptions = {
        sortField: 'created',
        sortDirection: 'desc',
        pageNum: 1,
        pageSize: 5,
    };
    notifications: any[];
    unreadCount: number = 0;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseUtilsService: FuseUtilsService,
        private stockService: StockService,
        private communService : CommonService,
        private websocketService :WebsocketService,
        private notificationService : NotificationsService,
    )
    {
        // Set the equivalent of {exact: false} as default for active match options.
        // We are not assigning the item.isActiveMatchOptions directly to the
        // [routerLinkActiveOptions] because if it's "undefined" initially, the router
        // will throw an error and stop working.
        this.isActiveMatchOptions = this._fuseUtilsService.subsetMatchOptions;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        if (this.item.link === 'admin/stock/vehicleInsurance'){
            this.getNbrNotifAssurance();
            this.updateNbrNotifAssurance();
        }

    this.updateNbrNotifVehicle()
        this.isActiveMatchOptions =
            this.item.isActiveMatchOptions ?? this.item.exactMatch
                ? this._fuseUtilsService.exactMatchOptions
                : this._fuseUtilsService.subsetMatchOptions;

        // Get the parent navigation component
        this._fuseVerticalNavigationComponent = this._fuseNavigationService.getComponent(this.name);

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Subscribe to onRefreshed on the navigation component
        this._fuseVerticalNavigationComponent.onRefreshed.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(() => {

            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    updateNbrNotifVehicle(){
        if (this.item.link === ApiPaths.VEHICLE_MAINTENANCE) {
            this.connect();
            let uuid = localStorage.getItem('useruuid');
            this.notificationService.getAllWithContext(uuid)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((notifications: any) => {
                this.notifications = notifications;
                this._calculateUnreadCount();
                this._changeDetectorRef.markForCheck();
              });
        
            this.notificationService.notificationCount$.pipe(takeUntil(this._unsubscribeAll))
              .subscribe((count: number) => {
                this.nbrNotifVidange = count;
                this._changeDetectorRef.markForCheck();
              });
          }

    }
    connect(): void {
        this.websocketService.connect();

        // subscribe receives the value.
        this.notificationService.notificationCount.subscribe((data) => {
            this.notifications.push(data);
            this._calculateUnreadCount();
            this._changeDetectorRef.markForCheck();

        });
    }

    disconnect(): void {
        this.websocketService.disconnect();
    }

    private _calculateUnreadCount(): void {
        let count = 0;

        if (this.notifications && this.notifications.length) {
            count = this.notifications.filter(notification => notification.status == 'UNSEEN').length;
        }

        this.unreadCount = count;
        this.nbrNotifVidange = count
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    getNbrNotifAssurance() {
        this.stockService.getAllInsurancesExpiringSoon(this.options, null, null, null, null, null).subscribe(
            ({
                next: (res: any) => {
                    this.nbrNotifAssurance = res.totalElements;
                    this._changeDetectorRef.detectChanges();
                },
                error(err) {
                    console.error(err)
                }
            })
        );
    }

    updateNbrNotifAssurance() {
        this.communService.nbrNotifAssurance$.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(
            value => {
                this.nbrNotifAssurance = value;
                this._changeDetectorRef.detectChanges();
            }
        );
    }

}
