export enum ImagePaths {
    pendingIcon = '../../assets/imgs/pending.svg',
    addPencilIcon = '../../assets/imgs/ajout-pencil.svg',
    detailsIcon = '../../assets/imgs/details.svg',
    checkIcon = '../../assets/imgs/check.png',
partnerLogo = '../../../../assets/imgs/admin.svg',
    checkList = '../../assets/imgs/check_list.svg',
    pdf = '../../assets/imgs/pdf.svg',
    invalidCraIcon = '../../assets/imgs/invalidCra.svg',
    validCraIcon = '../../assets/imgs/validCra.svg',
    valid = '../../assets/icons/valid.svg',
    export = '../../../../assets/icons/export.svg',
    craValid = '../../../../assets/icons/CraValid.svg',
    exportCRM = '/assets/imgs/Export.svg',
    sendEmail = "/assets/imgs/resmailbtn.svg",
    calendar = "/assets/imgs/calendar.svg",
    editIcon = "/assets/imgs/pencil.svg",
    defaultAvatar = "/assets/imgs/admin.svg",
    cinFront = '/assets/imgs/frontcin.png',
    cinBack = '/assets/imgs/backcin.png',
    calendar2='assets/imgs/leaveStatistics.svg',
    export2='/assets/imgs/exportHistory.svg',
    logoWIND ='/assets/imgs/Logowind.svg',


    validPointage = '/assets/imgs/valid-pointage.png',
    clockAlert = '/assets/imgs/clockAlert.png',
    pointageIcon = '/assets/imgs/pointageIcon.png',
    breakIcon = '/assets/imgs/breakIcon.png',
    lastTimePointage = '/assets/imgs/lastTimePointage.png',
    dateIcon = '/assets/imgs/dateIcon.png',
    statusIcon = '/assets/imgs/statusIcon.png',
    rttIcon = '/assets/imgs/rttIcon.png',
    break = '/assets/imgs/break-icon.png',
    statistics= '/assets/imgs/statistics.svg',
    addAmicale = '/assets/imgs/add-amicale.svg',
    percent = '/assets/imgs/percent.svg',
    logoAmicaleImage = '/assets/imgs/logo-amical-image.svg',
    fileLogo = '/assets/imgs/file-logo.svg',
    orSeparator = '/assets/imgs/or-separator.svg',
    prendCharge = '/assets/imgs/prend_charge.svg',
    datePicker = '/assets/imgs/date-picker.svg',
    logoValidAmicale = '/assets/imgs/logoValidAmicale.svg',
    pieceJointe = '/assets/imgs/piece-jointe.svg',
    approverAmicale = '/assets/imgs/approve.svg',
    validAmicale = '/assets/imgs/valid-amicale.svg',
    remiseCash = '/assets/imgs/remise-cash.svg',
    detailAmicale = '/assets/imgs/detail-amical.svg',
    back = '/assets/imgs/arrow.svg',
    closeButton='/assets/imgs/close_button.svg',
 arrow ='../../assets/imgs/arrow.svg',
    addPencil='/assets/imgs/ajout-pencil.svg',
    producthuntpng ='./assets/imgs/producthunt.png',
    editPencil='/assets/imgs/edit.svg',
    moneyIcon='./assets/imgs/moneyiconyellow.svg',
    producthunt='./assets/imgs/producthunt.png',
    loadMore='./assets/imgs/loading.svg',
    payment = '../../../../../assets/imgs/paiment.svg',
    reglement = 'assets/imgs/reglement.svg',
    trace = '../../../../../assets/imgs/Tracé 25638.svg',
    KOUNOUZ_TRAVEL='/assets/imgs/kounouz-travel.svg',
    UMRAH='/assets/images/kounouz/kn-1.svg',
    FLIGHT='/assets/images/kounouz/kn-2.svg',
    EVENT='/assets/images/kounouz/kn-3.svg',
    HOTEL='/assets/images/kounouz/kn-4.svg',
    ORGANIZED_TRIP='/assets/images/kounouz/kn-5.svg',
    B2C='/assets/images/kounouz/b2c.svg',
    SOCIETE='/assets/images/kounouz/societe.svg',
    AMICALE='/assets/images/kounouz/amicale.svg',
    AGENCE='/assets/images/kounouz/agence.svg',
    RABATEUR='/assets/images/kounouz/rabateur.svg',
    SELL_ON_LINE='/assets/images/kounouz/sell-on-line.svg',
    viewDoc  ='/assets/imgs/viewDoc.svg',
    exportfile='/assets/imgs/upload-up.svg',
    //moneyIcon='../../../../assets/icons/money.png',
transformIcon = './assets/imgs/transformIcon.svg',
    cancelIcon = './assets/imgs/cancelIcon.svg',
    holiday = '/assets/imgs/vacance2.svg',
    pending ='assets/imgs/pending1.svg',
    Facebook = '../../../../../../assets/svg-social-media/facebook.svg',
    Instagram = '../../../../../../assets/svg-social-media/instagram.svg',
    Messenger='../../../../../../assets/svg-social-media/messenger.svg',
    WhatApp='../../../../../../assets/svg-social-media/whatsApp.svg',
    Reaction = '../../../../../../assets/svg-social-media/reaction.svg',
    Message='../../../../../../assets/svg-social-media/message.svg',
    System='../../../../../../assets/svg-social-media/system.svg',
    TOP_STARS='/assets/images/kounouz/top-stars.svg',
    validCra ='assets/imgs/check.svg',
    viewIcon = './assets/imgs/view.svg',
    logoWindErp = '../../../../assets/imgs/LogoWindERP.webp',
    gestEmp = '../../../../assets/imgs/gestEmp.webp',
    gestStock ='../../../../assets/imgs/gestStock.webp',
    systemeErp= '../../../../assets/imgs/systemeErp.webp',
    facturation ='../../../../assets/imgs/facturation.webp',
    tunisiaFlag ='../../../../assets/imgs/tunisiaFlag.webp',
    vector  = "./assets/imgs/Vector.png",
    groupIcon  = "./assets/imgs/Group.png",
    checkedIcon = "./assets/imgs/checkYellow.png",
    checkedGreenIcon = "./assets/imgs/checkGreen.png",
    moneyIconYellow = "./assets/imgs/money.png",
    caisseTransfert='./assets/imgs/caisse-transfert.svg',
    blueCaisse='./assets/imgs/blue-caisse.svg',
    lock='./assets/imgs/lock.svg',
    unLock='./assets/imgs/unlock.svg',
    eye="./assets/imgs/eye.svg",
    transferFund="./assets/imgs/transfertInterCaisse.svg"
}
