
import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {Subject, takeUntil} from 'rxjs';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher';
import {FuseNavigationService, FuseVerticalNavigationComponent} from '@fuse/components/navigation';
import {Navigation} from 'app/core/navigation/navigation.types';
import {NavigationService} from 'app/core/navigation/navigation.service';
import {UserService} from 'app/shared/models/auth/user/user.service';
import {ImageService} from 'app/shared/services/backend/image/image.service';
import {AuthService} from "../../../../core/auth/auth.service";
import { PartnerService } from 'app/shared/services/backend/partner/partner.service';
import { SirhService } from 'app/shared/services/backend/collaborator/sirh.service';
import { environment } from 'environments/environment';
import { UserInfo } from 'app/shared/models/auth/user/userInfo';
import {ImagePaths} from "../../../../shared/constants/imagePaths";
import {CommercialService} from "../../../../shared/services/backend/commercial/commercial.service";
import {ModuleService} from "../../../../shared/services/backend/module/module.service";
import {ElementIdEnum} from "../../../../shared/enums/Module/element-id-enum";
// import {User} from 'app/shared/models/auth/user/user.types';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ClassyLayoutComponent implements OnInit,AfterViewInit ,OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    baseUrl = environment.dmsUrl
    permissions: string[] = [];

    user: UserInfo = {
        uuid :"",
        name: "user",
        email: "user@user.fr",
        avatar: null,

    }
    sirhUrl = environment.sirhUrl;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    _toggleNavigation:boolean=true;
    mediaQuery:any;
    dmsUrl : string
    constructor(
        private _router: Router,
        private authService:AuthService,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private partnerService : PartnerService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private imageService: ImageService,
        private sirhService : SirhService,
        private commercialService : CommercialService,
        private moduleService: ModuleService,
    ) {
        this.dmsUrl =environment.dmsUrl
    }
    ngAfterViewInit(): void {
        this.mediaQuery = window.matchMedia("(max-width:800px)");
        this.mediaQuery.addEventListener('change',(event)=>{
            if(this._toggleNavigation){
                this._toggleNavigation = !this._toggleNavigation
                this.toggleNavigation(null)

            }
        })
        this.mediaQuery = window.matchMedia("(min-width:1050px)");
        this.mediaQuery.addEventListener('change',(event)=>{

            if(!this._toggleNavigation){
                this._toggleNavigation=!this._toggleNavigation
                this.toggleNavigation(null)

            }
        })
    }


    ngOnInit(): void {
        this.fillUserInfo();
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }


    fillUserInfo() {
        let uuid = localStorage.getItem('useruuid');
        if (this.authService.isAdmin()) {
            this.partnerService.getPartnerUser(uuid).subscribe({
                next: (res) => {
                    this.user.name = res.company_name;
                    this.user.email = res.email;
                    this.user.avatar = environment.dmsUrl + res?.alfrescoUrlLogo ?? ImagePaths.logoWIND;

                }
            })
        }
        else if (this.authService.isUser() || this.authService.isAgent()) {

            this.sirhService.getCollaboratorByUuid(uuid).subscribe({
                next: (res) => {
                    this.user.name =  res.firstname + " " + res.lastname;
                    this.user.email = res.proEmail;
                    this.user.avatar = res.personalPicture;

                }
            })
        }else if ( this.authService.isCommercial()){
            this.commercialService.getCommercialByUuid(uuid).subscribe({
                next: (res) => {
                    this.user.name =  res.name
                    this.user.email = res.email;
                }
            })

        }
        else if (this.authService.isSystem()) {
            this._userService.getUserByUUID(uuid).subscribe({
                next: (res) => {
                    this.user.name =  "System";
                    this.user.email = res.email;
                    this.user.avatar =  ImagePaths.logoWIND;
                }
            })
        }

         this._userService.avatar$
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((imageAvatar) => {
                        if (imageAvatar) {
                            this.user.avatar = imageAvatar;
                        }
                    });

                    this._userService.email$
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((email) => {
                        if (email) {
                            this.user.email = email;
                            this.getUserPermissions();
                        }
                    });
                    this._userService.name$
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((name) => {
                        if (NamedNodeMap) {
                            this.user.name = name;
                        }
                    });

    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._userService.destroy();
        this._navigationService.destroy();
        this._fuseMediaWatcherService.destroy()
        this.imageService.destroy()
        this.navigation = null;
        this.user = null
        this.authService.destroy()
        this._unsubscribeAll.complete();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);
        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
            this.navigationChangeState();
        }
    }

    /**
 * Sign out
 */
    signOut(): void {
        this._userService.clearAvatarCache();
        localStorage.removeItem("sessionUuid")
        this._router.navigate(['/log/sign-out']);
    }

    navigationChangeState(){
        if(this._toggleNavigation===false){
            setTimeout(()=>{this._toggleNavigation=!this._toggleNavigation;},200)
        }else{this._toggleNavigation=!this._toggleNavigation;
    }

   }
    private getUserPermissions() {
        let uuid = localStorage.getItem('useruuid');
        this.moduleService.getSelectedOptionsByUser(uuid).subscribe({
            next: (res) => {
                this.permissions = res.map((option: any) => option.idMenu);
            }
        })
    }
    hasPermission(elementId: string): boolean {
        return this.permissions.includes(elementId);
    }
    navigateToIdentificationSecurity(): void {

        this._router.navigate(['employee/profile/identification-security']);

    }
    protected readonly ElementIdEnum = ElementIdEnum;
}
