import { Injectable } from '@angular/core';
import {LocalStorageService} from "../../../../core/local-storage/local-storage.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import { Observable, catchError, map, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DmsService {
    baseUrl = environment.dmsUrl
    headers :any;

  constructor(private http: HttpClient,
              private localSt: LocalStorageService) {

  }

  previewDoc(documentIdentifier: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/documents/v2/${documentIdentifier}`,{responseType: 'blob', observe: 'response'}).pipe(map(response => {
      return  response
    }));
  }
  getDocument(documentIdentifier: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/documents/v3/${documentIdentifier}`);
  }

  loadProfilePhoto(userId: string) {
    return this.http.get(`${this.baseUrl}/alfresco/file/${userId}/image/content`, { responseType: 'text' })
   }

  deleteImage(nodeId : string){
    return this.http.delete(`${this.baseUrl}/alfresco/file/${nodeId}/image`);
  }


  getNodeId(uuidAmical:string){
    let params = new HttpParams()
      .set('uuidAmical',uuidAmical);
    return this.http.get(`${this.baseUrl}/alfresco/get-image-logo-amical`,{params})
  }

  getImage(nodeId: string | ArrayBuffer) {
    const url = `${this.baseUrl}/alfresco/nodes/${nodeId}/content`;
    return this.http
      .get(url,{responseType:'blob'})
      .pipe(map((response) => {
      return response
    }));
  }

loadProfilePhotoAlfresco (imageId: any) {
    return this.http.get(`${this.baseUrl}/alfresco/file/${imageId}/download`, { responseType: 'blob' })
   }
  deleteFile(nodeId : string){
    return this.http.delete(`${this.baseUrl}/alfresco/file/${nodeId}`);
  }
}
