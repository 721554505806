import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Inject, Injectable, Injector, NgZone} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map, timeout} from 'rxjs/operators';
import {AUTHORISATION_BEARER_HEADER, GLOBAL_AJAX_TIMEOUT} from '../../../constants/global';
import {LocalStorageService} from "../../frontend/local-storage/local-storage.service";
import {Account} from "../../../models/data/account";
import {PartnerModel} from "../../../models/partner/PartnerModel";
import {PartnerAddressModel} from "../../../models/partner/AddressPartnerModel";
import {PartnerRibModel} from "../../../models/partner/RibPartnerModel";
import {AdresseModel} from "../../../models/sirh/adresse/adresse";
import {environment} from "../../../../../environments/environment";
import {PreferenceModel} from "../../../models/partner/preferenceModel";
import { ViewOptions } from 'app/components/admin/view-options';
import { PartnerDataDto } from 'app/shared/models/partner/PartnerDataDto';
import {AgencyModel} from "../../../models/partner/AgencyModel";
import moment                from "moment/moment";
import {PartnerAgencyModel} from "../../../models/partner/PartnerAgencyModel";

@Injectable({
    providedIn: 'root'
})
export class PartnerService {
    errorMessage = '';
    header: HttpHeaders;
    // baseUrl: string = 'http://ns3012518.ip-149-202-74.eu:8762/wind-partner';
    baseUrl = environment.partnersUrl;
    private partnerImage: any;
    private patternResult: PartnerModel;
    private partner: PartnerModel;
    public partnerAddressResult: PartnerAddressModel;
    public partnerRibResult: PartnerRibModel;
    parametersAddadress: AdresseModel;
    public uuid: any;
    private ActivePartenaire: PartnerModel;
    private apiUrl = 'https://test.clictopay.com/payment/rest/register.do';

    //private partner: PartnerModel;
    constructor(private http: HttpClient, private localSt: LocalStorageService, private injector: Injector, private zone: NgZone) {

        this.header = new HttpHeaders().append(AUTHORISATION_BEARER_HEADER.name, localStorage.getItem('token'));
    }

    getPartnerInfo() {
        return this.http.get<{ email: string; uuid: string }>(`${this.baseUrl}/partners/partner-info`);
    }




    private exampleDataSubject = new BehaviorSubject<any>(null);
    exampleData$ = this.exampleDataSubject.asObservable();

    setExampleData(data: any) {
      this.exampleDataSubject.next(data);
    }
    addpicture(formData: any, uuid: any) {
        return this.http.post(`${this.baseUrl}/partners/uploadFiles/${uuid}`, formData).pipe(
            timeout(60000),
            catchError((e, c) => {
                return []; // only for catch error to work, it requires a return of array, object..
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    addAdresse(payload) {
        return this.http.post(`${this.baseUrl}/addresses`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return []; // only for catch error to work, it requires a return of array, object..
            }),
            map((response: any) => {
                return response;
            })
        );
    }


    ModifyStatutAddresse(payload: any) {
        return this.http.put<any>(`${this.baseUrl}/addresses`, payload, {
            headers: new HttpHeaders().append(AUTHORISATION_BEARER_HEADER.name, localStorage.getItem('token')).set('enctype', 'multipart/form-data')
        }).pipe(
            timeout(60000),
            catchError((e, c) => {
                return []; // only for catch error to work, it requires a return of array, object..
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    getAllAddresseBytenantId(): Observable<any> {
        return this.http.get<AdresseModel>(`${this.baseUrl}/addresses/getAddressesByTenantId/`)
    }

    private setPartnerParams(options :ViewOptions ,requestedParams: any) {

        let params = new HttpParams();
        if (requestedParams.name) {
            params = params.set('name', requestedParams.name);
        }
        if (requestedParams.email) {
            params = params.set('email', requestedParams.email);
        }
        if (requestedParams.start) {
            params = params.set('start', moment((requestedParams.start)).format('YYYY/MM/DD').toString());
        }
        if (requestedParams.end) {
            params = params.set('end',moment((requestedParams.end)).format('YYYY/MM/DD').toString());
        }
        if (requestedParams.verified) {
            params = params.set('verified', (requestedParams.verified));
        }
        if (requestedParams.demoStatus != null){
            params = params.append("demoStatus", requestedParams.demoStatus);
        }
        if(requestedParams.parentId != null){
            params = params.append("parentId", requestedParams.parentId);
        }
        params = params.append("pageSize", options.pageSize);
        params = params.append("pageNum", options.pageNum);
        if (options.sortField) (params = params.append("sortField", options.sortField));
        if (options.sortDirection) params = params.append("sortDirection", options.sortDirection);
        return params;
    }

    getAllAddresse(): Observable<any> {
        return this.http.get(`${this.baseUrl}/addresses/getAddresses`).pipe(map(response => {
            return response;
        }));
    }

    getPartnerNamesAndEmails(): Observable<PartnerDataDto[]> {
        return this.http.get<PartnerDataDto[]>(`${this.baseUrl}/partners/names-emails`);
    }

    register(formData: any) {

        return this.http.post<any>(`${this.baseUrl}/partners/register`, formData,
        {
        headers: {'enctype': 'multipart/form-data'}
        }
        ).pipe(

            map((response: any) => {
                return response;
            })
        );
    }
    gellAllPartnerIsAgency(isAgency:boolean):Observable<any>{
        return this.http.get<PartnerAgencyModel>(`${this.baseUrl}/partners/get-All-Partners-Is-Agency/${isAgency}`, );
    }
    verifyPartner(formData: any) {

        return this.http.post<any>(`${this.baseUrl}/partners/verifyPartner`, formData,
        {
        headers: {'enctype': 'multipart/form-data'}
        }
        ).pipe(

            map((response: any) => {
                return response;
            })
        );
    }

    public handleError(error: HttpErrorResponse) {
        return throwError(error);
    }


    updateLogo(payload: any, uuid: any) {

        const formData = new FormData();
        formData.append('logo', payload);
        return this.http.put<any>(`${this.baseUrl}/partners/updateLogo/${uuid}`, formData, {headers: {'enctype': 'multipart/form-data'}  }
        );
    }


    updateEvaluationCycle(payload: any) {
        return this.http.put<any>(`${this.baseUrl}/partners/updatePartnerEvaluation`, payload);
    }

    ModifyProfile(payload: any, personalPicture: any) {
        const formData = new FormData();
        formData.append('logo', personalPicture);
        formData.append('properties', JSON.stringify(payload));
        return this.http.post<any>(`${this.baseUrl}/partners/update`, formData, {
            headers: {'enctype': 'multipart/form-data'}} );
    }

    verify(uuid:string){
        return this.http.post<any>(`${this.baseUrl}/partners/${uuid}/verify`,{});
    }

    verifyEmail(key: any) {
        // @ts-ignore
        return this.http.post<any>(`${this.baseUrl}/partners/${key}/verify-email`).pipe(
            timeout(60000),
            catchError((e, c) => {
                return [];
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    getPartnerUser(uuid: string): Observable<any> {
        return this.http.get<PartnerModel>(`${this.baseUrl}/partners/${uuid}`, ).pipe(map(response => {
            return this.partner = response;
        }))
    }

    getPartnerByTenantId(): Observable<any> {
        return this.http.get<PartnerModel>(`${this.baseUrl}/partners/getPartnerByTanantId`);
    }

    getPartner(): Observable<any> {
        return this.http.get<PartnerModel>(`${this.baseUrl}/partners/my-partner`).pipe(map(response => {
            return this.patternResult = response;
        }));
    }
    getPartnerByuuid(uuid): Observable<any> {
        return this.http.get<PartnerModel>(`${this.baseUrl}/partners/${uuid}`);
    }
    getCompanyName(uuid): Observable<any>{
        return this.http.get<any>(`${this.baseUrl}/partners/geCompanyName/${uuid}`).pipe(map(response => {
            return this.patternResult = response;
        }));
    }
    getLoad(path): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/partners/loadFile/${path}`, {
            headers: new HttpHeaders().append(AUTHORISATION_BEARER_HEADER.name, localStorage.getItem('token'))
        }).pipe(map(response => {
            return this.partnerImage = response;
        }));
    }

    getAllActivePartner(option?: any): Observable<any> {
        let queryParams = new HttpParams();
        if(option){
          if (option.demoStatus !== undefined) queryParams = queryParams.append("demoStatus", option.demoStatus)
          return this.http.get<PartnerModel>(`${this.baseUrl}/partners/PartenaireVerifier`, {params: queryParams});
        }else{
          return this.http.get<PartnerModel>(`${this.baseUrl}/partners/PartenaireVerifier`);
        }
    }


    getPartnerAddress(): Observable<any> {
        return this.http.get<PartnerAddressModel>(`${this.baseUrl}/addresses`).pipe(map(response => {
            return this.partnerAddressResult = response;
        }));
    }

    getPartnerRib(): Observable<any> {
        return this.http.get<PartnerRibModel>(`${this.baseUrl}/bank-accounts`).pipe(map(response => {
            return this.partnerRibResult = response;
        }));
    }

    affectModuletoPartner(uuid: Array<string>): Observable<any> {
        return this.http.post<Account>(`${this.baseUrl}/partners/${this.uuid.access_id}/modules/acquire`, uuid).pipe(
            timeout(GLOBAL_AJAX_TIMEOUT),
            map((response: any) => response)
        );
    }

    getadresse(uuid: string): Observable<any> {
        return this.http.get<PartnerAddressModel>(`${this.baseUrl}/addresses/${uuid}`,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            }
        ).pipe(map(response => {
            return this.partnerAddressResult = response;
        }));
    }

    createPreference(payload: any) {
        return this.http.post(`${this.baseUrl}/preferences`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                //this.toastr.error(" Error!  ");
                return [];
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    updatePreference(payload: any) {
        return this.http.put(`${this.baseUrl}/preferences`, payload).pipe(
            timeout(60000),
            catchError((e,c) => {
                return [];
            }),
            map( (response : any) => {
                return response;
            })
        );
    }

    getCurrentPreferences(): Observable<any> {
        return this.http.get<PreferenceModel>(`${this.baseUrl}/preferences/getCurrentPreferences`,

        ).pipe(map(response => {
            return response;
        }));
    }

    getAllPreference(): Observable<any> {
        return this.http.get<PreferenceModel>(`${this.baseUrl}/preferences/getAllBytenantId`).pipe(map(response => {
            return  response;
        }));
    }

    updateParttnersansImage(currentPartner: any): Observable<any> {
        return this.http.put(`${this.baseUrl}/partners/`,currentPartner);
    }
    getAllPartnerActiveBypage(options : ViewOptions, params?: any):Observable<any> {

        return this.http.get(`${this.baseUrl}/partners/getAllPartnersByPage/`, {params: this.setPartnerParams(options ,params)});
    }
    getAllPartners(){
        return this.http.get<PartnerModel[]>(`${this.baseUrl}/partners/getAllPartners`).pipe(map(response => {
            return  response;
        }));
    }

    getPhoneNumbers(): Observable<string[]> {
        return this.http.get<string[]>(`${this.baseUrl}/partners/phone-numbers`);
    }

    filterPartner(params: any): Observable<any>{

        return this.http.get(`${this.baseUrl}/partners/filterPartners`, {params: this.setParams(params)});
    }

    private setParams(requestedParams: any) {
        let params = new HttpParams();
        if (requestedParams.name !== null && requestedParams.name !== undefined) {
            params = params.set('name', requestedParams.name.trim());
        }
        if (requestedParams.email !== null && requestedParams.email !== undefined) {
            params = params.set('email', requestedParams.email.trim());
        }
        if (requestedParams.email !== null && requestedParams.email !== undefined) {
            params = params.set('email', requestedParams.email);
        }
        if (requestedParams.start !== null && requestedParams.start !== undefined) {
            params = params.set('start', (requestedParams.start));
        }
        if (requestedParams.end !== null && requestedParams.end !== undefined) {
            params = params.set('end', (requestedParams.end));
        }
        if (requestedParams.verified !== null && requestedParams.verified !== undefined) {
            params = params.set('verified', (requestedParams.verified));
        }
        if (requestedParams.pageIndex !== null && requestedParams.pageIndex !== undefined) {
            params = params.set('pageIndex', (requestedParams.pageIndex));
        }
        if (requestedParams.pageSize !== null && requestedParams.pageSize !== undefined) {
            params = params.set('pageSize', (requestedParams.pageSize));
        }
        if(requestedParams.isAgency !== null && requestedParams.isAgency !== undefined){
            params = params.set('isAgency', (requestedParams.isAgency));
        }
        if (requestedParams.sortField)
            params = params.append("sortField", requestedParams.sortField);
        if (requestedParams.sortDirection)
            params = params.append("sortDirection", requestedParams.sortDirection);
        if (requestedParams.demoStatus)
            params = params.append("demoStatus", requestedParams.demoStatus);
        return params;
    }




    createAgency(agency: AgencyModel): Observable<AgencyModel> {
        return this.http.post<AgencyModel>(`${this.baseUrl}/partners/createAgency`, agency);
    }

    getAllAgences(){
        return this.http.get<AgencyModel[]>(`${this.baseUrl}/partners/getAllPartners`).pipe(map(response => {
            return  response;
        }));
    }

    getPartnerTypeEnterprise(parentId:number){
        let params = new HttpParams();
        if(parentId != null){
            params = params.append("parentId",parentId);
        }
        return this.http.get<PartnerDataDto[]>(`${this.baseUrl}/partners/partners-entreprises-by-parent`,{params:params});
    }

    deletePartner(uuid: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/partners/${uuid}`);
    }



}
