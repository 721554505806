import {SafeResourceUrl} from "@angular/platform-browser";

export class User
{
    uuid: string;
    name: string;
    email: string;
    avatar: string | SafeResourceUrl;
    status?: string;
    sessionUuid?: string

    constructor(){}
}
