import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommunRegxService {
    regwebUrl =  /(https?:\/\/)?([\w\d]+\.)?[\w\d]+\.\w+\/?.+/
    regRegister = /^([a-zA-Z0-9]){9}$/
    regMatFiscal= /\d{13}/
    regPassword = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[-_!@#&()\[\]{}:;',?/*~$^+=<>]).{8,32}$/
    regmatfiscal =   /^([a-zA-Z0-9]){13}$/
    regMatfiscalContact =/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9\-\/\\]+$/
    regMatriculefiscale = /^\d{7}\/[A-Z]\/?[A-Z]?\/?[A-Z]?\/?\d*$/
    //regmatfiscal =   /^(?:[a-zA-Z!@#$%^&*()0-9-]*-?){13}$/
    reg8Digit = /^[^18][0-9]{7}$/;
    regCIN = /^[10][0-9]{7}$/;
    ribPattern = /^[0-9]{22}$/;
    numSecuritySoc = /^[0-9]{10}$/;
    cnssNumberRegex = /^(\w){10}$/;
    regnom = /^[^\s]{3,}$/
    regemail= /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
    regTel = /^([2-579])\d{7}$/
    regTVA= /^(?=.*[a-zA-Z])(?=.*\d).{13}$/
    regFax = /^0?7\d{7}$/
    regcapital = /[0-9]/
    validationRegExNineNumb = "[0-9]*$";
    validationRegExBaseSalary = "^-?[0-9]\\d*(\\.\\d{1,5})?$";
    regsocialSecurityNumber = /^0?\d{0,10}$/
    regnumCompte = /^0?\d{0,20}$/;

    regAccountNumber= /^0*\d{20}$/
    regIBAN= /^0*\d{24}$/
    regDigital =/^\d+$/
    reg8DigitCin = /^[0-1][0-9]{7}$/;
    regDigitCin = /^[01]/;
    regDigitalPessenger = /^[1-9]\d*$/;
    regAdvisedSalePrice= /^\d+(?:\.\d{1,3})?$/;
    cinPattern = /^[0-9]{8}$/;

    patternNumber = /[0-9\+\-\ ]/;

    constructor() { }
}
