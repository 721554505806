const API = 'api'
const API_DOTNET = "api-dotnet"
const API_PAYS = "https://finance.wind-erp.com/api"
const API_KEY = 'f2c9b8a7-4d3e-44e9-bf96-1a77aeb1a255'
export const environment = {
    production : true ,
    build: 'Production',
    // PROD APIS
    authUrl : `${API}/wind-auth`,
    partnersUrl : `${API}/wind-partner`,
    sirhUrl: `${API}/wind-sirh`,
    crmUrl: `${API}/wind-crm`,
    buillingUrl : `${API}/wind-billing`,
    fundUrl : `${API}/wind-fund`,
    leavesUrl : `${API}/wind-leaves`,
    payrollUrl : `${API}/wind-payroll`,
    moduleUrl: `${API}/wind-module`,
    dmsUrl : `${API}/wind-dms`,
    docGenUrl : `${API}/wind-docgen`,
    recrutementUrl : `${API}/wind-recrutement`,
    stockUrl : `${API}/wind-stock`,
    majUrl: `${API}/wind-maj`,
    notificationUrl:`${API}/wind-notification`,
    urlSocketNotification:`${API}/wind-urlSocketNotification`,
    ticketUrl:`${API}/wind-ticket-management`,
    commercialUrl:`${API}/wind-commercial`,
    contentManagementUrl: `${API}/wind-content-management`,
    WS_BASE_URL: `ws/ws-notification`,
    API_DOTNET:API_DOTNET,
    apiKey:API_KEY,
    iotUrl: `${API}/wind-iot`,
    alfrescoShareUrl:`/share/proxy/alfresco-noauth/api/internal/shared/node/`

}
export const environmentB2C = {
    production : true,
    build: 'Production',
    apiUrlB2C:`${API_DOTNET}`,
    apiPays: `${API_PAYS}`,
    apiKey: `${API_KEY}`


}
