import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, forkJoin, map, Observable, of, ReplaySubject, switchMap, tap} from 'rxjs';
import {User} from 'app/shared/models/auth/user/user.types';
import {PartnerService} from "../../../services/backend/partner/partner.service";
import {SirhService} from "../../../services/backend/collaborator/sirh.service";
import {RecrutementService} from 'app/shared/services/backend/recrutement/recrutement.service';
import {TokenClaims} from "../token-claims";
import {environment} from "../../../../../environments/environment";
import { ViewOptions } from 'app/components/admin/view-options';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonService } from 'app/shared/services/frontend/commun/common.service';
import { DmsService } from 'app/shared/services/backend/Dms/dms.service';
import {AuthService} from "../../../../core/auth/auth.service";
@Injectable({
    providedIn: 'root'
})

export class UserService {
    public _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    private _userSetting: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _userSystem: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _partnerRawData: ReplaySubject<User> = new ReplaySubject<User>(1);
    baseUrl = environment.authUrl
    partnerUrl = environment.partnersUrl
    dmsUrl=environment.dmsUrl
    sirhUrl = environment.sirhUrl;
    public avatar = new BehaviorSubject<string | null>('');
    public email = new BehaviorSubject<string | null>('');
    public name = new BehaviorSubject<string | null>('');
    public avatarBlob = new BehaviorSubject<SafeResourceUrl | null>('');
    avatar$ = this.avatar.asObservable();
    email$ = this.email.asObservable();
    name$ = this.name.asObservable();
    photoProfile: string;
    avatarBlob$ = this.avatarBlob.asObservable();
    image !: SafeResourceUrl

    /**
     * Constructor
     */
    constructor(
                private partnerService: PartnerService,
                private sirhService: SirhService,
                private recrutementService: RecrutementService,
                private http: HttpClient,
                private commonService: CommonService,
                private dmsService: DmsService,
                private sanitizer : DomSanitizer,
                private authService:AuthService

) {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: TokenClaims) {
        let uuid = value.uuid
        let role = value.role
        // Store USER
        if (role === 'ROLE_ADMIN') {
            this.storePartnerData(uuid)
        }
        if (role === 'ROLE_USER') {
            this.storeCollaboratorData(uuid)
        }
        if (role === 'ROLE_CANDIDATE') {
            this.storeCandidateData(uuid)
        }
        if (role === 'ROLE_SYSTEM') {
            this.storeSystemData(uuid)
        }
    }
    getPartnerPhoto(logo : string) {
        return `${this.dmsUrl}${logo}`

    }


    storePartnerData(uuid: any) {

        this.partnerService.getPartnerUser(uuid).subscribe({
            next: (res) => {
                this.photoProfile = res?.logo && res.logo !== 'null'
                    ? (environment.dmsUrl +"/alfresco/nodes/"+res.logo+"/content")
                    : './assets/imgs/Logowind.svg';
                this.sendavatar(this.photoProfile);
                this._partnerRawData.next(res);
                localStorage.setItem("partner_id",res.id)
                this._user.next(
                    {
                        uuid: res?.uuid,
                        name: res?.company_name,
                        email: res?.email,
                        avatar: this.getPartnerPhoto("/alfresco/nodes/"+res.logo+"/content"),
                        status: 'online',
                        sessionUuid: localStorage.getItem('sessionUuid')
                    });
            }
        })
    }

    storeCollaboratorData(uuid: any) {

        this.sirhService.getCollaboratorByUuid(uuid).subscribe({
            next: (res) => {
                let avatarUrl: string;
                if (res.personalPicture == null) {
                  avatarUrl = './assets/imgs/Logowind.svg';
                } else {
                  avatarUrl = `${this.sirhUrl}/collaborators/loadFile/` + res.personalPicture;
                }
                this._user.next({
                    uuid: res.uuid,
                    name: res.firstname + " " + res.lastname,
                    email: res.proEmail,
                    avatar: avatarUrl,
                    status: 'online',
                    sessionUuid: localStorage.getItem('sessionUuid')
                  });

            }
        })
    }

    storeCandidateData(uuid: any) {
        this.recrutementService.getCandidateByUuid(uuid).subscribe({
            next: (res) => {
                let picture
                if (res.personalPicture) picture = `${environment.partnersUrl}/partners/loadFile/${res.personalPicture}`
                else picture = null
                this._user.next(
                    {
                        uuid: res.uuid,
                        name: res.firstName + " " + res.lastName,
                        email: res.email,
                        avatar: picture,
                        status: 'online',
                        sessionUuid: localStorage.getItem('sessionUuid')
                    });
            }
        })
    }

    getUserByEmail(email: string) {
        const params = new HttpParams().set('email', email);
        return this.http.get(`${this.baseUrl}/users/getByEmail`, { params });
    }

    getSystemUser() {
        this.getUserByEmail("system@system.wind").subscribe({
            next:(response)=>{
                this.systemUser$ = response;
            }}
        )
    }
    set systemUser$(body: any) {
        this._userSystem.next(body)
    }

    get systemUser$(): Observable<any> {
        return this._userSystem.asObservable();
    }

    storeSystemData(uuid: any) {
        this.getUserByUUID(uuid).subscribe({
            next: (res) => {
                let picture
                if (res.personalPicture) picture = `${environment.partnersUrl}/partners/loadFile/${res.personalPicture}`
                else picture = null
                this._user.next(
                    {
                        uuid: res.uuid,
                        name: "System",
                        email: res.email,
                        avatar: picture,
                        status: 'online',
                        sessionUuid: localStorage.getItem('sessionUuid')
                    });
            }
        })
    }

    // private getSettingPartner() {
    //     this.moduleServie.getConfig().subscribe({
    //         next:(response)=>{
    //             this.userSetting$ = response;
    //         }}
    //     )
    // }

    addRole(payload: any) {
        return this.http.post<any>(`${this.baseUrl}/roles`, payload).pipe(
            map((response) => {
                return response;
            })
        )
    }
    addRolewithPermissions(payload: any) {
        return this.http.post<any>(`${this.baseUrl}/roles/addnewRoleWithSelectedOptions`, payload).pipe(
            map((response) => {
                return response;
            })
        )
    }

    getAllRoles(uuid : any) {
        return this.http.get<any>(`${this.baseUrl}/roles/${uuid}`);
    }
    getallRoles() {
        return this.http.get<any>(`${this.baseUrl}/roles/ALL`);
    }

    getAllUsers(uuid : any) {
        return this.http.get<any>(`${this.baseUrl}/users/${uuid}`);
    }

    getAllUsersPerPage(options: ViewOptions, partnerUuid): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("pageSize", options.pageSize);
        queryParams = queryParams.append("pageNum", options.pageNum);
        queryParams = queryParams.append("partnerUuid", partnerUuid);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this.http.get(`${this.baseUrl}/users/getUsersPerPage`, { params: queryParams });

    }

    affectUser(payload: any): Observable<any> {
        return this.http.put(`${this.baseUrl}/users/affect`, payload);
    }

    getConnectedUserRole(uuid : any) {
        return this.http.get<any>(`${this.baseUrl}/roles/user/${uuid}`);
    }

    saveSelectedModulesForRole(payload : any) {
        return this.http.post<any>(`${this.baseUrl}/acl/saveSelectedOptions`, payload);
    }

    updateSelectedModulesForRole(payload : any) {
        return this.http.post<any>(`${this.baseUrl}/acl/updateSelectedOptions`, payload).pipe(
            map((response) => {
              return response;
            })
          );
    }

    getUserByUUID(uuid: any) {
        return this.http.get<any>(`${this.baseUrl}/users/${uuid}`);
    }

    saveModuleAcls(payload) : Observable<any> {

        return this.http.post<any>(`${this.baseUrl}/acl/addModuleAclsForUser`, payload).pipe(
            map((response) => {
              return response;
            })
          );
    }

    getMenuAclByMenu(name : string) : Observable<any> {

        return this.http.get<any>(`${this.baseUrl}/acl/getMenuAclByMenu/${name}`).pipe(map(response => {
            return  response;
        }));
    }

    getAllPartnerModuleAcls(uuid : any) {
        uuid = uuid.replace(/"/g, '');
        return this.http.get<any>(`${this.baseUrl}/acl/getAllModuleAclsByUser/${uuid}`);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    getavatar(): Observable<any> {
        return this.avatar.asObservable();
    }

    sendavatar(avatar: string) {
        this.avatar.next(avatar);
    }

    getavatarBlob() {
        return this.avatarBlob.asObservable();;
    }
    sendavatarBlob(avatar: SafeResourceUrl) {
        this.avatarBlob.next(avatar);
    }

    sendEmail(email: string) {
        this.email.next(email);
    }
    sendName(name: string) {
        this.name.next(name);
    }
    clearAvatarCache(): void {
        this.avatar.next(null);
    }
    set userSetting$(body: any) {
        this._userSetting.next(body)
    }

getName(): Observable<string> {
        return this.name.asObservable();
      }

    getEmail(): Observable<string> {
        return this.email.asObservable();
      }

    get userSetting$(): Observable<any> {
        return this._userSetting.asObservable();
    }

    get partnerRawData$(): Observable<any> {
        return this._partnerRawData.asObservable();
    }

    destroy() {
        this._user = new ReplaySubject<User>(1);

    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    /**
     * Update the user
     *
     * @param user
     */
    updatePassword(payload, uuid){
        return this.http.put<boolean>(`${this.baseUrl}/users/update-password/${uuid}`,payload);
    }


    getStoredIsAgency(): any {
        return this.user;
    }

    setStoredUser(userModel: any) {
        this.user = userModel;
    }

    fetchUserAndCheckAgency(uuid: string): Observable<boolean> {
        return new Observable(observer => {
            this.getUserByUUID(uuid).subscribe(response => {
                if (response) {
                    this.setStoredUser(response);
                    const isAgency = response?.tenant_id == 1000 || response?.tenant_id == 1004 ? false :true;
                    observer.next(isAgency);
                }
                observer.complete();
            });
        });
    }
}
