import { Injectable } from '@angular/core';
import { ModuleService } from '../../backend/module/module.service';
import { traductionLanguage } from 'app/shared/enums/language/traduction';

@Injectable({
  providedIn: 'root'
})
export class BankService {
    origin : string ;
    tunisianBanks: { [key: string]: string } = {
        'STB': 'Société Tunisienne de Banque « STB »',
      'BNA': 'Banque Nationale Agricole « BNA »',
      'BH':'Banque de l’Habitat « BH »',
      'BFPME':'Banque de Financement des Petites et Moyennes entreprises « BFPME »',
      'BTS': 'Banque Tunisienne de Solidarité « BTS »',
      'BTE': 'Banque de Tunisie et des Emirats « BTE »',
      'BTL' : 'Banque Tuniso-Libyenne « BTL »',
      'TSB': 'Tunisian Saudi Bank « TSB »',
      'ABB': 'Al Baraka Bank',
      'AWIB':'Al Wifak International Bank',
      'Amen Bank':  'Amen Bank',
      'Attijari Bank':'Attijari Bank',
      'ATB':  'Arab Tunisian Bank « ATB »',
      'ABC' : 'Arab Banking Corporation « ABC »',
      'BIAT' : 'Banque Internationale Arabe de Tunisie «  BIAT »',
      'BT':  'Banque de Tunisie « BT »',
      'BTK':  'Banque Tuniso Koweitienne « BTK »',
      'BFT' : 'Banque Franco Tunisienne « BFT »',
      'Citi Bank':  'Citi Bank',
      'QNB-Tunis':  'Qatar National Bank- Tunis « QNB-Tunis »',
      'UBCI':  'Union Bancaire de Commerce et d’Industrie «  UBCI »',
      'UIB':  'Union Internationale de Banque «  UIB »',
      'BZ':'Banque Zitouna'
    };

    frenchBanks: { [key: string]: string } = {
        'BNP': 'Banque Nationale de Paris « BNP »',
        'SG': 'Société Générale',
        'CA': 'Crédit Agricole',
        'BP': 'Banque Populaire',
        'CM': 'Crédit Mutuel',
        'La Banque Postale': 'La Banque Postale',
        'HSBC': 'HSBC France',
        'LCL': 'Le Crédit Lyonnais « LCL »',
        'CIC': 'Crédit Industriel et Commercial « CIC »'
    };
    arabBanks: { [key: string]: string } = {
      'STB': 'البنك التونسي « STB »',
      'BNA': 'البنك الوطني الفلاحي « BNA »',
      'BH': 'بنك الإسكان « BH »',
      'BFPME': 'بنك تمويل المؤسسات الصغرى والمتوسطة « BFPME »',
      'BTS': 'البنك التونسي للتضامن « BTS »',
      'BTE': 'بنك تونس والإمارات « BTE »',
      'BTL': 'البنك التونسي الليبي « BTL »',
      'TSB': 'البنك التونسي السعودي « TSB »',
      'ABB': 'بنك البركة',
      'AWIB': 'البنك الويفاك الدولي',
      'Amen Bank': 'بنك الأمان',
      'Attijari Bank': 'بنك التجاري',
      'ATB': 'البنك العربي التونسي « ATB »',
      'ABC': 'المؤسسة العربية المصرفية « ABC »',
      'BIAT': 'البنك الدولي العربي لتونس « BIAT »',
      'BT': 'بنك تونس « BT »',
      'BTK': 'البنك التونسي الكويتي « BTK »',
      'BFT': 'البنك الفرنسي التونسي « BFT »',
      'Citi Bank': 'سيتي بنك',
      'QNB-Tunis': 'البنك الوطني القطري - تونس « QNB-Tunis »',
      'UBCI': 'الاتحاد البنكي للتجارة والصناعة « UBCI »',
      'UIB': 'الاتحاد الدولي للبنوك « UIB »',
      'BZ': 'بنك الزيتونة'
  };

    tunisianBanksInEnglish: { [key: string]: string } = {
        'STB': 'Tunisian Banking Corporation « STB »',
        'BNA': 'National Agricultural Bank « BNA »',
        'BH': 'Housing Bank « BH »',
        'BFPME': 'Bank for Financing Small and Medium Enterprises « BFPME »',
        'BTS': 'Tunisian Solidarity Bank « BTS »',
        'BTE': 'Bank of Tunisia and the Emirates « BTE »',
        'BTL': 'Tunisian Libyan Bank « BTL »',
        'TSB': 'Tunisian Saudi Bank « TSB »',
        'ABB': 'Al Baraka Bank',
        'AWIB': 'Al Wifak International Bank',
        'Amen Bank': 'Amen Bank',
        'Attijari Bank': 'Attijari Bank',
        'ATB': 'Arab Tunisian Bank « ATB »',
        'ABC': 'Arab Banking Corporation « ABC »',
        'BIAT': 'International Arab Bank of Tunisia « BIAT »',
        'BT': 'Bank of Tunisia "BT"',
        'BTK': 'Tunisian-Kuwaiti Bank « BTK »',
        'BFT': 'Franco-Tunisian Bank « BFT »',
        'Citi Bank': 'Citi Bank',
        'QNB-Tunis': 'Qatar National Bank-Tunis « QNB-Tunis »',
        'UBCI': 'Union Bank of Commerce and Industry « UBCI »',
        'UIB': 'International Union Bank « UIB »',
        'BZ': 'Zitouna Bank'
    };

    getBanksByOrigin(origin): { [key: string]: string } {
        if (origin === 'TUNISIAN') {
            return this.tunisianBanks;
        } else if (origin === 'EUROPEAN') {
            return this.frenchBanks;
        } else {
            return this.tunisianBanks;
        }
    }

    getBanksByOriginAndCurrentLanguage(origin, currentLanguage): { [key: string]: string } {
        if (origin === 'TUNISIAN') {
            switch (currentLanguage) {
                case traductionLanguage.FR:
                    return this.tunisianBanks;
                case traductionLanguage.AR:
                    return this.arabBanks;
                case traductionLanguage.EN:
                    return this.tunisianBanksInEnglish;
            }
        } else if (origin === 'EUROPEAN') {
            return this.frenchBanks;
        } else {
            return this.tunisianBanks;
        }
    }
}

