import {HttpBackend, HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CandidateModel } from 'app/shared/models/recrutement/candidate/candidate-model';
import { BehaviorSubject, Observable, catchError, map, timeout } from "rxjs";
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecrutementService {

  public selectedIndex: number = 0;
  baseUrl = environment.recrutementUrl
  private httpClient: HttpClient;

  private OffreUuid = new BehaviorSubject<string | null>('');
  offreUuid$ = this.OffreUuid.asObservable();

  constructor(private http: HttpClient, handler: HttpBackend) {
      this.httpClient = new HttpClient(handler);
  }

  sendOffreUuid(offreUuid: string) {
    this.OffreUuid.next(offreUuid);
  }

  getOffreUuid(): Observable<any> {
    return this.OffreUuid.asObservable();
  }

  ///Candidat//
  getAllCandidats() {
    return this.http.get<any>(this.baseUrl + `/candidate/getAll`)
  }
  getCandidatesByOffre(uuid: any): Observable<any> {
    return this.http.get<Array<CandidateModel>>(`${this.baseUrl}/candidate/getCandidatesByOffre/${uuid}`).pipe(map(response => {
      return response;
    }));
  }
  ///Entretien
  getAllEntretiens() {
    return this.http.get<any>(this.baseUrl + `/interview/getAllInterviews`)
  }
  //demandeRecrutement
  getAlldemandeRecrutement() {
    return this.http.get<any>(this.baseUrl + `/demandeRecrutement`)
  }
  getAllOffers(defaultIndexAndSize): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/offreRecrutement/getByTenantId/?page=${defaultIndexAndSize.pageIndex + 1}&size=${defaultIndexAndSize.pageSize}`)
  }

    getAllOffersbyRequester(uuid,defaultIndexAndSize): Observable<any> {
        return this.http.get<any>(this.baseUrl + `/offreRecrutement/getofferbyRequester/${uuid}/?page=${defaultIndexAndSize.pageIndex + 1}&size=${defaultIndexAndSize.pageSize}`)
    }

  findCondidat(rawValue: any) {
    return this.http.get<any>(this.baseUrl + `/candidate/findCondidatBySourcingAndStatut/${rawValue.sourcingName}/${rawValue.statut}`)

  }
  findInterview(rawValue) {
    return this.http.get<any>(this.baseUrl + `/interview/findInterviews/${rawValue.ref}/${rawValue.statut}/${rawValue.sourcingName}`)
  }

  findDemande(rawValue) {
    return this.http.get<any>(this.baseUrl + `/demandeRecrutement/findBySourcingAnd/${rawValue.sourcingName}/${rawValue.statut}`)
  }
  findOffers(ref: any, statut: any) {
    return this.http.get<any>(this.baseUrl + `/offreRecrutement/getByrefStatutDemande/`, {
      params: this.setParamsOffres(ref, statut)
  })
  }
  private setParamsOffres(ref: any, statut: any): HttpParams {
    let params = new HttpParams();
    if (ref !== '') {
        params = params.set('ref', ref);
    }
    if (statut !== '') {
        params = params.set('statut', statut);
    }

    return params;
}
  getOfferByUuid(uuid: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + `/offreRecrutement/getByUuid/${uuid}`)
  }
  UpdateOffreRecrutement(payload: any) {
    return this.http.patch(`${this.baseUrl}/offreRecrutement`, payload,).pipe(
      timeout(60000),
      catchError((e, c) => {
        return []; // only for catch error to work, it requires a return of array, object..
      }),
      map((response: any) => {
        return response;
      })
    );
  }


  //get all by uuid
  getAlldemandeRecrutementByuuid(uuid,role,defaultIndexAndSize) {

      if(role=="ROLE_ADMIN"){
          return this.http.get<any>(this.baseUrl + `/demandeRecrutement/?page=${defaultIndexAndSize.pageIndex + 1}&size=${defaultIndexAndSize.pageSize}`);
      }
      else  if(role=="ROLE_USER"){
          return this.http.get<any>(this.baseUrl +  `/demandeRecrutement/getAllDemandes/${uuid}/?page=${defaultIndexAndSize.pageIndex + 1}&size=${defaultIndexAndSize.pageSize}`);
      }
      }

  /**candidats valider sur dossier */
  validerCondidates(condidatesUuid: any) {
    return this.http.post(`${this.baseUrl}/candidate/acceptAll`, condidatesUuid).pipe(
      timeout(60000),
      catchError((e, c) => {
        return []; // only for catch error to work, it requires a return of array, object..
      }),
      map((response: any) => {
        return response;
      })
    );
  }

  /**candidats refuser */

  refuserCondidates(condidatesUuid: any) {
    return this.http.post(`${this.baseUrl}/candidate/refuseAll`, condidatesUuid,).pipe(
      timeout(60000),
      catchError((e, c) => {
        return []; // only for catch error to work, it requires a return of array, object..
      }),
      map((response: any) => {
        return response;
      })
    );
  }
  getCycleValidationByTenantId() {
    return this.http.get(`${this.baseUrl}/windcyclevalidation/byTenantId`);
  }

  getAllCycleValidationConfigByTenantId() {
    return this.http.get<any>(`${this.baseUrl}/cyclevalidation/getAllByTenantId`);
  }

  getRHCycleValidationValidators() {
    return this.http.get<any>(`${this.baseUrl}/windcyclevalidation/getRHCycleValidationValidators`);
  }

  createCycleValidationConfig(payload: any) {
    return this.http.post(`${this.baseUrl}/cyclevalidation`, payload)
  }

  updateCycleValidationConfig(payload: any) {
    return this.http.put(`${this.baseUrl}/cyclevalidation`, payload)
  }

  updateCycleValidation(payload: any) {
    return this.http.put(`${this.baseUrl}/windcyclevalidation`, payload)
  }

  deleteValidationCycleConfig(uuid: any) {
    return this.http.delete(`${this.baseUrl}/cyclevalidation/${uuid}`)
  }

  getSkils() {
    return this.http.get(`${this.baseUrl}/skils`)
  }

  getcompetences() {
      return this.httpClient.get(`${this.baseUrl}/skils/getall`)
  }
  addDemandeforUsers(demandeRecrutement) {
    return this.http.post(`${this.baseUrl}/demandeRecrutement/createforcollab`, demandeRecrutement)
  }
  addDemandeRecrutement(demandeRecrutement) {
    return this.http.post(`${this.baseUrl}/demandeRecrutement/create`, demandeRecrutement)
  }

  getInterview(type, id): any {
    return this.http.get(`${this.baseUrl}/interview/getInterview/${type}/${id}`);
  }
  createInterview(payload: any): any {
    return this.http.post(`${this.baseUrl}/interview`, payload)
  }
  updateInterview(payload: any) {
    return this.http.put(`${this.baseUrl}/interview/update`, payload)
  }
  getDemandeByCondidat(UuidCondidat: any) {
    return this.http.get<any>(`${this.baseUrl}/candidate/getDemandeByCondidate/${UuidCondidat}`);
  }
  getPDF(id:number):any{
    return this.http.get<any>(`${this.baseUrl}/candidatecv/${id}`);
  }
  isWordExist(UuidCondidat:any,word:any){
    return this.http.get<any>(`${this.baseUrl}/candidatecv/findWord/${word}/${UuidCondidat}`);
  }
  validerCondidate(uuidCondidiate: any) {
    return this.http.post(`${this.baseUrl}/candidate/${uuidCondidiate}/accept`, {});
  }
  getCandidateByUuid(uuid: any): any {
    return this.http.get(`${this.baseUrl}/candidate/getCandidate/${uuid}`)
  }
  getAllInterviewsByCandidate(id: number): any {
    return this.http.get(`${this.baseUrl}/interview/getInterviewsByCandidate/${id}`);
  }
  previewDoc(documentIdentifier: any): Observable<any> {
    return this.http.get(`http://localhost:9900/documents/v2/${documentIdentifier}`,{ responseType: "arraybuffer" });
  }
  ModifyProfile(payload: any, personalPicture: any) {
    const formData = new FormData();
    formData.append('image', personalPicture);
    formData.append('properties', JSON.stringify(payload));

    return this.http.put<any>(`${this.baseUrl}/candidate/updateInfo`, formData, {
        headers: {'enctype': 'multipart/form-data'}} );
  }

  receiveMailForOffre(tenant_id: any, uuidCollaborator :any):Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/receive-mail`,  {params: this.setParamsMailForOffre(tenant_id, uuidCollaborator)
    }).pipe(map(response => {
      return response;
    }));
  }

  setParamsMailForOffre(tenant_id: any,uuidCollaborator:any): HttpParams | { [param: string]: string | string[]; } {
    let params = new HttpParams();
    params = params.set('tenant_id',tenant_id)
    params = params.set('uuidCollaborator',uuidCollaborator)
    return params;
  }

  receiveMail(subject:any, dateInit:any, dateFinal:any,tenant_id: any, uuidPartner :any):Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/receive-mail`,  {params: this.setParamsMail(subject, dateInit, dateFinal,tenant_id, uuidPartner)
    }).pipe(map(response => {
      return response;
    }));
  }
  setParamsMail(subject: any, dateInit: any, dateFinal: any,tenant_id: any,uuidPartner:any): HttpParams | { [param: string]: string | string[]; } {
    let params = new HttpParams();
    if (subject !== '') {
      params = params.set('subject', subject);
    }
    if (dateInit !== '') {
      params = params.set('dateInit', dateInit);
    }
    if (dateFinal !== '') {
      params = params.set('dateFinal', dateFinal);
    }
    params = params.set('tenant_id',tenant_id)
    params = params.set('uuidPartner',uuidPartner)
    return params;

  }
  getCandidateById(id: number): any{
    return this.http.get(`${this.baseUrl}/candidate/getCandidateById/${id}`);

  }

  createInterviewEvaluation(payload: any) {
    return this.http.post(`${this.baseUrl}/interviewEval`, payload).pipe(
      timeout(60000),
      map((response: any) => {
        return response;
      })
    );
  }

  getEvaluationByEntretiens(uuid:any) {
    return this.http.get<any>(this.baseUrl + `/interviewEval/getByInterview/${uuid}`)
  }

    getAllValidatorPerCycleValidation(uuidCycleValidation:string) {
        return this.http.get<any>(this.baseUrl + `/demandeCycle/getValidatorsPerCycle/${uuidCycleValidation}`)
    }
}
