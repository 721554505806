import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, EventEmitter  } from "@angular/core";
import { ProductModel, ServiceModelDto } from "app/shared/models/stock/product ";
import { ViewOptions } from "app/components/admin/view-options";
import { Observable, Subject, catchError, map, throwError, timeout,of,switchMap, BehaviorSubject } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { IPage } from "../../../../components/admin/ipage";
import { StockHistoryModel } from "../../../models/stock/stock-history-model";
import {NgToastService} from "ng-angular-popup";
import { Tax } from "app/components/common-components/tax";
import moment from "moment";
import { ImgsData } from "app/layout/common/interfaces/upload-interfaces";
import { IntervalDateForm } from "app/shared/models/builling/interval-date-form";
import { QuantiteVendueModelDto } from "app/shared/models/stock/quantite-vendue-model";
import { PackageElementDto } from "app/shared/models/stock/PackageElementDto";
import {TypeProductEnum} from "../../../enums/stock/type-product-enum";
import {ServiceTypeEnum} from "../../../enums/stock/service-type-enum";
import { ReservedDaysProductModel } from "app/shared/models/stock/reserved-days-product";
import { VehicleMaintenanceModel } from "app/shared/models/stock/VehicleMaintenanceModel";
import { ReparationModel } from "app/shared/models/stock/reparation-model";
import { PaymentReparationModel } from "app/shared/models/builling/PaymentReparationModel";
import {CompteResult} from "../../../models/caisse/compte-result";
import {Assurance} from "../../../models/stock/assurance";
import {ElementTypeEnum} from "../../../enums/stock/ElementTypeEnum";


@Injectable({
    providedIn: 'root'
})
export class StockService {

  uploadExcelFileNewProduct(selectedFile: any , uuidPartner?:string) {
    const formData: FormData = new FormData();
        if (uuidPartner) {
            formData.append('uuidPartner', uuidPartner);
        }
        formData.append('file', selectedFile, selectedFile.name);
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        return this.http.post(`${this.baseUrl}/products/importForPartner`, formData, { headers: headers, responseType: 'text' })
  }

  importPurshaseInfos(selectedFile: any, pdfGenerationType):Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', selectedFile, selectedFile.name);
        formData.append('pdfGenerationType', pdfGenerationType);
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        return this.http.post(`${this.baseUrl}/code/importPurshaseInfos `, formData, { headers: headers, responseType: 'blob' })
  }

  updateProductPrices(productsToUpdate : any) {
    return this.http.put<any>(`${this.baseUrl}/products/updateFreePrice`, productsToUpdate).pipe(map(response => {
        return response;
    }));
  }

  addNewComposition(payload: { idProduct: any; selectedVariants: any; selectedServices: any; }) {
    return this.http.post(`${this.baseUrl}/products/createNewComposition`, payload).pipe(map(response => {
        return response;
    }));
  }

  getAllCompositionByProductId(idProduct : number) {
    let params = new HttpParams();
        if (idProduct) {
            params = params.append('idProduct', idProduct);
        }
        return this.http.get<any>(`${this.baseUrl}/products/getAllComposition`, {
            params: params
        });
  }
    archiveProduct(uuidProduct: string) {
        let params = new HttpParams();
        if (uuidProduct) {
            params = params.append('uuidProduct', uuidProduct);
        }
        return this.http.get<any>(`${this.baseUrl}/products/checkAndDeleteProduct`, {
            params: params
        });
    }
    activerProduct(uuidProduct: string) {
        let params = new HttpParams();
        if (uuidProduct) {
            params = params.append('uuidProduct', uuidProduct);
        }
        return this.http.get<any>(`${this.baseUrl}/products/activateProduct`, {
            params: params
        });
    }
    downloadDepotVariantExcel(depotId: any, categoryIds: number[]) {
        const requestBody = { depotIds: depotId, categoryIds: categoryIds };

        return this.http.post(`${this.baseUrl}/depotVariant/downloadDepotVariant`, requestBody, {
            responseType: 'blob'
        });
    }

    downloadDepotVariantCloturerExcel(idInventory: any) {
        const params = new HttpParams().set('inventoryId', idInventory);
        return this.http.get(`${this.baseUrl}/inventory/downloadDataInventory`, { params, responseType: 'blob' });
    }

  assignPromoToProducts(submitData: any) {
    return this.http.post(`${this.baseUrl}/promotion/assignProduct`, submitData).pipe(map(response => {
        return response;
    }));
  }

    getPromotionPerPage(options: ViewOptions, startDate?: string, endDate?: string, type?: string) {
        let queryParams = new HttpParams();
        if (type) {
            queryParams = queryParams.set('type', type);
        }
        if (startDate && endDate) {
            queryParams = queryParams.set('startDate', startDate);
            queryParams = queryParams.set('endDate', endDate);
        }
        queryParams = queryParams.append("pageNum", options.pageNum);
        queryParams = queryParams.append("pageSize", options.pageSize);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this.http.get(`${this.baseUrl}/promotion/getAllPromoByPage`, {params: queryParams});
    }

    getReparationsPerPage(options: ViewOptions, carName?:string, matricule?:string, client?:string, startDate?: string, endDate?: string) {
        let queryParams = new HttpParams();

        if (startDate && endDate) {
            queryParams = queryParams.set('startDate', startDate);
            queryParams = queryParams.set('endDate', endDate);
        }
        queryParams = queryParams.append("pageNum", options.pageNum);
        queryParams = queryParams.append("pageSize", options.pageSize);
        if (carName) (queryParams = queryParams.append("carName", carName));
        if (matricule) (queryParams = queryParams.append("matricule", matricule));
        if (client) (queryParams = queryParams.append("client", client));
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this.http.get(`${this.baseUrl}/reparation/getAllReparationsByPage`, {params: queryParams});
    }

    getProductsListByPageAndPromoId(options: ViewOptions,idPromo : number) {
        let queryParams = new HttpParams();
        if (idPromo) {
            queryParams = queryParams.set('idPromo', idPromo);
        }
        queryParams = queryParams.append("pageNum", options.pageNum);
        queryParams = queryParams.append("pageSize", options.pageSize);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this.http.get(`${this.baseUrl}/promotion/get-all-product-by-promo`, {params: queryParams});
    }
    getLastPriceUpdated(uuid: any, startDate: string, endDate: string) {
        let params = new HttpParams();
        if (uuid) {
            params = params.append('uuidProduct', uuid);
        }
        if (startDate) {
            params = params.append('startDate', startDate);
        }
        if (endDate) {
            params = params.append('endDate', endDate);
        }

        return this.http.get<any>(`${this.baseUrl}/HistoricPrice/getLastPriceUpdated`, {
            params: params
        });
    }


    filterDepotVariant(payload: { depotId: any; categorie: any; variant: any; product : string}) {
        let params = new HttpParams()
        .set('categorieId', payload.categorie)
        .set('depotId', payload.depotId)
        .set('variantId',payload.variant)
        .set('productUuid',payload.product)
      return this.http.get<any>(`${this.baseUrl}/depotVariant/filterByCategory/`, { params: params });
    }

    baseUrl = environment.stockUrl;

    reloadEvent: EventEmitter<void> = new EventEmitter<void>();
    private productListSubject = new Subject<any>();
    private initPageSubject = new Subject<void>();

    private listProductStateSubject = new BehaviorSubject<any>({
        filterState: {}
    });

    updateListProductState(listProductState: any) {
        this.listProductStateSubject.next(listProductState);
    }

    getListProductState(): Observable<any> {
        return this.listProductStateSubject.asObservable();
    }

    constructor(private http: HttpClient,private toast: NgToastService,) {
    }

  pageInit$ = this.initPageSubject.asObservable();

  pageInit() {
    this.initPageSubject.next();
  }

    exportToCSV(dataToExport: any[]): void {
        const StockHistoryModel = dataToExport.map(depot => ({
            "Référence produit": depot.variant.reference,
            "Quantité Réelle": depot.realQuantity,
            "Quantité Disponible": depot.availableQuantity,
            "Quantité Réservée": depot.reservedQuantity,
            "Quantité Vendue": depot.orderedQuantity,
            "Quantité Bloquante": depot.blockedQuantity

        }));

        const csvData = this.convertToCSV(StockHistoryModel);

        if (csvData) {
            const blob = new Blob(["\ufeff" +csvData], { type: 'text/csv;charset=utf-8;' });
            const fileName = 'Liste des niveaux de dépôt.csv';
            const link = document.createElement('a');

            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                alert("Cliquez sur le lien suivant pour télécharger le fichier : " + fileName);
            }
        } else {
            this.toast.error({ detail: "Erreur! Vous n'avez aucun produit au niveaux de dépôt", duration: 5000 });
        }
    }

    convertToCSV(data: any[]) {
        const separator = ';';
        if (data.length != 0) {
            const header = Object.keys(data[0]);
            const csv = [header.join(separator)];

            for (const row of data) {
                const values = header.map(key => {
                    let value = row[key];
                    if (typeof value === 'string') {
                        value = value.replace(/"/g, '""');
                        if (value.includes(separator)) {
                            value = `"${value}"`;
                        }
                    }
                    return value;
                });
                csv.push(values.join(separator));
            }

            return csv.join('\n');
        }
    }
    getDepotByVariantUuid(uuidvariant: any): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/depotVariant/getDepotForVariant/${uuidvariant}`,).pipe(map(response => {
            return response;
        }));
    }


    getAllProductByType(type: any): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/products/getAllProductByType/${type}`).pipe(map(response => {
            return response;
        }));
    }

    filterProductsSP(serachFildes: any,options: any ,type:any):Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("name", serachFildes.nom);
        queryParams = queryParams.append("refExterne", serachFildes.reference);
        queryParams = queryParams.append("typeProduct", type);
        queryParams = queryParams.append("pageNum", options.pageNum);
        queryParams = queryParams.append("pageSize", options.pageSize);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this.http.get(`${this.baseUrl}/products/filterProductsSP`, {params: queryParams});
    }



    getAllProductSPByPage(options: any ,type:any):Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("typeProduct", type);
        queryParams = queryParams.append("pageNum", options.pageNum);
        queryParams = queryParams.append("pageSize", options.pageSize);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this.http.get(`${this.baseUrl}/products/getAllProductSpByType`, {params: queryParams});
    }


    createProductSP(payload: any):  Observable<any>{
        return this.http.post(`${this.baseUrl}/products/createProductSP`, payload).pipe(map(response => {
            return response;
        }));
    }

    getInventoryBYId(id: any): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/inventory/getInventoryById/${id}`,).pipe(map(response => {
            return response;
        }));
    }

    getAllInvantoriesPerPage(options: any):Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("pageNum", options.pageNum);
        queryParams = queryParams.append("pageSize", options.pageSize);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this.http.get(`${this.baseUrl}/inventory/getAllInventory/`, {params: queryParams});
    }


    getDepotvariantsByIdsdepot(payload:any): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/depotVariant/get-all-depot-variant`,payload).pipe(map(response => {
            return response;
        }));
    }

    getProductsForCategories(payload :any) : Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/products/getProductsByCategoriesIds`,payload).pipe(map(response => {
            return response;
        }));
    }

    getAllTypeInvantoriesPerPage(options: any):Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("pageNum", options.pageNum);
        queryParams = queryParams.append("pageSize", options.pageSize);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this.http.get(`${this.baseUrl}/inventory/getAllTypeInventories/`, {params: queryParams});
    }

    deleteTypeInventory(id:any){
        return this.http.delete(`${this.baseUrl}/inventory/deleteTypeInventory/${id}`)
    }

    addTypeInventory(payload: any) {
        return this.http.post(`${this.baseUrl}/inventory/addTypeInventory/`, payload).pipe(map(response=> {
                return response;
            }));
    }
    updateInventory(payload:any){
        return this.http.put<any>(`${this.baseUrl}/inventory/updateInventory`, payload,).pipe(map(response => {
            return response;
        }));
    }
    addinventoryGreen(payload:any){
        return this.http.post<any>(`${this.baseUrl}/inventory/AddInventoryGreen`, payload,).pipe(map(response => {
            return response;
        }));
    }

    getAllTypeInventory(){
        return this.http.get<any>(`${this.baseUrl}/inventory/getAllTypeinventory`,).pipe(map(response => {
            return response;
        }));
    }

    sendProductListData(data: any) {
        this.productListSubject.next(data);
    }


    getAllProducts(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/products`,).pipe(map(response => {
            return response;
        }));
    }

    getAllServices(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/services`,).pipe(map(response => {
            return response;
        }));
    }

    getAllProductsForRentAndServices(saleType):Observable<any>{
        return this.http.get<any>(`${this.baseUrl}/products/getAllProductsForRentAndServices/${saleType}`,).pipe(map(response => {
            return response;
        }));
    }
    getAllProductsForSale():Observable<any>{
        return this.http.get<any>(`${this.baseUrl}/products/getAllProductsForSale`,).pipe(map(response => {
            return response;
        }));
    }
    composeProduct(payload){
        return this.http.post(`${this.baseUrl}/products/compose`,payload).pipe((result)=>result);

    }
    decomposeProduct(payload){
        return this.http.post(`${this.baseUrl}/products/ComposerDecomposerProduit`,payload).pipe((result)=>result);

    }
    verifyQuantityProductComposee(payload){
        return this.http.post(`${this.baseUrl}/products/verifyQuantity`,payload).pipe((result)=>result);

    }

    getAllProductsServices(retriveType: string, achatOuVente?: string): Observable<any> {
        const params: any = { retriveType };
        if (achatOuVente) {
            params.achatOuVente = achatOuVente;
        }
        return this.http.get<any>(`${this.baseUrl}/products/getAllProductsServices`, { params }).pipe(
            map(response => response)
        );
    }

    getAllProductsServicesWithStartsWith(
        retriveType: string,
        achatOuVente: string,
        startsWithName: string,
        startsWithReference: string,
        startsWithBarCode: string
    ): Observable<any> {
        const params: any = {
            retriveType,
            achatOuVente,
            startsWithName,
            startsWithReference,
            startsWithBarCode
        };

        return this.http.get<any>(`${this.baseUrl}/products/getAllProductsServicesWithStartsWith`, { params }).pipe(
            map(response => response)
        );
    }


    getAllVariant(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/variants/getAll`,).pipe(map(response => {
            return response;
        }));
    }
    getVariantByUuid(uuid: any): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/variants/getByUuid/${uuid}`,).pipe(map(response => {
            return response;
        }));
    }
    getVariantById(id: number): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/variants/getById/${id}`,).pipe(map(response => {
            return response;
        }));
    }
    getVariantsByIds(ids: number[]): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/variants/getByIds`,ids);
    }
    getServicesByIds(ids: number[]): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/services/getByIds`, {
          params: { ids: ids.join(',') }
        });
    }
    getServicesByUUID(UUID: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/services/${UUID}`);
    }
    getProductTaxes(id: any): Observable<any> {
        return this.http.get<Array<Tax>>(`${this.baseUrl}/products/taxes/${id}`,).pipe(map(response => {
            return response;
        }));
    }
    getInsuranceByProductId(id: any): Observable<any> {
        return this.http.get<Array<Tax>>(`${this.baseUrl}/assurance/last/${id}`,).pipe(map(response => {
            return response;
        }));
    }
    getProductById(id: any): Observable<any> {
        return this.http.get<Array<ProductModel>>(`${this.baseUrl}/products/getId/${id}`,).pipe(map(response => {
            return response;
        }));
    }
    getProductByIdV2(id: any): Observable<any> {
        return this.http.get<Array<ProductModel>>(`${this.baseUrl}/products/getId/${id}/V2`,).pipe(map(response => {
            return response;
        }));
    }

    loadImage(url:string): Observable < Blob > {
        return this.http.get(url, {
         responseType: "blob"
        });
       }

    getProductByUuid(uuid: any): Observable<any> {
        return this.http.get<Array<ProductModel>>(`${this.baseUrl}/products/${uuid}`,).pipe(map(response => {
            return response;
        }));
    }
    getProductOrService(uuid: any): Observable<any> {
        return this.http.get<ProductModel>(`${this.baseUrl}/products/productOrService/${uuid}`,).pipe(map(response => {
            return response;
        }));
    }

    getProductAndVariantByUuid(produitUuid: any, variantUuid?: any): Observable<any> {
        let url = `${this.baseUrl}/products/getProductAndVariant/${produitUuid}`;
        if (variantUuid) {
            url += `?variantUuid=${variantUuid}`;
        }
        return this.http.get<any>(url).pipe(map(response => {
            return response;
        }));
    }

    createdepot(payload: any) {
        return this.http.post(`${this.baseUrl}/depot/create`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return []; // only for catch error to work, it requires a return of array, object..
            }),
            map((response: any) => {
                return response;
            }));
    }


    triggerReload() {
      this.reloadEvent.emit();
    }



    getAllDepotsPerPage(defaultPageSize: any): Observable<any> {
        return this.http.get<Array<any>>(`${this.baseUrl}/depot/perPage/?page=${defaultPageSize.pageIndex + 1}&size=${defaultPageSize.pageSize}`,
        ).pipe(map(response => {
            return response;
        }));
    }
    getAllAlertesPerPage(defaultPageSize: any): Observable<any> {
        return this.http.get<Array<any>>(`${this.baseUrl}/alert/perPage/?page=${defaultPageSize.pageNum}&size=${defaultPageSize.pageSize}`,
        ).pipe(map(response => {
            return response;
        }));
    }

    getAllDepots(): Observable<any> {
        return this.http.get<Array<any>>(`${this.baseUrl}/depot/getAll`,
        ).pipe(map(response => {
            return response;
        }));
    }

    getAllAdminDepots(): Observable<any> {
        return this.http.get<Array<any>>(`${this.baseUrl}/depot/getAllDepots`,
        ).pipe(map(response => {
            return response;
        }));
    }


    getAllStocks(): Observable<any> {
        return this.http.get<Array<any>>(`${this.baseUrl}/stock/getAllStock`,
        ).pipe(map(response => {
            return response;
        }));
    }

    createstock(payload: any) {
        return this.http.post(`${this.baseUrl}/stock`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return []; // only for catch error to work, it requires a return of array, object..
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    updateStock(payload: any) {
        return this.http.put(`${this.baseUrl}/stock`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return []; // only for catch error to work, it requires a return of array, object..
            }),
            map((response: any) => {
                return response;
            })
        );
    }



    createAlerte(payload: any):Observable<any> {
        return this.http.post(`${this.baseUrl}/alert`, payload);
    }
    updateAlerte(payload: any) :Observable<any>{
        return this.http.put(`${this.baseUrl}/alert`, payload);
    }
    updateAlerteQuantity(payload: any) {
        return this.http.put(`${this.baseUrl}/alertVariant/updateAlertVariant`, payload);
    }
    updateDepot(payload): Observable<any> {
        return this.http.put(`${this.baseUrl}/depot/update`, payload, {
        }).pipe(
            timeout(60000),
            catchError((e, c) => {
                return [];
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    updateDepotThreshold(payload : any) {
        return this.http.put(`${this.baseUrl}/depot/updateThreshold`, payload, {
        }).pipe(
            timeout(60000),
            catchError((e, c) => {
                return [];
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    affectDefaultDepot(uuid) {
        return this.http.get<Array<any>>(`${this.baseUrl}/depot/affectDefault/${uuid}`
        ).pipe(map(response => {
            return response;
        }));
    }

    deletedAlerte(uuid: any) {
        return this.http.delete<any>(`${this.baseUrl}/alert/delete/${uuid}`);
    }
    deleteImage(nodeId : string,type:string){
        return this.http.delete(`${this.baseUrl}/products/delete/${nodeId}/${type}`);
    }

    getAllStockProducts() {
        return this.http.get<any>(`${this.baseUrl}/products/getAll`)
    }
    addNewService(serviceModelDto : ServiceModelDto) {
        return this.http.post(`${this.baseUrl}/services`, serviceModelDto);
    }

    getAllServicesBypage(payload: any): Observable<any> {

        let params = new HttpParams()
            .set('pageNum', payload.pageNum)
            .set('pageSize', payload.pageSize)
            .set('sortField', payload.sortField)
            .set('sortDirection', payload.sortDirection)
            .set('serviceType',  payload.serviceType);

        return this.http.get(`${this.baseUrl}/services/getAllByPage/`, { params: params });
    }


    getAllStockBypage(pageNum: any, pageSize: any, sortField: any, sortDirection: any): Observable<any> {
        let params = new HttpParams()
            .set('pageNum', pageNum)
            .set('pageSize', pageSize)
            .set('sortField', sortField)
            .set('sortDirection', sortDirection);
        return this.http.get(`${this.baseUrl}/stock/getAllByPage`, { params: params });
    }

    filter(rawValue, pageIndex, PageSize , categoryId? : any) {
        let params = new HttpParams()
            .set('name', rawValue.nom)
            .set('reference', rawValue.reference);
            if(categoryId){
                params = params.set('categoryId', categoryId);
              }
        return this.http.get(`${this.baseUrl}/services/filter/${pageIndex}/${PageSize}`, { params: params });
    }

    private setParams(requestedParams: any): HttpParams {
        let params = new HttpParams();
        if (requestedParams?.name !== null && requestedParams?.name !==undefined) {
            params = params.set('name', requestedParams.name);
        }
        if (requestedParams?.reference !== null && requestedParams?.reference !==undefined) {
            params = params.set('reference', (requestedParams.reference)?.toString());
        }
        if (requestedParams?.minPrice !== null && requestedParams.minPrice !==undefined) {
            params = params.set('minPrice', requestedParams.minPrice);
        }
        if (requestedParams?.maxPrice !== null && requestedParams.maxPrice !==undefined) {
            params = params.set('maxPrice', requestedParams.maxPrice);
        }
        if (requestedParams?.categoryId !== null && requestedParams.categoryId !== undefined) {
            params = params.set('categoryId', requestedParams.categoryId);
        }
        if (requestedParams?.productType !== null && requestedParams.productType !== '' && requestedParams?.productType !== undefined) {
            params = params.set('productType', requestedParams.productType);
        }
        if (requestedParams?.isArchived !== null && requestedParams.isArchived !== '' && requestedParams?.isArchived !== undefined) {
            params = params.set('isArchived', requestedParams.isArchived);
        }
        return params;
    }

    filterProducts(params: any): Observable<any> {
        return this.http.get(`${this.baseUrl}/products/filter`, { params: this.setProductParams(params) });
    }

    private setStockLevelParams(options, requestedParams: any): HttpParams {
        let params = new HttpParams();
        params = params.set('pageNum', (options.pageIndex).toString());
        params = params.set('pageSize', (options.pageSize).toString());
        params = params.set('sortField', options.sortField);
        params = params.set('sortDirection', options.sortDirection);
        if (requestedParams.minQuantityInitial !== null) {
            params = params.set('minQuantityInitial', (requestedParams.minQuantityInitial).toString());
        }
        if (requestedParams.maxQuantityInitial !== null) {
            params = params.set('maxQuantityInitial', (requestedParams.maxQuantityInitial).toString());
        }
        if (requestedParams.minQuantityAvailable !== null) {
            params = params.set('minQuantityAvailable', (requestedParams.minQuantityAvailable).toString());
        }
        if (requestedParams.maxQuantityAvailable !== null) {
            params = params.set('maxQuantityAvailable', (requestedParams.maxQuantityAvailable).toString());
        }
        if (requestedParams.minQuantityOrdered !== null) {
            params = params.set('minQuantityOrdered', (requestedParams.minQuantityOrdered).toString());
        }
        if (requestedParams.maxQuantityOrdered !== null) {
            params = params.set('maxQuantityOrdered', (requestedParams.maxQuantityOrdered).toString());
        }
        if (requestedParams.ref !== null) {
            params = params.set('ref', (requestedParams.ref));
        }
        return params;
    }

    private setStockParams(options, requestedParams: any): HttpParams {
        let params = new HttpParams();
        params = params.set('pageNum', (options.pageNum).toString());
        params = params.set('pageSize', (options.pageSize).toString());
        params = params.set('sortField', options.sortField);
        params = params.set('sortDirection', options.sortDirection);
        if (requestedParams.product !== null) {
            params = params.set('product', requestedParams.product);
        }
        if (requestedParams.depot !== null) {
            params = params.set('depot', requestedParams.depot);
        }
        if (requestedParams.quantity_min !== null) {
            params = params.set('quantity_min', (requestedParams.quantity_min).toString());
        }
        if (requestedParams.quantity_max !== null) {
            params = params.set('quantity_max', (requestedParams.quantity_max).toString());
        }
        return params;
    }


    private setProductParams(requestedParams: any): HttpParams {
        let params = new HttpParams();
        if (requestedParams.nom !== null) {
            params = params.set('nom', requestedParams.nom);
        }
        if (requestedParams.refExterne !== null) {
            params = params.set('refExterne', (requestedParams.refExterne).toString());
        }
        if (requestedParams.productPrice.purchase_price_min !== null) {
            params = params.set('achat_min', (requestedParams.productPrice.purchase_price_min).toString());
        }
        if (requestedParams.productPrice.purchase_price_max !== null) {
            params = params.set('achat_max', (requestedParams.productPrice.purchase_price_max).toString());
        }
        if (requestedParams.productPrice.advised_sale_price_min !== null) {
            params = params.set('conseille_min', (requestedParams.productPrice.advised_sale_price_min).toString());
        }
        if (requestedParams.productPrice.advised_sale_price_max !== null) {
            params = params.set('conseille_max', (requestedParams.productPrice.advised_sale_price_max).toString());
        }
        if (requestedParams.productPrice.retailer_selling_price_min !== null) {
            params = params.set('revendeur_min', (requestedParams.productPrice.retailer_selling_price_min).toString());
        }
        if (requestedParams.productPrice.retailer_selling_price_max !== null) {
            params = params.set('revendeur_max', (requestedParams.productPrice.retailer_selling_price_max).toString());
        }
        return params;
    }

    getReference() {
        return this.http.get(`${this.baseUrl}/stock/lastReference`, { responseType: 'text' });
    }

    updateService(serviceDto: any, taxes: any[] | null) {
        let params = new HttpParams();

        if (taxes && taxes.length > 0) {
            taxes.forEach((tax) => {
                params = params.append('taxes', tax.toString());
            });
        }

        return this.http.put(`${this.baseUrl}/services`, serviceDto, { params });
    }

    deleteServiceByUuid(uuidService) {
        return this.http.delete(`${this.baseUrl}/services/delete/${uuidService}`)

    }

    getReservedProductDays(id: number, month?: string): Observable<ReservedDaysProductModel[]> {
        const params = month ? { month } : {};
        return this.http.get<ReservedDaysProductModel[]>(
            `${this.baseUrl}/products/getReservedProductDays/${id}`,
            { params }
        );
    }


    updatePrice(payload: any): Observable<any> {
        return this.http.put(`${this.baseUrl}/products/updatePrice`, payload);
    }

    getSubOptionsGroupedBy(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/sub-options/getAllGroupedBy/`,).pipe(map(response => {
            return response;
        }));
    }

    getAllOptions(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/product-options/getAll`,).pipe(map(response => {
            return response;
        }));
    }

    createOption(payload: any): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/product-options/create`, payload,).pipe(map(response => {
            return response;
        }));
    }

    getSubOptionsByOptionUUID(uuid: any): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/sub-options/getAllByOptions/${uuid}`,).pipe(map(response => {
            return response;
        }));
    }

    createSubOption(payload: any): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/sub-options/create`, payload);
    }

    createVariant(payload: any): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/variants/create`, payload,).pipe(map(response => {
            return response;
        }));
    }

    transfereVariantQuantity(payload: any) {

        return this.http.post<any>(`${this.baseUrl}/variants/transfereVariantQuantity`, payload).pipe(map(response => {
            return response;
        }));
    }
    transferePurchaseOrderQuantity(payload: any) {

        return this.http.post<any>(`${this.baseUrl}/variants/transferePurchaseOrderQuantity`, payload).pipe(map(response => {
            return response;
        }));
    }

    transfereDeliveryFormQuantity(payload: any) {

        return this.http.post<any>(`${this.baseUrl}/variants/transfereDeliveryFormQuantity`, payload).pipe(map(response => {
            return response;
        }));
    }

    getProductVariant(id: any): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/variants/getVariantsByProduct/${id}`).pipe(map(response => {
            return response;
        }));
    }

    getLotMinValueToBeginWith(uuidVariant: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/serial-nbr/getLotMinValue/${uuidVariant}`).pipe(map(response => {
            return response;
        }));
    }
    getSerialnbrList(uuidVariant: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/sku/getSerialNumberByVariant/${uuidVariant}`).pipe(map(response => {
            return response;
        }));
    }
    getSimmilarVariants(id: any): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/variants/getSelectedVariantSimilaires/${id}`).pipe(map(response => {
            return response;
        }));
    }
    getProductsWithVariantPageable(options:any): Observable<any> {
              let params = new HttpParams()
            .set('pageNum', options.pageNum)
            .set('pageSize', options.pageSize)
            .set('sortField', options.sortField)
            .set('sortDirection', options.sortDirection);
            if (options.nom !== null) {
                params =params.append('nom', options.nom);
            }
            if (options.reference !== null) {
                params = params.append('reference', options.reference);
            }

        return this.http.get<any>(`${this.baseUrl}/variants/product`, { params: params }).pipe(map(response => {
            return response;
        }));
    }


    getAllVariantsByProductUuid(uuid: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/variants/getAllVariantsByProductUuid/${uuid}`).pipe(map(response => {
            return response;
        }));
    }

    getProductQuantity(id: any): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/mouvementdepot/getByProduct/${id}`).pipe(map(response => {
            return response;
        }));
    }



    createProduct(payload: any):  Observable<any>{
        return this.http.post(`${this.baseUrl}/products/create`, payload).pipe(map(response => {
            return response;
        }));
    }

    duplicateVariant(payload: any,originalProductId:any): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/variants/duplicate/${originalProductId}`,payload).pipe(map(response => {
            return response;
        }));
    }




    updateProduct(payload: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/products`, payload)
    }

    updateProductCategory(payload: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/products/updateCategory`, payload)
    }

    getPageProduct(options: ViewOptions): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("pageSize", options.pageSize);
        queryParams = queryParams.append("pageNum", options.pageNum);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this.http.get(`${this.baseUrl}/products/getProduct/page`, { params: queryParams })

    }
    getPageProductByType(options: ViewOptions ,typeProduct : string, searchFields: any ): Observable<any> {
        let queryParams = new HttpParams();
        if(typeProduct){
            queryParams = queryParams.set('typeProduct', typeProduct);
          }
        if (searchFields?.name !== null && searchFields?.name !==undefined) {
            queryParams = queryParams.set('name', searchFields.name);
        }
        if (searchFields?.reference !== null && searchFields?.reference !==undefined) {
            queryParams = queryParams.set('reference', (searchFields.reference)?.toString());
        }
        if (searchFields?.minPrice !== null && searchFields.minPrice !==undefined) {
            queryParams = queryParams.set('minPrice', searchFields.minPrice);
        }
        if (searchFields?.maxPrice !== null && searchFields.maxPrice !==undefined) {
            queryParams = queryParams.set('maxPrice', searchFields.maxPrice);
        }
        if (searchFields?.categoryId !== null && searchFields.categoryId !== undefined) {
            queryParams = queryParams.set('categoryId', searchFields.categoryId);
        }
        if (searchFields?.productType !== null && searchFields.productType !== '' && searchFields?.productType !== undefined) {
            queryParams = queryParams.set('productType', searchFields.productType);
        }
        if (searchFields?.isArchived !== null && searchFields.isArchived !== '' && searchFields?.isArchived !== undefined) {
            queryParams = queryParams.set('isArchived', searchFields.isArchived);
        }
        queryParams = queryParams.append("pageSize", options.pageSize);
        queryParams = queryParams.append("pageNum", options.pageNum);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this.http.get(`${this.baseUrl}/products/getProductType/page`, { params: queryParams })
    }
    getPageProductSipmleAndMp(options: ViewOptions , name? : string , ref? : string,productType? : any  , categoryId? : any): Observable<any> {
        let queryParams = new HttpParams();
        if(name){
            queryParams = queryParams.set('name', name);
          }
          if(ref){
            queryParams = queryParams.set('ref', ref);
          }
          if(productType){
            queryParams = queryParams.set('productType', productType);
          }
          if(categoryId){
            queryParams = queryParams.set('categoryId', categoryId);
          }
        queryParams = queryParams.append("pageSize", options.pageSize);
        queryParams = queryParams.append("pageNum", options.pageNum);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this.http.get(`${this.baseUrl}/products/getProductSimpleAndMP/page`, { params: queryParams })
    }

    filterProductPage(paginationOption: ViewOptions, searchFields: any, productType? : string , isArchived?: boolean) {
        let pageNum = paginationOption.pageNum
        let pageSize = paginationOption.pageSize
        let sortField = paginationOption.sortField
        let sortDirection = paginationOption.sortDirection
        if(productType){
            searchFields.productType = productType;
        }
        if(isArchived){
            searchFields.isArchived = isArchived;
        }
        return this.http.get(`${this.baseUrl}/products/filterProduct/page/${pageNum}/${pageSize}/${sortField}/${sortDirection}`,
            { params: this.setParams(searchFields) })

    }

    getPageStocksHistory(paginationOption: ViewOptions): Observable<IPage<StockHistoryModel>> {
        let params = new HttpParams();
        params = params.set('pageSize', paginationOption.pageSize);
        params = params.set('pageNum', paginationOption.pageNum);
        params = params.set('sortDirection', paginationOption.sortDirection);
        params = params.set('sortField', paginationOption.sortField);
        return this.http.get(`${this.baseUrl}/stock-history/getpage`, { params: params }).pipe(map(
            (data: IPage<StockHistoryModel>) => {
                data.content.forEach(element => {

                    element.context
                    if (element.context == "ADD_STOCK") {
                        element.contextDetails = "Ajout du stock " + element.stockName;
                    }
                    if (element.context == "ADD_ENTREE_STOCK") {
                        element.contextDetails = "Ajout d\'une nouvelle entrée de stock " + element.mvtStockRef;
                    }
                    if (element.context == "ADD_SORTIE_STOCK") {
                        element.contextDetails = "Ajout d\'une nouvelle sortie de stock " + element.mvtStockRef;
                    }
                    if ((element.context == "UPDATE_STOCK" || element.context == "UPDATE_ENTREE_STOCK" || element.context == "UPDATE_SORTIE_STOCK") && element.contextDetails != null) {
                        let words = element.contextDetails.split(',');
                    }
                    return element;
                });
                return data;

            }
        ))

    }

    getPageDepotHistory(paginationOption: ViewOptions,variant? : string, depotName? : string,parentCategory?:number[], startDate? : string, endDate?: string , product? : string): Observable<IPage<StockHistoryModel>> {
        let params = new HttpParams();
        if (variant) {
            params = params.set('variant', variant);
        }
        if (depotName) {
            params = params.set('depotName', depotName);
        }
        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        if (parentCategory && parentCategory.length > 0) {
            params = params.set('parentCategory', parentCategory.join(','));
        }
        if (product) {
            params = params.set('product', product);
        }
        params = params.set('pageSize', paginationOption.pageSize);
        params = params.set('pageNum', paginationOption.pageNum);
        params = params.set('sortDirection', paginationOption.sortDirection);
        params = params.set('sortField', paginationOption.sortField);
        return this.http.get(`${this.baseUrl}/depot-history/getpage`, { params: params }).pipe(map(
            (data: IPage<StockHistoryModel>) => {
                data.content.forEach(element => {
                    element.context
                    if (element.context == "ADD_STOCK") {
                        element.contextDetails = "Ajout du stock " + element.stockName;
                    }
                    if (element.context == "ADD_ENTREE_STOCK") {
                        element.contextDetails = "Ajout d\'une nouvelle entrée de stock " + element.mvtDepotRef;
                    }
                    if (element.context == "ADD_SORTIE_STOCK") {
                        element.contextDetails = "Ajout d\'une nouvelle sortie de stock " + element.mvtDepotRef;
                    }
                    if (element.context == "RETOUR_AVOIR") {
                        element.contextDetails = "Retour d\'une sortie de stock " + element.mvtDepotRef;
                    }
                    if (element.context == "ADD_ANNULER_STOCK") {
                        element.contextDetails = "Annuler d\'une sortie de stock " + element.mvtDepotRef;
                    }
                    if (element.context == "ADD_ANNULER_STOCK_ACHAT") {
                        element.contextDetails = "Annuler d\'une entrée de stock " + element.mvtDepotRef;
                    }
                    if (element.type == "STOCK_INITIAL") {
                        element.contextDetails = "Entrée de stock initial";
                    }
                    if (element.context == "CORRECT_INVENTORY") {
                        element.contextDetails = "correction de stock par inventaire " + element.mvtDepotRef;
                    }
                    if ((element.context == "UPDATE_STOCK" || element.context == "UPDATE_ENTREE_STOCK" || element.context == "UPDATE_SORTIE_STOCK") && element.contextDetails != null) {
                        let words = element.contextDetails.split(',');
                    }
                    return element;
                });
                return data;

            }
        ))

    }
    getPageDepotHistoryinit(paginationOption: ViewOptions,variant? : string, depotName? : string, startDate? : string, endDate?: string): Observable<IPage<StockHistoryModel>> {
        let params = new HttpParams();
        if(variant){
            params = params.set('variant', variant);
        }
        if(depotName){
            params = params.set('depotName', depotName);
        }
        if(startDate){
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if(endDate){
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        params = params.set('pageSize', paginationOption.pageSize);
        params = params.set('pageNum', paginationOption.pageNum);
        params = params.set('sortDirection', paginationOption.sortDirection);
        params = params.set('sortField', paginationOption.sortField);
        return this.http.get(`${this.baseUrl}/depot-history/getpageinit`, { params: params }).pipe(map(
            (data: IPage<StockHistoryModel>) => {
                data.content.forEach(element => {
                    element.context
                    if (element.context == "ADD_STOCK") {
                        element.contextDetails = "Ajout du stock " + element.stockName;
                    }
                    if (element.context == "ADD_ENTREE_STOCK") {
                        element.contextDetails = "Ajout d\'une nouvelle entrée de stock " + element.mvtDepotRef;
                    }
                    if (element.context == "ADD_SORTIE_STOCK") {
                        element.contextDetails = "Ajout d\'une nouvelle sortie de stock " + element.mvtDepotRef;
                    }
                    if (element.context == "RETOUR_AVOIR") {
                        element.contextDetails = "Retour d\'une sortie de stock " + element.mvtDepotRef;
                    }
                    if (element.context == "ADD_ANNULER_STOCK") {
                        element.contextDetails = "Annuler d\'une sortie de stock " + element.mvtDepotRef;
                    }
                    if (element.context == "ADD_ANNULER_STOCK_ACHAT") {
                        element.contextDetails = "Annuler d\'une entrée de stock " + element.mvtDepotRef;
                    }
                    if (element.type == "STOCK_INITIAL") {
                        element.contextDetails = "Entrée de stock initial";
                    }
                    if (element.context == "CORRECT_INVENTORY") {
                        element.contextDetails = "correction de stock par inventaire " + element.mvtDepotRef;
                    }

                    return element;
                });
                return data;

            }
        ))

    }
    getcountEntreSortie(
        variant?: string,
        depotName?: string,
        startDate?: string,
        endDate?: string,
        parentCategory?:number[]
    ): Observable<IPage<StockHistoryModel>> {
        let params = new HttpParams();

        if (variant) {
            params = params.set('variant', variant);
        }
        if (depotName) {
            params = params.set('depotName', depotName);
        }
        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        if (parentCategory && parentCategory.length > 0) {
            params = params.set('parentCategory', parentCategory.join(','));
          }
        return this.http.get<IPage<StockHistoryModel>>(`${this.baseUrl}/depot-history/countsEntreSortie`, { params }).pipe(
            map(data => {
                return data;
            })
        );
    }

    getAllTypeDepot(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/type-depot/getAllTypeDepot`).pipe(map(response => {
            return response;
        }));
    }

    getAllDepotVariants(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/depotVariant/getAll`).pipe(map(response => {
            return response;
        }));
    }

    getAllDepotVariantByVariant(uuid: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/depotVariant/getAllByVariant/${uuid}`).pipe(map(response => {
            return response;
        }));
    }

    getDepotByDevis(uuid: any): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/depot/getByDevis/${uuid}`).pipe(map(response => {
            return response;
        }));
    }

    getMouvementDepot(id: any, showAllRows: boolean): Observable<any> {
        const url = showAllRows ? `${this.baseUrl}/depotVariant/${id}?showAllRows=true` : `${this.baseUrl}/depotVariant/${id}`;
        return this.http.get<any>(url).pipe(map(response => {
          return response;
        }));
      }
      getDepotCost(id: any): Observable<any> {
        const url = `${this.baseUrl}/depotVariant/calculateDepotCost/${id}`;
        return this.http.get<any>(url).pipe(map(response => {
          return response;
        }));
      }
    createTransfertDepot(payload: any): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/mouvementdepot/createTransfertDepot`, payload)
    }
    createRequestTransfertDepot(payload: any): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/TransferRequest/createTransferRequest`, payload)
    }
    getAllRequestsBypage(options: any):Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("pageSize", options.pageSize);
        queryParams = queryParams.append("pageNum", options.pageNum);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this.http.get(`${this.baseUrl}/TransferRequest/getAllRequestsByPage/`, {params: queryParams});
    }
    updateStatus(payload: any) {
        return this.http.put(`${this.baseUrl}/TransferRequest/UpdateStatus`, payload,).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    getDefaultDepot(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/depot/getDefaultDepot`).pipe(map(response => {
            return response;
        }));
    }
    getDepotByUuid(uuid:any): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/depot/get/${uuid}`).pipe(map(response => {
            return response;
        }));
    }

    getDepotById(id:any): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/depot/getById/${id}`).pipe(map(response => {
            return response;
        }));
    }

    affectCollaboratorsToDepot(payload): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/depot/affectCollabs`, payload).pipe(map(response => {
            return response;
        }));
    }


    getByDefaultDepotFalse(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/depot/getDefaultDepotFalse`).pipe(map(response => {
            return response;
        }));
    }
    getAllListParentCategory(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/category/getParentCategory`).pipe(map(response => {
            return response;
        }));
    }

    getAllCategoriesPerPage(options: any, depot?, category?, variant?):Observable<any> {
        let queryParams = new HttpParams();
        if(depot){
            queryParams = queryParams.set('depot', depot);
        }
        if(category){
            queryParams = queryParams.set('category', category);
        }
        if(variant){
            queryParams = queryParams.set('variant', variant);
        }
        queryParams = queryParams.append("pageIndex", options.pageIndex);
        queryParams = queryParams.append("pageSize", options.pageSize);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this.http.get(`${this.baseUrl}/category/filterCategoryPageable/`, {params: queryParams});
    }

    getAllCategories(options, depot?, category?): Observable<any> {
        let queryParams = new HttpParams();
        if (depot) {
            queryParams = queryParams.set('idDepot', depot);
        }
        if (category) {
            queryParams = queryParams.set('idCategory', category);
        }
        queryParams = queryParams.append('pageSize', options.pageSize);
        queryParams = queryParams.append('pageNum', options.pageNum);
        if (options.sortField) {
            queryParams = queryParams.append('sortField', options.sortField);
        }
        if (options.sortDirection) {
            queryParams = queryParams.append('sortDirection', options.sortDirection);
        }
        return this.http.get<any>(`${this.baseUrl}/category/getAllCategories`, { params: queryParams }).pipe(map(response => {
            return response;
        }));
    }

    createCategory(subject: any, imageFile?: File) {
        const formData = new FormData();
        formData.append('category', JSON.stringify(subject));

        if (imageFile) {
            formData.append('image', imageFile);
        }

        return this.http.post(`${this.baseUrl}/category/create`, formData,).pipe(
            map(response => {
                return response;
            })
        );

      }
    getAllListByParentCategoryId(id): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/category/getByParentCategory/${id}`).pipe(map(response => {
            return response;
        }));
    }

    getParentCategoryWithChildren(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/category/getAllParentCategory`).pipe(map(response => {
            return response;
        }));
    }

    getListCategories(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/category/getListCategories`).pipe(map(response => {
            return response;
        }));
    }
    getCategorieswithProductsQuantity(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/category/getProductCountForAllCategories`).pipe(map(response => {
            return response;
        }));
    }

    getParentCategoryWithChildrenAndQuantity(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/category/getParentCategoryWithChildrenAndQuantity`).pipe(map(response => {
          return response;
        }));
      }

    getAllCategory(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/category/getAll`).pipe(map(response => {
            return response;
        }));
    }
    getAllCategoryNames( categoryType?: ElementTypeEnum): Observable<any> {
        let params = new HttpParams();
        if (categoryType) {
            params = params.set('categoryType', categoryType);
        }
        return this.http.get<any>(`${this.baseUrl}/category/getAllNames`,{ params }).pipe(
            map(response => response)
        );
    }

    deleteCategory(uuid: any) {
        return this.http.delete(`${this.baseUrl}/category/${uuid}`)
    }
    updateCategory(subject: any, imageFile?: File[]) {
        const formData = new FormData();
        formData.append('category', JSON.stringify(subject));

        if (imageFile) {
            formData.append('image', imageFile[0]);
        }

        return this.http.post(`${this.baseUrl}/category/update`, formData, {
        }).pipe(map(response => {
            return response;
        }));
    }
    getByCategoryId(id: number, achatOuVente?: string): Observable<any> {
        let params = new HttpParams();
        if (achatOuVente) {
            params = params.set('achatOuVente', achatOuVente);
        }
        return this.http.get<any>(`${this.baseUrl}/products/getByCategoryId/${id}`, { params }).pipe(
            map(response => response)
        );
    }

    createCategoryDefault() {
        return this.http.get(`${this.baseUrl}/category/createCategoryDefault`);
    }

    deleteOrUpdateVariantByUuid(uuid,productId): Observable<any> {

        return this.http.post<any>(`${this.baseUrl}/variants/deleteOrUpdateVariantByUuid/${uuid}/${productId}`,{}).pipe(map(response => {
            return response;
        }));
    }


    getAllVariantByProductIdAndStatus(id,status): Observable<any> {
        let params = new HttpParams()
            .set('status', status);
        return this.http.get<any>(`${this.baseUrl}/variants/getAllByProductIdAndArchived/${id}`,{ params: params }).pipe(map(response => {
            return response;
        }));
    }



    filterTransfert(depotId :any , reference:any): Observable<any>{
        let params = new HttpParams();
    if (reference != null ) {
     params = params.set('reference', reference);
    }
    if (depotId != '' ) {
        params = params.set('depotId', depotId);
       }

        return this.http.get<any>(`${this.baseUrl}/depotVariant/filter`,{ params: params }).pipe(map(response => {
            return response;
          }));

    }


    getAllAlertesVariantPerPage(options, requestedParams: any): Observable<any> {
        let params = new HttpParams();
        params = params.set('pageNum', (options.pageNum).toString());
        params = params.set('pageSize', (options.pageSize).toString());
        params = params.set('sortField', options.sortField);
        params = params.set('sortDirection', options.sortDirection);
        if (requestedParams !== null) {
            params = params.set('idDepot', requestedParams);
        }
        return this.http.get<any>(`${this.baseUrl}/alertVariant/getByPage`, { params: params });
    }


    getMonthlyAvailableQuantityForVariant(uuidVariant,year)
    {
        let params = new HttpParams();
        if (uuidVariant != '' ) {
         params = params.set('uuidVariant', uuidVariant);
        }
        if (year != '' ) {
            params = params.set('year', year);
           }
        return this.http.get<any>(`${this.baseUrl}/VariantQuantityHistory/statistics/monthly/`,{ params: params }).pipe(map(response => {
            return response;
        }));
    }
    getYearlyPriceVariation(uuidVariant) {
        return this.http.get<any>(`${this.baseUrl}/HistoricPrice/product/${uuidVariant}/yearly-variation`).pipe(
            map(response => {
                return response;
            })
        );


    }



    getHistoryByProductVariant(variantUuid) {
        return this.http.get<any>(`${this.baseUrl}/depot-history/getHistoryByProductVariant/${variantUuid}`).pipe(
            map(response => {
                return response;
            })
        );
    }


    getVariantQuantityHistory(uuidVariant, startDate, endDate): Observable<any> {
        let params = new HttpParams();
        if(uuidVariant){
            params=params.set('uuidVariant',uuidVariant)
        }
        if(startDate){
          params = params.set('startDateTime',startDate);
        }
        if(endDate){
          params = params.set('endDateTime', endDate);
        }
        return this.http.get<any[]>(`${this.baseUrl}/VariantQuantityHistory/statisticsStock`, { params: params })
      }

    getcalculateDepotCost(idDepot: number, year: number, categoryId?: number) {
        let params = new HttpParams().set('idDepot', idDepot?.toString()).set('year', year.toString());
        if (categoryId) {
            params = params.set('categoryId', categoryId.toString());
        }
        return this.http.get<any>(`${this.baseUrl}/depotVariant/calculateDepotCostVariation`, { params });
    }


    getAvailableQuantity(uuidVariant)
    {
        return this.http.get<any>(`${this.baseUrl}/depotVariant/getAvailableQuantity/${uuidVariant}`).pipe(map(response => {
            return response;
        }));
    }
    getQuantities(idVariant)
    {
        return this.http.get<any>(`${this.baseUrl}/depotVariant/getQuantities/${idVariant}`).pipe(map(response => {
            return response;
        }));
    }
     getBestGain(): Observable<any> {
        return this.http.get<Array<any>>(`${this.baseUrl}/variants/getBestGains`,
        ).pipe(map(response => {
            return response;
        }));
    }
    getBestSeller(): Observable<any> {
        return this.http.get<Array<any>>(`${this.baseUrl}/variants/calculateBestSale`,
        ).pipe(map(response => {
            return response;
        }));
    }
    addInventoryWithDetails(payload:any){
        return this.http.post<any>(`${this.baseUrl}/inventory/addInventoryWithDetails`, payload,).pipe(map(response => {
            return response;
        }));
    }

   updateInventoryWithDetails(payload:any){
        return this.http.put<any>(`${this.baseUrl}/inventory/updateInventoryWithDetails`, payload,).pipe(map(response => {
            return response;
        }));
    }

    getQuantityChanges(uuidVariant,year): Observable<any[]> {
        let params = new HttpParams();
        if (uuidVariant != '' ) {
            params = params.set('uuidVariant', uuidVariant);
        }
        if (year != '' ) {
            params = params.set('year', year);
            return this.http.get<any[]>(`${this.baseUrl}/depot-history/statistics/changes`, {params: params}).pipe(map(response => {
                return response;
            }));
        }}
        getVerificationBdc(payload: any) : Observable<any>{
            return this.http.post<any>(`${this.baseUrl}/mouvementdepot/get-inventory-verification`, payload).pipe(map(response => {
                return response;
            }));
          }
          getInventoryData(id: any): Observable<any> {
            return this.http.get<any>(`${this.baseUrl}/inventory/GetInventoryData/${id}`,).pipe(map(response => {
                return response;
            }));
        }
        getAllProductFournisseurPricePerPage(options, requestedParams: any): Observable<any> {
            let params = new HttpParams();
            params = params.set('pageNum', (options.pageNum));
            params = params.set('pageSize', (options.pageSize));
            params = params.set('sortField', options.sortField);
            params = params.set('sortDirection', options.sortDirection);
            if (requestedParams !== null) {
                params = params.set('idProduct', requestedParams);
            }
            return this.http.get<any>(`${this.baseUrl}/products/getMultiFournisseurByProduct`, { params: params });
        }
        getQuantityVendueByCategory(id: number , options:ViewOptions , intervalDate: IntervalDateForm ): Observable<QuantiteVendueModelDto> {
            let queryParams = new HttpParams();
            queryParams = queryParams.append("pageSize", options.pageSize);
            queryParams = queryParams.append("pageNum", options.pageNum);
            if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
            if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
            if(intervalDate){
                queryParams = queryParams.append("start",moment(intervalDate.start).format('YYYY-MM-DD') );
                queryParams = queryParams.append("end",moment(intervalDate.end).format('YYYY-MM-DD') );
            }
            return this.http.get<QuantiteVendueModelDto>(`${this.baseUrl}/getQuantityVendueByCategory/${id}`, { params: queryParams });

          }
          getAllDepotVariantsPerPage(options, requestedParams: any,categoriesListId?:any): Observable<any> {
            let params = new HttpParams();
            params = params.set('pageNum', (options.pageNum).toString());
            params = params.set('pageSize', (options.pageSize).toString());
            params = params.set('sortField', options.sortField);
            params = params.set('sortDirection', options.sortDirection);
            if (requestedParams !== null) {
                params = params.set('idDepot', requestedParams);
            }
            if (categoriesListId) {
                if (categoriesListId.length>0) {
                    params = params.set('categoriesListId', categoriesListId);
                }
            }
            return this.http.get<any>(`${this.baseUrl}/depotVariant/allVariantByDepot`, { params: params });
        }
        filterForTransferDepotPerPage(options, requestedParams: any): Observable<any> {
            let params = new HttpParams();
            params = params.set('pageNum', (options.pageNum).toString());
            params = params.set('pageSize', (options.pageSize).toString());
            params = params.set('sortField', options.sortField);
            params = params.set('sortDirection', options.sortDirection);
            if (requestedParams !== null) {
                params = params.set('idDepot', requestedParams.idDepot);
            }
            if (requestedParams !== null) {
                params = params.set('reference', requestedParams.reference);
            }
            return this.http.get<any>(`${this.baseUrl}/depotVariant/filterForTransferDepot`, { params: params });
        }

    generateTransfertReport(payload){
            return this.http.post(`${this.baseUrl}/TransferRequestDetails/generateTransfertReport`,payload, {
                responseType:'blob'
            })
    }

        validateDelivery(payload):Observable<any>{
            return this.http.put<any>(`${this.baseUrl}/TransferRequestDetails/validateDelivery`, payload)

        }
        getAllRequestsDetailsBypage(options: any):Observable<any> {
            let queryParams = new HttpParams();
            queryParams = queryParams.append("pageSize", options.pageSize);
            queryParams = queryParams.append("pageNum", options.pageNum);
            if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
            if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
            return this.http.get(`${this.baseUrl}/TransferRequestDetails/getAllRequestsByPage/`, {params: queryParams});
        }
        createDetailsTransfertDepot(payload: any): Observable<any> {
            return this.http.post<any>(`${this.baseUrl}/TransferRequestDetails/createTransferDepot`, payload)
        }
        updateStatusDemande(payload: any) {
            return this.http.put(`${this.baseUrl}/TransferRequestDetails/UpdateStatus`, payload,).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }

        correctQuantityVariants(uuidPartner: string ): Observable<any> {
            let queryParams = new HttpParams();
            if(uuidPartner){
                queryParams = queryParams.append("partnerUuid",uuidPartner)
            }
            return this.http.get<any>(`${this.baseUrl}/updateQuantityForAllVariants/`, { params: queryParams });

          }
          correctComposition(uuidPartner: string ): Observable<any> {
            let queryParams = new HttpParams();
            if(uuidPartner){
                queryParams = queryParams.append("partnerUuid",uuidPartner)
            }
            return this.http.get<any>(`${this.baseUrl}/products/correctComposition`, { params: queryParams });

          }

          findAllByProductIdAndTypeTiersAndCreatedBetween(id: any, startDate: string, endDate: string, tiersType : string , priceType : string) {
            let params = new HttpParams();
            if (startDate) {
                params = params.append('startDate', startDate);
            }

            if (endDate) {
                params = params.append('endDate', endDate);
            }
            if (tiersType) {
                params = params.append('tiersType', tiersType);
            }
            if (priceType) {
                params = params.append('priceType', priceType);
            }

            return this.http.get<any>(`${this.baseUrl}/productMultiPriceTypeTiers/findAllByProductIdAndTypeTiersNameAndDate/${id}`, {
                params: params
            }).pipe(map(response => {
                return response;
              }));
            }

          getAllProductByDepotPerPage(options, idDepot,refExterne,name): Observable<any> {
            let params = new HttpParams();
            params = params.set('pageNum', (options.pageNum).toString());
            params = params.set('pageSize', (options.pageSize).toString());
            params = params.set('sortField', options.sortField);
            params = params.set('sortDirection', options.sortDirection);
            params = params.set('idDepot', idDepot);
            params = params.set('refereence', refExterne);
            params = params.set('name', name);
            return this.http.get<any>(`${this.baseUrl}/depotVariant/allProductByDepot`, { params: params });
        }


    getListProduct(options: ViewOptions, partnerUuid): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("pageSize", options.pageSize);
        queryParams = queryParams.append("pageNum", options.pageNum);
        queryParams = queryParams.append("partnerUuid", partnerUuid);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this.http.get(`${this.baseUrl}/products/getProductByUuid/page`, { params: queryParams })
    }

    getSkuImportList(options: ViewOptions): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("pageSize", options.pageSize);
        queryParams = queryParams.append("pageNum", options.pageNum);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this.http.get(`${this.baseUrl}/sku-import/getAllByPage`, { params: queryParams }).pipe(map(
            (data: IPage<any>) => {
                return data;
            }
        ))
    }
    downloadProductExcel(partnerUuid: string): Observable<Blob> {
        const params = new HttpParams().set('partnerUuid', partnerUuid);
        return this.http.get(`${this.baseUrl}/products/downloadProducts`, { params, responseType: 'blob' });
    }


    downloadHistoriqueExcel(variant? : string, depotName? : string, categoryId?:number[],startDate? : string, endDate?: string): Observable<any> {
        let params = new HttpParams();
        if(variant){
        params = params.set('variant', variant);
        }
        if(depotName){
        params = params.set('depotName', depotName);
        }
        if(startDate){
        params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if(endDate){
        params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }

        if(categoryId && categoryId.length>0){
            params = params.set('parentCategory', categoryId.join(','));
        }

        return this.http.get(`${this.baseUrl}/depot-history/downloadHistoric`, { params: params ,responseType: 'blob' as 'json' });
    }
    uploadExcelFile(file: File,depot : any,partnerUuid :any): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('UuidDepot', depot);
        formData.append('UuidPartner', partnerUuid);
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        return this.http.post(`${this.baseUrl}/products/import`, formData, { headers: headers, responseType: 'text' })
    }


    getAllAdminDepotsForSystem(partnerUuid): Observable<any> {
        return this.http.get<Array<any>>(`${this.baseUrl}/depot/getAllDepotsForSystem/${partnerUuid}`
        ).pipe(map(response => {
            return response;
        }));
    }

    deleteAllData(payload): Observable<any> {
        return this.http.post<Array<any>>(`${this.baseUrl}/delete-data/all/`, payload
        ).pipe(map(response => {
            return response;
        }));
    }


    uploadFile(file: File, depotId: number, categoryIds: number[]) {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('depotId', depotId.toString());
        categoryIds.forEach(categoryId => {
            formData.append('categoryIds', categoryId.toString());
        });
        return this.http.post(`${this.baseUrl}/depotVariant/upload`, formData);
    }



    getBestGainByCategories(categoryId: number): Observable<any> {
        let params = new HttpParams().set('categoryId', categoryId);
        return this.http.get<any>(`${this.baseUrl}/variants/getBestGainsByCategory`, { params })
            .pipe(map(response => {
                return response;
            }));
    }

    getBestSaleByCategory(categoryId: number): Observable<any> {
        let params = new HttpParams().set('categoryId', categoryId);
        return this.http.get<any>(`${this.baseUrl}/variants/getBestSaleByCategory`, { params })
            .pipe(map(response => {
                return response;
            }));
    }

    generateCodeFromAchat(customer: any , variantsUuids:any): Observable<Blob> {
          let params = new HttpParams().set('customer', customer).set('variantsUuids', variantsUuids);
        return this.http.get(`${this.baseUrl}/code/generateCodefromAchat`, {
            params: params,
            responseType: 'blob'
        });

    }


    generateStockReport(idDepot: number, year: number, categoryId : number): Observable<Blob> {
        let params = new HttpParams()
            .set('idDepot', idDepot)
            .set('year', year)
        if (categoryId !== undefined) {
            params = params.set('categoryId', categoryId.toString());
        }
        return this.http.get(`${this.baseUrl}/depotVariant/generateStockPdf`, {
            params: params,
            responseType: 'blob'
        });
    }

    getArrivingQuantity(variantUuid: string): Observable<number> {
        return this.http.get<number>(`${this.baseUrl}/depotVariant/arriving-quantity`, { params: { variantUuid } });
    }

    downloadProductExcelFromFilter(searchFields: { categoryId: number; name: string; reference: string; minPrice: number; maxPrice: number; category: any; productType: string; isArchived: boolean; }) {
        if(searchFields.productType){
            searchFields.productType = searchFields.productType;
        }
        if(searchFields.isArchived){
            searchFields.isArchived = searchFields.isArchived;
        }
        return this.http.get(`${this.baseUrl}/products/downloadProductExcelByFilter`, { params: this.setParams(searchFields) , responseType: 'blob' });
      }


    genererSkuFromVariant(variant: any , quantity,withPrice):Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("withPrice",withPrice)
        queryParams = queryParams.append("variant", variant);
        queryParams = queryParams.append("quantity", quantity);
        return this.http.get(`${this.baseUrl}/code/generateCodefromVariant`, { params: queryParams, responseType: 'blob' });
    }

    genererSkus(element):Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("id", element.id);
        queryParams = queryParams.append("pdfGenerationType", element.pdfGenerationType)
        return this.http.get(`${this.baseUrl}/code/generateSkus`, { params: queryParams, responseType: 'blob' });
    }

    printDeliveryPdf(uuid, optionType?):Observable<any> {

        let params = new HttpParams();
        params = params.set('deliveryUuid', uuid);
        if (optionType) {
            params = params.set('optionType', optionType);
        }
        return this.http.get(`${this.baseUrl}/code/printDeliveryPdf`, {params: params, responseType: 'blob'})

    }

    saveDataOrders(ResponseData: any) {
        return this.http.post(`${this.baseUrl}/externalOrders/saveData`, ResponseData,).pipe(
            map((response: any) => {
                return response;
            })
        );

    }
    getAllOrdersByPage(options: any ):Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("pageNum", options.pageNum);
        queryParams = queryParams.append("pageSize", options.pageSize);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this.http.get(`${this.baseUrl}/externalOrders/getAllByPage`, {params: queryParams});
    }
    filterByPage(options: any, searchFields: any): Observable<any> {
        let queryParams = new HttpParams();

        if (searchFields.status) {
            queryParams = queryParams.append("status", searchFields.status);
        }
        if (searchFields.substatus) {
            queryParams = queryParams.append("substatus", searchFields.substatus);
        }
        if (searchFields.startDate) {
            queryParams = queryParams.set('startDate', moment(searchFields.startDate).format('YYYY-MM-DD'));
        }
        if (searchFields.endDate) {
            queryParams = queryParams.set('endDate', moment(searchFields.endDate).format('YYYY-MM-DD'));
        }
        queryParams = queryParams.append("pageNum", options.pageNum);
        queryParams = queryParams.append("pageSize", options.pageSize);

        if (options.sortField) {
            queryParams = queryParams.append("sortField", options.sortField);
        }
        if (options.sortDirection) {
            queryParams = queryParams.append("sortDirection", options.sortDirection);
        }

        return this.http.get(`${this.baseUrl}/externalOrders/filterByPage`, { params: queryParams });
    }


    downloadServiceExcelFromFilter(searchFields: {  nom: string; reference:string}) {
        return this.http.get(`${this.baseUrl}/services/downloadServiceExcelByFilter`, { params: this.setParams(searchFields) , responseType: 'blob' });
    }

    uploadExcelFileNewService(selectedFile: any) {
        const formData: FormData = new FormData();
        formData.append('file', selectedFile, selectedFile.name);
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        return this.http.post(`${this.baseUrl}/services/importServiceForPartner`, formData, {
            headers: headers,
            responseType: 'text'
        })
    }
    checkSerialNumbersExist(serialNumber: number): Observable<boolean> {
        let params = new HttpParams()
            .set('serialNumber', serialNumber)
        return this.http.post<boolean>(`${this.baseUrl}/sku/check-serial-number`, null, { params });
    }

    createReparation(reparationDto:ReparationModel): Observable<ReparationModel> {
            return this.http
                .post<ReparationModel>(`${this.baseUrl}/reparation`,reparationDto)
                .pipe(map(response => {
                return response;
            }));
    }

    getPackageElementsbyId(id: any): Observable<any> {
        return this.http.get<Array<PackageElementDto>>(`${this.baseUrl}/services/package?id=${id}`).pipe(
            map(response => {
                return response;
            })
        );
    }
    deletepackage(id:any){
        return this.http.delete(`${this.baseUrl}/services/deletepackage/${id}`)
    }
    addPackageElement(uuid: string, serviceDto: ServiceModelDto): Observable<PackageElementDto> {

        return this.http.post<PackageElementDto>(`${this.baseUrl}/services/addpackageelement/${uuid}`, serviceDto, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        });
    }

    sendProductsImages(images: ImgsData[], batchSize: number, partnerUuid: string) {
        const uploadBatch = (batch: ImgsData[], batchIndex: number) => {
            const formData = new FormData();
            batch.forEach((image) => {
                const hexString = image.imageHex.startsWith('_binary 0x')
                    ? image.imageHex.slice(10)
                    : image.imageHex;
                const binaryData = new Uint8Array(
                    hexString.match(/.{1,2}/g)!.map((byte) => parseInt(byte, 16))
                );
                const file = new File(
                    [binaryData],
                    `${image.name}${image.extension}`,
                    { type: `image/${image.extension}` }
                );
                formData.append("images", file);
                formData.append("uuidPartner", partnerUuid);
            });
            return this.http.post(`${this.baseUrl}/products/productImages`, formData).pipe(
                map((response) => response),
                catchError((error) => {
                    console.error(`Error uploading batch ${batchIndex}:`, error);
                    return of(null);
                })
            );
        };

        const requests = [];
        for (let i = 0; i < images.length; i += batchSize) {
            const batch = images.slice(i, i + batchSize);
            requests.push(uploadBatch(batch, i / batchSize));
        }

        return requests.reduce((prev, curr) => {
            return prev.pipe(
                switchMap(() => curr)
            );
        }, of(null));
    }
    getSimpleServicesBypage(payload: any): Observable<any> {

        let params = new HttpParams()
            .set('pageNum', payload.pageNum)
            .set('pageSize', payload.pageSize)
            .set('sortField', payload.sortField)
            .set('sortDirection', payload.sortDirection)
            .set('serviceType', payload.serviceType);

        return this.http.get(`${this.baseUrl}/services/getsimpleserviceByPage`, { params: params });
    }

    checkInventoryNameExists(name: string) {
        return this.http.get<any>(`${this.baseUrl}/inventory/existsByName`, { params: { name } }).pipe(
            map(response => {
                return response;
            })
        );
    }

    createPackage(subject: any, imageFile?: File): Observable<any> {
        const formData = new FormData();
        formData.append('requestString', JSON.stringify(subject));

        if (imageFile) {
            formData.append('image', imageFile);
        }

        return this.http.post(`${this.baseUrl}/services/package`, formData).pipe(
            map(response => {
                return response;
            })
        );
    }


    getProductsByType(type: TypeProductEnum, filter: { name?: string; reference?: string }, option: { pageNum: number; pageSize: number; sortField: string; sortDirection: string }): Observable<any> {
        let params = new HttpParams()
            .set('type', type)
            .set('pageNum', option.pageNum)
            .set('pageSize', option.pageSize)
            .set('sortField', option.sortField)
            .set('sortDirection', option.sortDirection);

        if (filter.name) {
            params = params.set('name', filter.name);
        }

        if (filter.reference) {
            params = params.set('reference', filter.reference);
        }

        return this.http.get(`${this.baseUrl}/products/get-products-by-type`, { params: params });
    }

    getServicesByType(
        serviceType: ServiceTypeEnum,
        filter: { name?: string; reference?: string },
        option: { pageNum: number; pageSize: number; sortField: string; sortDirection: string }
    ): Observable<any> {
        let params = new HttpParams()
            .set('serviceType', serviceType)
            .set('pageNum', option.pageNum)
            .set('pageSize', option.pageSize)
            .set('sortField', option.sortField)
            .set('sortDirection', option.sortDirection);

        if (filter.name) {
            params = params.set('name', filter.name);
        }

        if (filter.reference) {
            params = params.set('reference', filter.reference);
        }

        return this.http.get(`${this.baseUrl}/services/get-services-by-type`, { params: params });
    }


    getUsersActivities(page: number, size: number) {
        let params = new HttpParams()
        .set('page', page)
        .set('size', size)
        return this.http.get<any>(`${this.baseUrl}/Trigger-log-stock/get-all-activity-by-user-and-date` , { params: params });
    }
    private selectedTabIndex: number = 0;

    setSelectedTab(index: number) {
        this.selectedTabIndex = index;
    }

    getSelectedTab(): number {
        return this.selectedTabIndex;
    }

    printBarcodePdf(barCode,name,quantity,price):Observable<any> {
        let params = new HttpParams();
        if(price!=null){
            params = params.set('price', price);
        }
        params = params.set('barCode', barCode);
        params = params.set('name', name);
        params = params.set('quantity', quantity);
        return this.http.get(`${this.baseUrl}/products/generateBarCodePdf`, {params: params, responseType: 'blob'})

    }

    getAllProductOptions(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/product_measurements/getAll`).pipe(map(response => {
            return response;
        }));
    }

    createVehicleMaintenance(payload: any) {
        return this.http.post(`${this.baseUrl}/vehicleMaintenance`, payload);
    }
    updateVehicleMaintenance(id: number, vehicleMaintenanceDtoForAdd: any): Observable<any> {
        return this.http.put(`${this.baseUrl}/vehicleMaintenance/update/${id}`, vehicleMaintenanceDtoForAdd);
    }

    getAllVehicleMaintenancePerPage(options: ViewOptions): Observable<any> {
        let params = new HttpParams();
        params = params.set('pageSize', options.pageSize);
        params = params.set('pageIndex', options.pageNum);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);
        return this.http.get<any>(`${this.baseUrl}/vehicleMaintenance/perPage`, { params: params })
    }

    findAllByVidangeApproved(options: ViewOptions ,carName: string , matricule : string , startDate : string ,endDate : string , vidangeApproved : boolean): Observable<any> {
        let params = new HttpParams();
        if (startDate && endDate) {
            params = params.set('startDate', startDate);
            params = params.set('endDate', endDate);
        }
        if (carName) (params = params.append("carName", carName));
        if (matricule) (params = params.append("client", matricule));
        params = params.set('vidangeApproved', vidangeApproved);
        params = params.set('pageSize', options.pageSize);
        params = params.set('pageIndex', options.pageNum);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);
        return this.http.get<any>(`${this.baseUrl}/vehicleMaintenance/approvedPerPage`, { params: params })
    }
    updateVidangeStatus(id: number): Observable<VehicleMaintenanceModel> {
        return this.http.put<VehicleMaintenanceModel>(`${this.baseUrl}/vehicleMaintenance/${id}`, { });
    }

    getAllInsurancesExpiringSoon(options: ViewOptions,carName?:string, carNumber?:string,insuranceCompany?:string, startDate?: string, endDate?: string): Observable<any> {
        let params = new HttpParams();
        if (startDate && endDate) {
            params = params.set('startDate', startDate);
            params = params.set('endDate', endDate);
        }
        if (carName) (params = params.append("carName", carName));
        if (carNumber) (params = params.append("carNumber", carNumber));
        if (insuranceCompany) (params = params.append("insuranceCompany", insuranceCompany));


        params = params.set('pageSize', options.pageSize);
        params = params.set('pageIndex', options.pageNum);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);
        return this.http.get<any>(`${this.baseUrl}/assurance/expiring-soon`, { params: params });
    }
    updateInsurance(payload:Assurance): Observable<any> {
        return this.http.put<Assurance>(`${this.baseUrl}/assurance/update`, {});
    }
    getAllInsurances(options: ViewOptions,carName?:string, carNumber?:string,insuranceCompany?:string, startDate?: string, endDate?: string): Observable<any> {
        let params = new HttpParams();
        if (startDate && endDate) {
            params = params.set('startDate', startDate);
            params = params.set('endDate', endDate);
        }
        if (carName) (params = params.append("carName", carName));
        if (carNumber) (params = params.append("carNumber", carNumber));
        if (insuranceCompany) (params = params.append("insuranceCompany", insuranceCompany));

        params = params.set('pageSize', options.pageSize);
        params = params.set('pageIndex', options.pageNum);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);
        return this.http.get<any>(`${this.baseUrl}/assurance/all`, { params: params });
    }

    createVehicleInsurance(payload: any) {
        return this.http.post(`${this.baseUrl}/assurance/create`, payload);
    }
    updateVehicleInsurance(payload: any) {
        return this.http.put(`${this.baseUrl}/assurance/update`, payload);
    }

    getProductNamesAndRef(): Observable<{ id:number,uuid:string, ref: string, name: string }[]> {
        return this.http.get<{id:number,uuid:string, ref: string, name: string }[]>(`${this.baseUrl}/products/get-products-list-ref-names`);
    }

    getAllInsurance() {
        return this.http.get<any>(`${this.baseUrl}/assurance/allInsurances`, {});
    }

}
