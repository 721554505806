export const APP_PREFIX = 'WIND';
export const GLOBAL_AJAX_TIMEOUT = 3000;
export const ME_TOO_CLIENT_PACK = "7bfd8fdd-6a2b-43b6-816d-182270e6c01c";
export const FUTUR_SAT_CLIENT_PACK = "4a2545ba-6cce-4c06-a097-2729e6ba850b";
export const MAJ_CLIENT_PACK = "17f5d2a4-fb0e-4718-aa19-62363e317479";
export const ENTREPRISE_MA_PACK = "2c5c9eb8-2d75-435b-883f-e87dac784479";
export const SYSTEM_UUID = "2d1ebc5b-7d27-4197-9cf0-e84451c5bbb2";
export const SEARCH_INITIATED = 'SEARCH_INITIATED';
export const SEARCH_TERMINATED = 'SEARCH_TERMINATED';
export const SKIP_AUTH_INTERCEPTOR_HEADER = {name: 'X-Skip-Interceptor', value: '*'};
export const TOKEN_REFRESH_HEADER = {name: 'X-Token-Refresh', value: ''};
export const AUTHORISATION_BEARER_HEADER = {name: 'Authorization', value: 'Bearer '};
export const AUTHENTICATION_KEY = APP_PREFIX + '_AUTH';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_USER = 'ROLE_USER';
export const APIkEY="f2c9b8a7-4d3e-44e9-bf96-1a77aeb1a255";
export const GOUVERNEMENTS = [
    'Ariana', 'Béja', 'Ben Arous', 'Bizerte', 'Gabès', 'Gafsa', 'Jendouba', 'Kairouan',
    'Kasserine', 'Kébili', 'Kef', 'Mahdia', 'Manouba', 'Médenine', 'Monastir', 'Nabeul',
    'Sfax', 'Sidi Bouzid', 'Siliana', 'Sousse', 'Tataouine', 'Tozeur', 'Tunis', 'Zaghouan'
].map(gouvernement => ({
    label: gouvernement,
    value: gouvernement
}));
export const TYPE_ACHAT = [{
    label: 'Produit',
    value: 'Produit'
}, {
    label: 'Service',
    value: 'Service'
}, {
    label: 'Produit et Service',
    value: 'produit_service'
}]
export const STE_LIV = [
    {
        label: 'Sans Colis',
        value: false
    },
    {
        label: 'Avec Colis',
        value: true
    }]