import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';
import { firstValueFrom, Subscription } from 'rxjs';

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl implements OnDestroy {
  override itemsPerPageLabel = '';
    private langChangeSubscription: Subscription;

    constructor(private translocoService: TranslocoService) {
    super();
        this.translateLabel();

        this.langChangeSubscription = this.translocoService.langChanges$.subscribe(async () => {
            await this.translateLabel();
            this.changes.next();
    });
  }
    
    ngOnDestroy(): void {
        if (this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
    }

    async translateLabel() {
        this.itemsPerPageLabel = await firstValueFrom(this.translocoService.selectTranslate('commun.itemsPerPage'));
  }
}
