import { RoleEnum } from "app/shared/enums/general/roleEnum";

export const noUrlRedirect = (): string => {
    const role = localStorage.getItem('role')
    if (role === RoleEnum.ROLE_SYSTEM) {
        return '/system/Partenaires';
    }
    else  if (role === RoleEnum.ROLE_ADMIN) {
        return '/admin/Entreprise';
    }
    return '/employee/profile';
}
