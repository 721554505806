import {Devise} from "../builling/devise";

export const ListDevises : Devise[] = [
    {
        label  : 'Dinar Tunisien',
        value : 'TND',
        icon : 'TND'
    },
    {
        label  : 'Dollar Américain',
        value : 'USD',
        icon : 'DOLLAR'
    },
    {
        label  : 'Euro',
        value : 'EUR',
        icon : 'EURO'
    }
]
