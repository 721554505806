import { Injectable ,EventEmitter  } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})

export class NotificationsService
{    notificationUrl = environment.notificationUrl
    private _notifications: ReplaySubject<Notification[]> = new ReplaySubject<Notification[]>(1);
    notificationMessage = new EventEmitter();
    notificationCount = new EventEmitter();

    private _notificationCount = new BehaviorSubject<number>(0);
    notificationCount$ = this._notificationCount.asObservable();

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }


    updateNotificationCount(count: number): void {
        this._notificationCount.next(count);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<any>
    {
        return this._notifications.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */
    getAll(uuid:any): Observable<any>
    { let params = new HttpParams();
        params = params.append('receiver', uuid);
        return this._httpClient.get<any>(this.notificationUrl + "/notification/getNotifications",{ params: params}).pipe(


            tap((notifications) => {
                this._notifications.next(notifications);
            })
        );
    }

    getAllWithContext(uuid:any): Observable<any>
    { let params = new HttpParams();
        params = params.append('receiver', uuid);
        return this._httpClient.get<any>(this.notificationUrl + "/notification/receiver/getNotifications",{ params: params}).pipe(
            tap((notifications) => {
                this._notifications.next(notifications);
            })
        );
    }


    /**
     * Create a notification
     *
     * @param notification
     */
    create(notification: Notification): Observable<Notification>
    {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.post<Notification>('api/common/notifications', {notification}).pipe(
                map((newNotification) => {

                    // Update the notifications with the new notification
                    this._notifications.next([...notifications, newNotification]);

                    // Return the new notification from observable
                    return newNotification;
                })
            ))
        );
    }

    /**
     * Update the notification
     *
     * @param uuid
     * @param notification
     */
    update(uuid: any ,status :any): Observable<any>
    {
        let params = new HttpParams();
        params = params.append('uuid', uuid);
        params = params.append('status', status);

        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.post<any>(this.notificationUrl + "/notification/mark", {},{params}).pipe(
                map((updatedNotification: any) => {

                    // Find the index of the updated notification
                    const index = notifications.findIndex(item => item.uuid === uuid);

                    // Update the notification
                    notifications[index] = updatedNotification;

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the updated notification
                    return updatedNotification;
                })
            ))
        );
    }



    /**
     * Delete the notification
     *
     * @param id
     */
    delete(id: string): Observable<boolean>
    {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.delete<boolean>('api/common/notifications', {params: {id}}).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted notification
                    const index = notifications.findIndex(item => item.id === id);

                    // Delete the notification
                    notifications.splice(index, 1);

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the deleted status
                    return isDeleted;
                })
            ))
        );
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): Observable<boolean>
    {
        let uuid = localStorage.getItem('useruuid')
        let params = new HttpParams();
        params = params.append('receiverUuid', uuid);

        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.post<any>(this.notificationUrl + "/notification/markAllAsRead",{},{params}).pipe(
                map((isUpdated: any) => {
                    this._notifications.next(notifications);
                    return isUpdated;
                })
            ))
        );
    }

    markNotificationAsRead(): Observable<boolean>
    {
        let uuid = localStorage.getItem('useruuid')
        let params = new HttpParams();
        params = params.append('receiverUuid', uuid);

        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.post<any>(this.notificationUrl + "/notification/markNotificationAsRead",{},{params}).pipe(
                map((isUpdated: any) => {
                    this._notifications.next(notifications);
                    return isUpdated;
                })
            ))
        );
    }
}
