import { Injectable } from '@angular/core';
import { environment } from "../../../../../environments/environment";
import { BehaviorSubject, catchError, Observable } from "rxjs";
import { map, timeout } from "rxjs/operators";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { PayrollNumberResult } from 'app/shared/models/payroll/payrollnumber';
import { DevisModel } from "../../../models/builling/DevisModel";
import { BonCommandModel } from 'app/shared/models/builling/BonCommandeModel';
import { BonLivraisonModel } from 'app/shared/models/builling/BonLivraisonModel';
import { FactureModel } from 'app/shared/models/builling/FactureModel';
import { ViewOptions } from 'app/components/admin/view-options';
import moment from "moment/moment";
import {CaisseSystemModel} from "../../../models/Invoice/caisse-system.model";
import {BonLivraisonListDtoModel} from "../../../models/builling/BonLivraisonListDtoModel";
import {BillTemplateModel} from "../../../models/builling/BillTemplateModel";
import {BillTemplateDto} from "../../../models/builling/BillTemplateDto";
import { LineModel } from 'app/shared/models/Invoice/line.model';
import { TransactionsPageResponseDto } from 'app/shared/models/builling/TransactionsPageResponseDto';
import {UpdateInvoiceModel} from "../../../models/Invoice/UpdateInvoiceModel.model";
import { CounterModel } from 'app/shared/models/builling/CounterModel';
import { GenericPayloadUpdate } from 'app/shared/models/builling/GenericPayloadUpdate';
import {SubscriptionModel} from "../../../models/stock/summaryModel";
import { ProductGroupDto } from 'app/shared/models/builling/ProductGroupDto';
import { PaymentReparationModel } from 'app/shared/models/builling/PaymentReparationModel';

@Injectable({
    providedIn: 'root'
})
export class BuillingService {
    public selectedIndex: number = 0;
    billingnumber: PayrollNumberResult;
    baseUrl = environment.buillingUrl
    private DataSubject = new BehaviorSubject<any>(null);
    private DataFormSubject = new BehaviorSubject<any>(null);
    private DataTotalAmount = new BehaviorSubject<any>(null);


    constructor(private _httpClient: HttpClient) {
    }


    setExampleData(data: any) {
        this.DataSubject.next(data);
    }

    totalAmountData$ = this.DataTotalAmount.asObservable();

    setTotalAmountData(data: any) {
        this.DataTotalAmount.next(data);
    }

    exampleDataForm$ = this.DataFormSubject.asObservable();

    setExampleForm(data: any) {
        this.DataFormSubject.next(data);
    }

    getAllDevisByType(payload) {

        return this._httpClient.post<DevisModel[]>(`${this.baseUrl}/devis/getAllDevisByTypeForSystem`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return [];
            }),
            map((response: any) => {
                return response;
            })
        );
    }


    getAllPurshasesByTypeForSystem(payload){
        return this._httpClient.post<any>(`${this.baseUrl}/purshaseorder/getAllPurshasesByTypeForSystem`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    payReparation(reparationPaymentDto:PaymentReparationModel): Observable<PaymentReparationModel> {
            return this._httpClient
                .post<PaymentReparationModel>(`${this.baseUrl}/reparation-transaction/pay-reparation`,reparationPaymentDto)
                .pipe(map(response => {
                return response;
            }));
    }

    getAllDeliveryByTypeForSystem(payload){
        return this._httpClient.post<any>(`${this.baseUrl}/delivery-form/getAllDeliveryByTypeForSystem`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    getAllInvoicesByTypeForSystem(payload){
        return this._httpClient.post<any>(`${this.baseUrl}/invoice/getAllInvoicesByTypeForSystem`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    getTotalCostBcAndBdl(params: {
        options: any;
        refFacture?: any;
        devisRef?: any;
        livraisonRef?:any;
        refBL? : any
        commandeRef?: any;
        paymentStatus?: any;
        reference?: any;
        customer?: any;
        typeTransaction?: any;
        typeModule?: any;
        status?: any;
        startDate?: string;
        endDate?: string;
        type ? : string;
        steLivraison? : boolean;
    }): Observable<any> {
        let httpParams = new HttpParams();

        const setIfNotNull = (key: string, value: any) => {
            if (value != null) {
                httpParams = httpParams.set(key, value);
            }
        };

        setIfNotNull('refFacture', params.refFacture);
        setIfNotNull('devisRef', params.devisRef);
        setIfNotNull('livraisonRef', params.livraisonRef);
        setIfNotNull('commandeRef', params.commandeRef);
        setIfNotNull('refBL', params.refBL);
        setIfNotNull('paymentStatus', params.paymentStatus);
        setIfNotNull('reference', params.reference);
        setIfNotNull('customer', params.customer);
        setIfNotNull('typeTransaction', params.typeTransaction);
        setIfNotNull('typeModule', params.typeModule);
        setIfNotNull('status', params.status);
        setIfNotNull('startDate', params.startDate ? moment(params.startDate).format('YYYY-MM-DD') : null);
        setIfNotNull('endDate', params.endDate ? moment(params.endDate).format('YYYY-MM-DD') : null);
        setIfNotNull('type', params.type);
        setIfNotNull('hasLivraison', params.steLivraison);
        // Pagination and sorting
        setIfNotNull('pageNum', params.options.pageIndex);
        setIfNotNull('pageSize', params.options.pageSize);
        setIfNotNull('sortDirection', params.options.sortDirection);
        setIfNotNull('sortField', params.options.sortField);

        return this._httpClient
        .get<any>(`${this.baseUrl}/purshaseorder/getTotalCostBcAndBdl`, { params: httpParams })
        .pipe(map(response => response));
    }
    getInvoicesInCaisseSystem(options? : any ,partner?: string , refInvoice? : string , paymentStatus? : string , startDate? : any , endDate? : any ) : Observable<CaisseSystemModel> {
        let queryParams = new HttpParams();
        if(partner){
            queryParams = queryParams.append("partner",partner);
        }
        if(refInvoice){
            queryParams = queryParams.append("refInvoice",refInvoice);
        }
        if(paymentStatus){
            queryParams = queryParams.append("paymentStatus",paymentStatus);
        }
        if (startDate) {
            queryParams = queryParams.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            queryParams = queryParams.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        queryParams = queryParams.set('pageIndex', options.pageNum);
        queryParams = queryParams.set('pageSize', options.pageSize);
        queryParams = queryParams.set('sortDirection', options.sortDirection);
        queryParams = queryParams.set('sortField', options.sortField);
        return this._httpClient.get(`${this.baseUrl}/invoice/getALLFactureSystem`, {params:queryParams}).pipe(

            map((response: any) => {
                return response;
            })
        );
    }


    getAllBonCommandByType(transactionType: any, moduleType: any): Observable<any> {

        return this._httpClient.get<any>(`${this.baseUrl}/purshaseorder/getAllBytenantId/${transactionType}/${moduleType}`).pipe(map(response => {
            return response;
        }));
    }

    getAllAvancesPerTier(tierUuid: string): Observable<any> {

        return this._httpClient.get<any>(`${this.baseUrl}/advance/getAllAvancesPerTier/${tierUuid}`).pipe(map(response => {
            return response;
        }));
    }

    getAllFactureByType(transactionType: any, moduleType: any) {
        return this._httpClient.get(`${this.baseUrl}/invoice/getAllInvoiceyByType/${transactionType}/${moduleType}`).pipe(
            timeout(60000),
            catchError((e, c) => {
                return []; // only for catch error to work, it requires a return of array, object..
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    filterBonCommande(devisRef: any, typeTransaction: any, typeModule: any, refBonCommand: any, customer: any) {
        return this._httpClient.get<any>(`${this.baseUrl}/purshaseorder/filterBonCommandeByDevis`, {params: this.setParamsBonCommande(devisRef, typeTransaction, typeModule, refBonCommand, customer)}).pipe(map(response => {
            return response;
        }));
    }

    filterBonCommandePagebale(options: any, reference: any, customer: any, typeTransaction: any, typeModule: any,status :any, startDate?: string, endDate?: string,typeAchat?: string) {

        //filterBonCommandePagebale(options: any, reference: any, customer: any, typeTransaction: any, typeModule: any,status :any) {
        let params = new HttpParams();
        if (reference != null) {
            params = params.set('reference', reference);
        }


        if (customer != null) {
            params = params.set('customer', customer);
        }
        if (typeTransaction != null) {
            params = params.set('typeTransaction', typeTransaction);
        }

        if (typeModule != null) {
            params = params.set('typeModule', typeModule);
        }

        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        if (typeAchat) {
            params = params.set('typeAchat', typeAchat);
        }
        params = params.set('status',status );
        params = params.set('pageNum', options.pageIndex);
        params = params.set('pageSize', options.pageSize);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);

        return this._httpClient.get<any>(`${this.baseUrl}/purshaseorder/filterBonCommandePageable`, {params: params}).pipe(map(response => {
            return response;
        }));
    }

    filterBonLivraison(devisRef: any, commandeRef, typeTransaction: any, typeModule: any) {
        return this._httpClient.get<any>(`${this.baseUrl}/delivery-form/filterBonLivraison`, {params: this.setParamsBonLivraison(devisRef, commandeRef, typeTransaction, typeModule)}).pipe(map(response => {
            return response;
        }));
    }


    filterBonLivraisonPagebale(options: any, paymentStatus: any, refBL : any , customer: any, devisRef: any, commandeRef: any, typeTransaction: any, typeModule: any, startDate?: string, endDate?: string,type?:string,steLivraison? : boolean) {
        let params = new HttpParams();
        if (refBL != null) {
            params = params.set('refBL', refBL);
        }

        if (devisRef != null) {
            params = params.set('devisRef', devisRef);
        }

        if (paymentStatus != null) {
            params = params.set('paymentStatus', paymentStatus);
        }
        if (customer != null) {
            params = params.set('costumer', customer);
        }

        if (commandeRef != null) {
            params = params.set('commandeRef', commandeRef);
        }

        if (typeTransaction != null) {
            params = params.set('typeTransaction', typeTransaction);
        }

        if (typeModule != null) {
            params = params.set('typeModule', typeModule);
        }
        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        if (type) {
            params = params.set('type',type);
        }
        if (steLivraison!=null) {
            params = params.set('hasLivraison',steLivraison);
        }
        params = params.set('pageNum', options.pageIndex);
        params = params.set('pageSize', options.pageSize);
        params = params.set('sortField', options.sortField);
        params = params.set('sortDirection', options.sortDirection);
        return this._httpClient.get<any>(`${this.baseUrl}/delivery-form/filterBonLivraison`, {params: params}).pipe(map(response => {
            return response;
        }));
    }

    filterTareTrackPagebale(options: any,  customer?: string,tare?: string,sens?: string,startDate?: string, endDate?: string) {
        let params = new HttpParams();

        if (tare != null) {
            params = params.set('tare', tare);
        }
        if (customer != null) {
            params = params.set('costumer', customer);
        }
        if (sens != null) {
            params = params.set('sens', sens);
        }

        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }

        params = params.set('pageNum', options.pageNum);
        params = params.set('pageSize', options.pageSize);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);

        return this._httpClient.get<any>(`${this.baseUrl}/tare-track/filterTareTrack`, {params: params}).pipe(map(response => {
            return response;
        }));
    }




    filterFacturePagebale(
        options: any,
        refFacture: any,
        devisRef: any,
        commandeRef: any,
        paymentStatus: any,
        customer: any,
        typeTransaction: any,
        typeModule: any,
        livraisonRef: any,
        realNumber: any,
        startDate?: string,
        endDate?: string,
        type? : string,
    ) {
        let params = new HttpParams();

        if (refFacture != null) {
            params = params.set('refFacture', refFacture);
        }
        if (devisRef != null) {
            params = params.set('devisRef', devisRef);
        }

        if (paymentStatus != null) {
            params = params.set('paymentStatus', paymentStatus);
        }

        if (livraisonRef != null) {
            params = params.set('livraisonRef', livraisonRef);
        }

        if (customer != null) {
            params = params.set('costumer', customer);
        }

        if (commandeRef != null) {
            params = params.set('commandeRef', commandeRef);
        }

        if (typeTransaction != null) {
            params = params.set('typeTransaction', typeTransaction);
        }

        if (typeModule != null) {
            params = params.set('typeModule', typeModule);
        }

        if (realNumber != null) {
            params = params.set('realNumber', realNumber);
        }

        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }

        if (endDate) {
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        if (type != null) {
            params = params.set('typeAchat', type);
        }
        params = params.set('pageNum', options.pageIndex);
        params = params.set('pageSize', options.pageSize);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);

        return this._httpClient.get<any>(`${this.baseUrl}/invoice/filterInvoice`, { params: params }).pipe(
            map((response) => {
                return response;
            })
        );
    }


    /* filterInvoicePagebale(pageOptions: any, searchFields) {
       let params = new HttpParams();
       params = params.append("pageSize", pageOptions.pageSize);
       params = params.append("pageIndex", pageOptions.pageIndex);
       if (pageOptions.sortDirection) params = params.append("sortDirection", pageOptions.sortDirection);
       if (pageOptions.sortField) params = params.append("sortField", pageOptions.sortField);
       if (searchFields.tierName) params = params.append("tierName", searchFields.tierName);
       if (searchFields.typeTier) params = params.append("typeTier", searchFields.typeTier);
       if (searchFields.startDate != null) {
         params = params.append("startDate", moment(searchFields.startDate).format('YYYY-MM-DD'))
       }
       if (searchFields.endDate != null) {
         params = params.append("endDate", moment(searchFields.endDate).format('YYYY-MM-DD'))
       }
       return this._httpClient.get<any>(`${this.baseUrl}/invoice/getFilteredInvoicePage`, { params: params }).pipe(map(response => {
         return response;
       }));
     }
     filterFacture(devisRef: any, commandeRef: any, typeTransaction: any, typeModule: any) {
       return this._httpClient.get<any>(`${this.baseUrl}/invoice/filterInvoice`, { params: this.setParams(devisRef, commandeRef, typeTransaction, typeModule) }).pipe(map(response => {
         return response;
       }));
     }*/

    private setParamsBonLivraison(devisRef: any, commandeRef: any, typeTransaction: any, typeModule: any): HttpParams {

        let params = new HttpParams();
        if (devisRef !== '') {
            params = params.set('devisRef', devisRef);
        }
        if (commandeRef !== '') {
            params = params.set('commandeRef', commandeRef);
        }
        if (typeTransaction !== '') {
            params = params.set('typeTransaction', typeTransaction);
        }
        if (typeModule !== '') {
            params = params.set('typeModule', typeModule);
        }
        return params;
    }

    private setParamsBonCommande(devisRef: any, typeTransaction: any, typeModule: any, refBonCommand: any, customer): HttpParams {

        let params = new HttpParams();
        if (devisRef !== null) {
            params = params.set('devisRef', devisRef);
        }
        if (typeTransaction !== null) {
            params = params.set('typeTransaction', typeTransaction);
        }
        if (typeModule !== null) {
            params = params.set('typeModule', typeModule);
        }
        if (typeModule !== null) {
            params = params.set('refBonCommand', refBonCommand);
        }
        if (typeModule !== null) {
            params = params.set('customer', customer);
        }
        return params;
    }

    createInvoice(payload: any, factType?: string) {
        let url = `${this.baseUrl}/invoice/`;
        if (factType) {
            url += `?factType=${factType}`
        }
        return this._httpClient.post(url, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    getDataEditGeneralFactureSP(uuidInvoice: any): Observable<any> {
        return this._httpClient.get<any>(`${this.baseUrl}/invoice/getDataEditGeneralFactureSP/${uuidInvoice}`).pipe(map(response => {
            return response;
        }));
    }

    addGeneralInvoiceForSp(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/invoice/addGeneralInvoiceForSp`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    editGeneralInvoiceSP(payload: any,uuidInvoice:any) {
        return this._httpClient.put(`${this.baseUrl}/invoice/editGeneralInvoiceSP/${uuidInvoice}`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    addClientAndFournisseurInvoicesForSp(payload: any,uuidGeneralInvoice:any) {
        return this._httpClient.post(`${this.baseUrl}/invoice/addClientAndFournisseurInvoicesForSp/${uuidGeneralInvoice}`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    createInvoiceWithPurshase(payload: any, depotUuid?: string) {
        let url = `${this.baseUrl}/invoice/createInvoiceWithPurshase/`;
        if (depotUuid) {
            url += `?depotUuid=${depotUuid}`;
        }
        return this._httpClient.post(url, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    addInvoicesForSp(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/invoice/addInvoicesForSp`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    getFactureNumber(type: any): Observable<any> {
        return this._httpClient.get<PayrollNumberResult>(`${this.baseUrl}/invoice/generate_Billing_Number/${type}`).pipe(map(response => {
            return this.billingnumber = response;
        }));
    }

    getDevisNumber(type: any): Observable<any> {
        return this._httpClient.get<PayrollNumberResult>(`${this.baseUrl}/devis/generate-Devis-Number/${type}`).pipe(map(response => {
            return this.billingnumber = response;
        }));
    }

    getBonLivraisonNumber(type: any): Observable<any> {
        return this._httpClient.get<PayrollNumberResult>(`${this.baseUrl}/delivery-form/generate-Delivery-Number/${type}`).pipe(map(response => {
            return this.billingnumber = response;
        }));
    }

    getBonCommandeNumber(type: any): Observable<any> {
        return this._httpClient.get<PayrollNumberResult>(`${this.baseUrl}/purshaseorder/generate-purshase-number/${type}`).pipe(map(response => {
            return response;
        }));
    }

    getRefundTransactions(uuid: any , uuids): Observable<any> {
        return this._httpClient.post<any>(`${this.baseUrl}/transaction/getRefundTransactionsByInvoice/${uuid}`,uuids).pipe(map(response => {
            return response;
        }));
    }

    getTransactionsByInvoiceByListUuidInvoices(listUuid:any):Observable<any>{
        return  this._httpClient.post<any>(`${this.baseUrl}/transaction/getTransactionsByInvoiceByListUuidInvoices`,listUuid).pipe(map(response => {
            return response;
        }));
    }

    getTransactionsByPurshase(uuid:string):Observable<any>{
        return  this._httpClient.get<any>(`${this.baseUrl}/transaction/getTransactionsByPurshase/${uuid}`).pipe(map(response => {
            return response;
        }));
    }

    getPayedTransactions(uuid: string, invoiceNature : any): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("uuidInvoice",uuid);
        queryParams = queryParams.append("invoiceNatureEnum",invoiceNature);

        return this._httpClient.get<any>(`${this.baseUrl}/transaction/getTransactionsByInvoice`, {params: queryParams}).pipe(map(response => {
            return response;
        }));
    }

    getAllTransactions(): Observable<any> {
        return this._httpClient.get<any>(`${this.baseUrl}/transaction/`).pipe(map(response => {
            return response;
        }));
    }

    getAllDeliveryFormsByTierInPeriod(payload: any) {
        return this._httpClient.post<any>(`${this.baseUrl}/delivery-form/getDelivryFormStatusbyCustomerInPeriod`, payload).pipe(map(response => {
            return response;
        }))
    }

    getAllInvoicesByTierInPeriod(payload: any) {
        return this._httpClient.post<any>(`${this.baseUrl}/invoice/getinvoicestatusbyCustomerInPeriod`, payload).pipe(map(response => {
            return response;
        }))
    }

    // getAllTransactionsByCaisseUUID(uuid: any): Observable<any> {
    //   return this._httpClient.get<any>(`${this.baseUrl}/transaction/caisse/${uuid}`).pipe(map(response => {
    //     return response;
    //   }));
    // }

    saveTransactionForFeedCaisse(payload: any): Observable<any> {
        return this._httpClient.post<any>(`${this.baseUrl}/transaction/`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return []; // only for catch error to work, it requires a return of array, object..
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    getInvoiceByUUID(uuid: any) {
        return this._httpClient.get<any>(`${this.baseUrl}/invoice/${uuid}`).pipe(map(response => {
            return response;
        }))
    }

    getDevisByUUID(uuid: any) {
        return this._httpClient.get<any>(`${this.baseUrl}/devis/${uuid}`).pipe(map(response => {
            return response;
        }))
    }


    getAllTransactionsBetweenTwoDates(data: any, options: ViewOptions) {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("pageSize", options.pageSize);
        queryParams = queryParams.append("pageNum", options.pageNum);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this._httpClient.post<any>(`${this.baseUrl}/transaction/bydate/`, data, {params: queryParams})
    }



    getAllTransactionsBeforeDate(data: any) {
        return this._httpClient.post<any>(`${this.baseUrl}/transaction/bydate/before`, data).pipe(map(response => {
            return response;
        }))
    }





    createRetenueSource(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/r-source`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return [];

            }),
            map((response: any) => {
                return response;
            })
        );
    }

    getAllRetenuesPerPage(options: any, tierName? : string, tierType? : string, invoiceType? : string, startDate? : string, endDate?: string):Observable<any> {

        let queryParams = new HttpParams();
        if(tierName){
            queryParams = queryParams.set('tierName', tierName);
        }
        if(tierType){
            queryParams = queryParams.set('tierType', tierType);
        }
        if(invoiceType){
            queryParams = queryParams.set('invoiceType', invoiceType);
        }
        if(startDate){
            queryParams = queryParams.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if(endDate){
            queryParams = queryParams.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        queryParams = queryParams.append("pageNum", options.pageNum);
        queryParams = queryParams.append("pageSize", options.pageSize);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this._httpClient.get(`${this.baseUrl}/r-source/getAllPegeable/`, {params: queryParams});
    }

    createLocationOrder(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/location-order/create`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return [];
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    createLocationInvoice(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/location-invoice/create`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return [];
            }),
            map((response: any) => {
                return response;
            })
        );
    }
    generateDepositPdf(uuid:any,versionDeposit:boolean){
        return this._httpClient.post(`${this.baseUrl}/location-order/generateDepositPdf/${uuid}/${versionDeposit}`, {}, {
            responseType:'blob'
        })
    }
    generatePDFLocationOrder(payload:any):Observable<any>{
        return this._httpClient.post(`${this.baseUrl}/location-order/generateLocationOrderPdf`,payload, {
            responseType:'blob'
        })
    }

    generateLocationOrderImage(payload:any){
        return this._httpClient.post(`${this.baseUrl}/location-order/generateLocationOrderImage`,payload, {
            responseType:'blob'
        })
    }
    validateRecoveryProduct(line,status) {
        return this._httpClient.put<any>(`${this.baseUrl}/line-location-order/validateRecoveryProduct/${status}`,line);
    }

    getDatesReversedProduct(uuid,quantityProduct,quantityReserved):Observable<any>{
        return this._httpClient.get<any>(`${this.baseUrl}/line-location-order/getDatesReversedProduct/${uuid}/${quantityProduct}/${quantityReserved}`,)

    }

    checkForLastRecoveryProduct(uuid){
        return this._httpClient.get<any>(`${this.baseUrl}/line-location-order/checkForLastRecoveryProduct/${uuid}`)
    }


    getLocationOrderByUuid(uuid):Observable<any>{
        return this._httpClient.get<any>(`${this.baseUrl}/location-order/getLocationOrderByUuid/${uuid}`)
    }

    getLinesLocationOrderNoRecovery(reference? : string,tierSource? : string, tierName? : string,todayDate? : any,  startDate? : any, endDate?: any):Observable<any>{
        let queryParams = new HttpParams();
        if(reference){
            queryParams = queryParams.set('reference', reference);
        }

        if(tierName){
            queryParams = queryParams.set('tierName', tierName);
        }
        if(tierSource){
            queryParams = queryParams.set('tierSource', tierSource);
        }
        if(todayDate){
            queryParams = queryParams.set('todayDate', moment(todayDate).format('YYYY-MM-DD'));
        }

        if(startDate){
            queryParams = queryParams.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if(endDate){
            queryParams = queryParams.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        return this._httpClient.get(`${this.baseUrl}/line-location-order/getLinesLocationOrderNoRecovery`, {params: queryParams});
    }

    getLinesLocationOrderByDeliveryDate(reference? : string,tierSource? : string, tierName? : string, cin? : string, phone? : string,todayDate? : any,  startDate? : any, endDate?: any):Observable<any>{
        let queryParams = new HttpParams();
        if(reference){
            queryParams = queryParams.set('reference', reference);
        }

        if(tierName){
            queryParams = queryParams.set('tierName', tierName);
        }
        if(tierSource){
            queryParams = queryParams.set('tierSource', tierSource);
        }
        if(cin){
            queryParams = queryParams.set('cin', cin);
        }
        if(phone){
            queryParams = queryParams.set('phone', phone);
        }
        if(todayDate){
            queryParams = queryParams.set('todayDate', moment(todayDate).format('YYYY-MM-DD'));
        }

        if(startDate){
            queryParams = queryParams.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if(endDate){
            queryParams = queryParams.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        return this._httpClient.get(`${this.baseUrl}/line-location-order/getLinesLocationOrderByDeliveryDate`, {params: queryParams});
    }

    getAllLocationOrdersPerPage(options: any, tierName? : string, reference? : string,tierSource? : string, startDate? : string, endDate?: string):Observable<any> {

        let queryParams = new HttpParams();
        if(tierName){
            queryParams = queryParams.set('tierName', tierName);
        }
        if(reference){
            queryParams = queryParams.set('reference', reference);
        }
        if(tierSource){
            queryParams = queryParams.set('tierSource', tierSource);
        }

        if(startDate){
            queryParams = queryParams.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if(endDate){
            queryParams = queryParams.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        queryParams = queryParams.append("pageIndex", options.pageNum);
        queryParams = queryParams.append("pageSize", options.pageSize);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this._httpClient.get(`${this.baseUrl}/location-order/perPage/`, {params: queryParams});
    }

    getAllLocationInvoicesPerPage(options: any, tierName? : string, reference? : string,tierSource? : string, startDate? : string, endDate?: string):Observable<any> {

        let queryParams = new HttpParams();
        if(tierName){
            queryParams = queryParams.set('tierName', tierName);
        }
        if(reference){
            queryParams = queryParams.set('reference', reference);
        }
        if(tierSource){
            queryParams = queryParams.set('tierSource', tierSource);
        }

        if(startDate){
            queryParams = queryParams.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if(endDate){
            queryParams = queryParams.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        queryParams = queryParams.append("pageIndex", options.pageNum);
        queryParams = queryParams.append("pageSize", options.pageSize);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this._httpClient.get(`${this.baseUrl}/location-invoice/perPage/`, {params: queryParams});
    }

    generatePDFLocationInvoice(payload:any){
        return this._httpClient.post(`${this.baseUrl}/location-invoice/generateLocationInvoicePdf`,payload, {
            responseType:'blob'
        })
    }

    getRetenueSourceByInvoiceUuid(uuid: string): Observable<any> {
        return this._httpClient.get<any>(`${this.baseUrl}/r-source/invoice/${uuid}`).pipe(
            map(response => {
                return response;
            }));
    }

    createDevis(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/devis`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return []; // only for catch error to work, it requires a return of array, object..
            }),
            map((response: any) => {
                return response;
            })
        );
    }


    createBonLivraison(payload: any, factType?: string) {
        let url = `${this.baseUrl}/delivery-form/`;
        if (factType) {
            url += `?factType=${factType}`;
        }
        return this._httpClient.post(url, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return [];
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    createDeliveryWithPurshase(payload: any, depotUuid?: string) {
        let url = `${this.baseUrl}/delivery-form/createDeliveryWithPurshase`;
        if (depotUuid) {
            url += `?depotUuid=${depotUuid}`;
        }
        return this._httpClient.post(url, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return [];
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    createListBonLivraison(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/delivery-form/deliveryAll`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return []; // only for catch error to work, it requires a return of array, object..
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    payCheckedInvoices(payload: any) {
        return this._httpClient.post<any>(`${this.baseUrl}/invoice/pay-checked-invoices`, payload).pipe(map(response => {
            return response;
        }))
    }

    payLocationOrder(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/location-order/pay-locationOrder`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return [];
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    payer(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/invoice/pay-invoice`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return [];
            }),
            map((response: any) => {
                return response;
            })
        );
    }
    payDevis(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/devis/pay-devis`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return [];
            }),
            map((response: any) => {
                return response;
            })
        );
    }


    deliver(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/delivery-form/pay-delivery`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return [];
            }),
            map((response: any) => {
                return response;
            })
        );
    }



    updateBonRecept(payload: any) {
        return this._httpClient.put(`${this.baseUrl}/receipt`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return []; // only for catch error to work, it requires a return of array, object..
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    createBonCommande(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/purshaseorder`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    generateTraitesTransactions(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/transaction/generateTraitesTransactions`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    deleteLinePurshase(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/line-purshase/deleteLinePurshase`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    deleteLineDevis(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/line-devis/deleteLineDevis`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    deleteDevis(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/delete-billing-data/devis/specific`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    deletePurshase(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/delete-billing-data/purshase/specific`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    deleteDelivery(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/delete-billing-data/delivery/specific`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    deleteInvoice(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/delete-billing-data/invocie/specific`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    addOrReturnQuantity(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/line-purshase/addOrReturnQuantity`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }


    updateBonCommandeLineModels(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/purshaseorder/updatePurshaseLines`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    genericInvoiceUpdate(payload: GenericPayloadUpdate) {
        return this._httpClient.post(`${this.baseUrl}/common-apis-billing/generic-update-invoice`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    createPartialBLivraison(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/delivery-form/createPartialBLivraison`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return [];
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    updateDevis(payload: any) {
        return this._httpClient.put(`${this.baseUrl}/devis`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return [];
            }),
            map((response: any) => {
                return response;
            })
        );
    }

    deleteRetenueSource(uuid: any): Observable<any> {
        return this._httpClient.delete(`${this.baseUrl}/r-source/${uuid}`).pipe(map(response => {
            return response;
        }));
    }

    getTransactionHistory(data: any) {
        return this._httpClient.post<any>(`${this.baseUrl}/transaction-history/`, data).pipe(map(response => {
            return response;
        }))
    }

    getTransactionHistoryGreater(data: any) {
        return this._httpClient.post<any>(`${this.baseUrl}/transaction-history/next`, data).pipe(map(response => {
            return response;
        }))
    }

    affectLinesToDepot(data: any) {
        return this._httpClient.post<any>(`${this.baseUrl}/line-purshase/affectLinesToDepot`, data).pipe(map(response => {
            return response;
        }))
    }

    affectDevisLinesToDepot(data: any) {
        return this._httpClient.post<any>(`${this.baseUrl}/line-devis/affectLinesToDepot`, data).pipe(map(response => {
            return response;
        }))
    }

    checkInvoice(): Observable<any> {
        return this._httpClient.get(`${this.baseUrl}/invoice/checkInvoice`)
    }

    checkDelivery(): Observable<any> {
        return this._httpClient.get(`${this.baseUrl}/invoice/checkDelivery`)
    }

    getAllDevisByCollaborator(uuid: string) {
        return this._httpClient.get<any>(`${this.baseUrl}/devis/getAllDevisVenteByCollaborator/${uuid}`)
    }

    getAllDeliveryFormVenteByCollaborato(uuid: string) {
        return this._httpClient.get<any>(`${this.baseUrl}/delivery-form/getAllDeliveryFormVenteByCollaborator/${uuid}`)
    }

    getAllInvoiceVenteByCollaborato(uuid: string) {
        return this._httpClient.get<any>(`${this.baseUrl}/invoice/getAllInvoiceVenteByCollaborator/${uuid}`)
    }

    getAllPurshaseOrderVenteByCollaborator(uuid: string) {
        return this._httpClient.get<any>(`${this.baseUrl}/purshaseorder/getAllPurshaseOrderVenteByCollaborator/${uuid}`)
    }

    filterBnLivraison(bonLivraison: any, devis: any, collab: any): Observable<Array<any>> {
        return this._httpClient.get<Array<any>>(`${this.baseUrl}/delivery-form/filterBLivraisonByCollabAndDevis`, {
            params: this.setParamsBnLivraison(bonLivraison, devis, collab)
        }).pipe(map(response => {
            return response;
        }));
    }

    filterBnLivraisonByClien(client: any, collab: any): Observable<Array<any>> {
        return this._httpClient.get<Array<any>>(`${this.baseUrl}/delivery-form/filterBLivraisonByCollabAndClient`, {
            params: this.setParamsBnLivraisonByClient(client, collab)
        }).pipe(map(response => {
            return response;
        }));
    }

    private setParamsBnLivraisonByClient(client: any, collab: any): HttpParams {

        let params = new HttpParams();

        if (client !== '') {
            params = params.set('client', client);
        }
        if (collab !== '') {
            params = params.set('collab', collab);
        }
        return params;
    }

    private setParamsBnLivraison(bonLivraison: any, devis: any, collab: any): HttpParams {

        let params = new HttpParams();
        if (bonLivraison !== '') {
            params = params.set('bonLivraison', bonLivraison);
        }
        if (devis !== '') {
            params = params.set('devis', devis);
        }
        if (collab !== '') {
            params = params.set('collab', collab);
        }
        return params;
    }

    filterDevis(devis: any, collab: any): Observable<Array<any>> {
        return this._httpClient.get<Array<any>>(`${this.baseUrl}/devis/filterDevisByCollab`, {
            params: this.setParamsDevis(devis, collab)
        }).pipe(map(response => {
            return response;
        }));
    }

    filterDevisByClient(client: any, collab: any): Observable<Array<any>> {
        return this._httpClient.get<Array<any>>(`${this.baseUrl}/devis/filterDevisByCollabAndCollab`, {
            params: this.setParamsDevisByClient(client, collab)
        }).pipe(map(response => {
            return response;
        }));
    }

    private setParamsDevisByClient(client: any, collab: any): HttpParams {

        let params = new HttpParams();
        if (client !== '') {
            params = params.set('client', client);
        }
        if (collab !== '') {
            params = params.set('collab', collab);
        }
        return params;
    }

    private setParamsDevis(devis: any, collab: any): HttpParams {

        let params = new HttpParams();
        if (devis !== '') {
            params = params.set('devis', devis);
        }
        if (collab !== '') {
            params = params.set('collab', collab);
        }
        return params;
    }

    addRemiseDelivery(payload) {
        return this._httpClient.patch(`${this.baseUrl}/delivery-form/addRemise`, payload);

    }

    addRemiseInvoice(payload) {
        return this._httpClient.patch(`${this.baseUrl}/invoice/addRemise`, payload);

    }

    getDeliverybyUuid(uuid: any): Observable<any> {
        return this._httpClient.get(`${this.baseUrl}/delivery-form/${uuid}`)
    }

    getAllDevisPageableByType(options: any, typeTransaction: any, moduleType: any, reference?: any, customer?: any,status? :any, isRegulation?: boolean, startDate?: string, endDate?: string): Observable<any> {
        let params = new HttpParams();
        if (reference != null) {
            params = params.set('reference', reference);
        }

        if (customer != null) {
            params = params.set('customer', customer);
        }
        if (isRegulation != null) {
            params = params.set('isRegulation', isRegulation);
        }
        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            params = params.set('endDate',moment(endDate).format('YYYY-MM-DD')
            );
        }

        params = params.set('status',status );

        params = params.set('invoiceType', typeTransaction);
        params = params.set('moduleType', moduleType);
        params = params.set('pageSize', options.pageSize);
        params = params.set('pageIndex', options.pageIndex);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);
        params = params.set('isRegulation', false);
        return this._httpClient.get<any>(`${this.baseUrl}/devis/perPage`, {params: params})
    }
    getAllBonCommandePageableByType(options: any, transactionType: any, moduleType: any,status:any[],isRegulation?: boolean,reference?:string,customer?:string): Observable<any> {
        let params = new HttpParams();
        params = params.set('transactionType', transactionType);
        params = params.set('moduleType', moduleType);
        params = params.set('pageSize', options.pageSize);
        params = params.set('pageIndex', options.pageIndex);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);
        if (isRegulation != null) {
            params = params.set('isRegulation', isRegulation);
        }
        if (reference) {
            params = params.set('reference', reference);
        }
        if (status && Array.isArray(status)) {
            params = params.set('status', status.join(','));
        }
        if (customer) {
            params = params.set('customer', customer);
        }
        return this._httpClient.get<BonCommandModel[]>(`${this.baseUrl}/purshaseorder/perPage`, {params: params})
    }

    getAllBonLivraisonPageableByType(options: any, transactionType: any, moduleType: any,isRegulation?: boolean,reference?:string,customer?:string): Observable<any> {
        let params = new HttpParams();
        params = params.set('transactionType', transactionType);
        params = params.set('moduleType', moduleType);
        params = params.set('pageSize', options.pageSize);
        params = params.set('pageIndex', options.pageIndex);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);
        if (isRegulation != null) {
            params = params.set('isRegulation', isRegulation);
        }
        if (reference) {
            params = params.set('reference', reference);
        }
        if (customer) {
            params = params.set('customer', customer);
        }
        return this._httpClient.get<BonLivraisonModel[]>(`${this.baseUrl}/delivery-form/perPage`, {params: params})
    }


    generatInvoicePdf(payload:any){
        return this._httpClient.post(`${this.baseUrl}/invoice/generatInvoicePdf`,payload, {
            responseType:'blob'
        })
    }

    previewInvoicePdf(payload:any){
        return this._httpClient.post(`${this.baseUrl}/invoice/previewInvoicePdf`,payload, {
            responseType:'blob'
        })
    }

    generateInvoiceImage(payload:any){
        return this._httpClient.post(`${this.baseUrl}/invoice/generateInvoiceImage`,payload, {
            responseType:'blob'
        })
    }

    generatePDFAllInvoicesSelectedSP(payload:any){
        return this._httpClient.post(`${this.baseUrl}/invoice/generatePDFAllInvoicesSelectedSP`,payload, {
            responseType:'blob'
        })
    }

    generatePDFSP(payload:any){
        return this._httpClient.post(`${this.baseUrl}/invoice/generatePDFSP`,payload, {
            responseType:'blob'
        })
    }

    generaterReport(tierName?: string, typeTier?: string, startDate?: string, endDate?: string,type?:any){
        let params = new HttpParams();
        if (tierName) {
            params = params.set('tierName', tierName);
        }
        if (typeTier) {
            params = params.set('nature', typeTier);
        }
        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        if (type) {
            params = params.set('type', type);
        }
        return this._httpClient.get(`${this.baseUrl}/invoice/Rapport-Journalier`, {
            params: params,
            responseType:'blob'
        })


    }

    generateDetailReport(tierName?: string, typeTier?: string, startDate?: string, endDate?: string,type?:any){
        let params = new HttpParams();
        if (tierName) {
            params = params.set('tierName', tierName);
        }
        if (typeTier) {
            params = params.set('nature', typeTier);
        }
        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        if (type) {
            params = params.set('type', type);
        }
        return this._httpClient.get(`${this.baseUrl}/invoice/Rapport-Detail`, {
            params: params,
            responseType:'blob'
        })


    }
    generateRapportJournalierFacturesPayments( billRequestList:any,tierName?: string, typeTier?: string,startDate?: string, endDate?: string){
        let params = new HttpParams();
        if (tierName) {
            params = params.set('tierName', tierName);
        }
        if (typeTier) {
            params = params.set('nature', typeTier);
        }
        if (startDate) {
            params = params.set('startDate', moment(startDate).format('DD-MM-YYYY'));
        }
        if (endDate) {
            params = params.set('endDate', moment(endDate).format('DD-MM-YYYY'));
        }
        return this._httpClient.post(`${this.baseUrl}/invoice/generateRapportJournalierFacturesPayments`, billRequestList,{
            params: params,
            responseType:'blob'
        })


    }


    getAllAllFacturePageableByType(options: any,isRegulation: boolean, transactionType?: any, moduleType?: any, typeTier?: string, tierName?: string, startDate?: string, endDate?: string,customer?:string,reference?:string,type?:string): Observable<any> {
        let params = new HttpParams();
        if (type) {
            params = params.set('typeAchat', type);
        }
        if (transactionType) {
            params = params.set('transactionType', transactionType);
        }
        if (moduleType) {
            params = params.set('moduleType', moduleType);
        }

        if (typeTier) {
            params = params.set('typeTier', typeTier);
        }
        if (tierName) {
            params = params.set('tierName', tierName);
        }
        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        if (customer) {
            params = params.set('customer', customer);
        }
        if (reference) {
            params = params.set('reference', reference);
        }
        params = params.set('pageSize', options.pageSize);
        params = params.set('pageIndex', options.pageIndex);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);
        params = params.set('isRegulation', isRegulation);
        return this._httpClient.get<FactureModel[]>(`${this.baseUrl}/invoice/perPage`, {params: params})
    }

    getAllAllFactureBlPageableByType(options: any, transactionType?: any, moduleType?: any, typeTier?: string, tierName?: string, startDate?: string, endDate?: string,type?:string,customer?:string): Observable<any> {
        let params = new HttpParams();
        if (type) {
            params = params.set('type', type);
        }
        if (transactionType) {
            params = params.set('transactionType', transactionType);
        }
        if (moduleType) {
            params = params.set('moduleType', moduleType);
        }

        if (typeTier) {
            params = params.set('typeTier', typeTier);
        }
        if (tierName) {
            params = params.set('tierName', tierName);
        }
        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        if (customer) {
            params = params.set('customer', customer);
        }
        params = params.set('pageSize', options.pageSize);
        params = params.set('pageIndex', options.pageIndex);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);
        return this._httpClient.get<FactureModel[]>(`${this.baseUrl}/delivery-form/bl_invoiceperpage`, {params: params})
    }

    changeStatuBonLivraison(uuid, optionType?) {

        let params = new HttpParams();
        params = params.set('updatedItemUuid', uuid);
        if (optionType) {
            params = params.set('optionType', optionType);
        }
        return this._httpClient.get(`${this.baseUrl}/delivery-form/changeStatus`, {params: params})

    }

    addTax(rawValue) {
        return this._httpClient.post(`${this.baseUrl}/tax`, rawValue)
    }

    getTaxPage(options) {
        let params = new HttpParams();
        params = params.set('pageSize', options.pageSize);
        params = params.set('pageNum', options.pageNum);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);
        return this._httpClient.get(`${this.baseUrl}/tax/getpagetax`, {params: params})

    }

    changeStatutax(itemid, status) {
        let params = new HttpParams();
        params = params.set('id', itemid);
        params = params.set('status', status);
        return this._httpClient.get(`${this.baseUrl}/tax/changetaxstatus`, {params: params})

    }

    getAllTax() {
        return this._httpClient.get(`${this.baseUrl}/tax/getall`)
    }

    getAvoirs(uuidInvoice: string): any {
        return this._httpClient.get(`${this.baseUrl}/avoir/getcreditByInvoice/${uuidInvoice}`)
    }

    getFilteredTransactionsPage(pageOptions, searchFields) {
        let params = new HttpParams();
        params = params.append("pageSize", pageOptions.pageSize);
        params = params.append("pageNum", pageOptions.pageNum);
        if (pageOptions.sortDirection) params = params.append("sortDirection", pageOptions.sortDirection);
        if (pageOptions.sortField) params = params.append("sortField", pageOptions.sortField);
        if (searchFields.tierName) params = params.append("tierName", searchFields.tierName);
        if (searchFields.typeTier) params = params.append("typeTier", searchFields.typeTier);
        if (searchFields.startDate != null) {
            params = params.append("startDate", moment(searchFields.startDate).format('YYYY-MM-DD'))
        }
        if (searchFields.endDate != null) {
            params = params.append("endDate", moment(searchFields.endDate).format('YYYY-MM-DD'))
        }
        if(searchFields.payType!=null && searchFields.payType){
            params = params.append("payType",searchFields.payType);
        }
        if(searchFields.refInvoice!=null && searchFields.refInvoice){
            params = params.append("refInvoice",searchFields.refInvoice);
        }
        return this._httpClient.get<TransactionsPageResponseDto>(`${this.baseUrl}/transaction/getFilteredTransactionPage`, {
            params: params
        });
    }

    getTransactionsByAvoirPage(pageOptions, uuidAvoir) {
        let params = new HttpParams();
        params = params.append("pageSize", pageOptions.pageSize);
        params = params.append("pageNum", pageOptions.pageNum);
        if (pageOptions.sortDirection) params = params.append("sortDirection", pageOptions.sortDirection);
        if (pageOptions.sortField) params = params.append("sortField", pageOptions.sortField);
        if (uuidAvoir) params = params.append("uuidAvoir", uuidAvoir);
        return this._httpClient.get<any>(`${this.baseUrl}/transaction/getTransactionPageByUuidAvoir`, {
            params: params
        });
    }

    getTransactionsSum() {
        return this._httpClient.get<any>(`${this.baseUrl}/transaction/getTransactionsSum`);
    }

    getLinesWithDefaultVariant(payload) {
        return this._httpClient.post<any>(`${this.baseUrl}/line-purshase/getLinesWithDefaultVariant`, payload);
    }

    getDeliveryLinesWithDefaultVariant(payload) {
        return this._httpClient.post<any>(`${this.baseUrl}/line-delivery/getLinesWithDefaultVariant`, payload);
    }

    getAllBonLivraisonAll() {
        return this._httpClient.get(`${this.baseUrl}/delivery-form/`).pipe(
            timeout(60000),
            catchError((e, c) => {
                return []; // only for catch error to work, it requires a return of array, object..
            }),
            map((response: any) => {
                return response;
            })
        );
    }


    calculateSumOfTtcAndLeftToPay() {
        return this._httpClient.get<any>(`${this.baseUrl}/invoice/sum`);
    }

    calculateSumOfTotalTva(searchFields ) {
        let params = new HttpParams();

        if (searchFields.startDate != null) {
            params = params.append("startDate", moment(searchFields.startDate).format('YYYY-MM-DD'))
        }
        if (searchFields.transactionType != null) {
            params = params.append("transactionType", searchFields.transactionType)
        }
        if (searchFields.endDate != null) {
            params = params.append("endDate", moment(searchFields.endDate).format('YYYY-MM-DD'))
        }
        return this._httpClient.get<any>(`${this.baseUrl}/invoice/totalTvaByType`,{params:params});
    }

    calculatePeriodSumOfTtcAndLeftToPay(tierName: string, nature: string, startDate: string, endDate: string): Observable<any> {
        let params = new HttpParams();
        if (tierName) {
            params = params.append('tierName', tierName);
        }
        if (nature) {
            params = params.append('nature', nature);
        }
        if (startDate) {
            params = params.append('startDate', startDate);
        }
        if (endDate) {
            params = params.append('endDate', endDate);
        }

        return this._httpClient.get<any>(`${this.baseUrl}/invoice/period-sum`, {
            params: params
        });
    }


    calculateSumAvoir(){
        return this._httpClient.get<any>(`${this.baseUrl}/FinancialCreditInvoice/sum-avoir`);
    }

    calculatePeriodSumAvoir(tierName: string, nature: string, startDate: string, endDate: string): Observable<any> {
        let params = new HttpParams();
        if (tierName) {
            params = params.append('tierName', tierName);
        }
        if (nature) {
            params = params.append('nature', nature);
        }
        if (startDate) {
            params = params.append('startDate', startDate);
        }
        if (endDate) {
            params = params.append('endDate', endDate);
        }

        return this._httpClient.get<any>(`${this.baseUrl}/FinancialCreditInvoice/period-sum-avoir`, {
            params: params
        });
    }

    cancelBonCommande(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/purshaseorder/cancelPurchaseOrder`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    cancelBonCommandeAchat(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/purshaseorder/cancelPurchaseOrder/achat`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    cancelDeliveryFormAchat(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/delivery-form/cancelDeliveryForm/achat`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    cancelDeliveryForm(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/delivery-form/cancelDeliveryForm`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    cancelInvoiceAchat(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/invoice/cancelInvoice/achat`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    cancelInvoice(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/invoice/cancelInvoice`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    getTaxByUUID(uuid:any) {
        return this._httpClient.get(`${this.baseUrl}/tax/${uuid}`).pipe(map(tax=>tax))
    }

    getAllTransactionsByFilters(pageOptions:any) {
        let params = new HttpParams();
        params = params.append("nomBank", pageOptions.nomBank);
        params = params.append("numcheque", pageOptions.numcheque);
        params = params.append("nomPayeur", pageOptions.nomPayeur);
        return this._httpClient.get<any>(`${this.baseUrl}/transaction/filterV2`,{ params: params }).pipe(map(response => {
            return response;
        }));

    }
    filterTransactionsByType(pageOptions:any) {
        let params = new HttpParams();
        if (pageOptions.status) params = params.append("status", pageOptions.status);
        if (pageOptions.payType) params = params.append("payType", pageOptions.payType);
        if (pageOptions.numCheque) params = params.append("numCheque", pageOptions.numCheque);
        if (pageOptions.nomPayeur) params = params.append("nomPayeur", pageOptions.nomPayeur);
        if (pageOptions.numDossier) params = params.append("numDossier", pageOptions.numDossier);

        if (pageOptions.numBordereau) params = params.append("bordereauNumber", pageOptions.numBordereau);
        if (pageOptions.invoiceType &&(pageOptions.invoiceType==='DEBIT'||pageOptions.invoiceType==='CREDIT')) params = params.append("sens", pageOptions.invoiceType);

        return this._httpClient.get<any>(`${this.baseUrl}/transaction/filterV3`,{ params: params }).pipe(map(response => {
            return response;
        }));
    }


    getAllTransactionByType( searchFields:any,pageOptions:any) {
        let params = new HttpParams();
        params = params.append("pageSize", pageOptions.pageSize);
        params = params.append("pageNum", pageOptions.pageNum);
        if (pageOptions.sortDirection) params = params.append("sortDirection", pageOptions.sortDirection);
        if (pageOptions.sortField) params = params.append("sortField", pageOptions.sortField);
        if (searchFields.status) params = params.append("status", searchFields.status);
        if (searchFields.payType) params = params.append("payType", searchFields.payType);
        if (searchFields.numCheque) params = params.append("numCheque", searchFields.numCheque);
        if (searchFields.nomPayeur) params = params.append("nomPayeur", searchFields.nomPayeur);
        if (searchFields.numDossier) params = params.append("numDossier", searchFields.numDossier);

        if (searchFields.numBordereau) params = params.append("bordereauNumber", searchFields.numBordereau);
        if (searchFields.sensType &&(searchFields.sensType==='DEBIT'||searchFields.sensType==='CREDIT')) params = params.append("sens", searchFields.sensType);

        if (searchFields.startDate != null) {
            params = params.append("startDate", moment(searchFields.startDate).format('YYYY-MM-DD'))
        }
        if (searchFields.endDate != null) {
            params = params.append("endDate", moment(searchFields.endDate).format('YYYY-MM-DD'))
        }

        return this._httpClient.get<any>(`${this.baseUrl}/transaction/filter`,{ params: params }).pipe(map(response => {
            return response;
        }));
    }

    getListBodereauNumbers() {
        return this._httpClient.get<any>(`${this.baseUrl}/transaction/listBordereau`);
    }

    getBordereauNumber(): Observable<any> {
        return this._httpClient.get<PayrollNumberResult>(`${this.baseUrl}/transaction/generate_Bordereau_Number`).pipe(map(response => {
            return response;
        }));
    }

    validerTransaction(uuids:string[], status: any , validationDate?: string) {
        const payload = {
            uuids,
            status,
            validationDate
        }

        return this._httpClient.post<any>(`${this.baseUrl}/transaction/valider`, payload);
    }

    getAllChequesbyMap( searchFields) {
        let params = new HttpParams();
        if (searchFields.status) params = params.append("status", searchFields.status);
        if (searchFields.payType) params = params.append("payType", searchFields.payType);
        if (searchFields.numCheque) params = params.append("numCheque", searchFields.numCheque);
        if (searchFields.invoiceType &&(searchFields.invoiceType==='DEBIT'||searchFields.invoiceType==='CREDIT')) params = params.append("sens", searchFields.invoiceType);

        if (searchFields.startDate != null) {
            params = params.append("startDate", moment(searchFields.startDate).format('YYYY-MM-DD'))
        }
        if (searchFields.endDate != null) {
            params = params.append("endDate", moment(searchFields.endDate).format('YYYY-MM-DD'))
        }

        return this._httpClient.get<any>(`${this.baseUrl}/transaction/byType`,{ params: params }).pipe(map(response => {
            return response;
        }));
    }
    getAllCreditStegbyMap( searchFields) {
        let params = new HttpParams();
        if (searchFields.status) params = params.append("status", searchFields.status);
        if (searchFields.payType) params = params.append("payType", searchFields.payType);
        if (searchFields.numDossier) params = params.append("numDossier", searchFields.numDossier);
        if (searchFields.startDate != null) {
            params = params.append("startDate", moment(searchFields.startDate).format('YYYY-MM-DD'))
        }
        if (searchFields.endDate != null) {
            params = params.append("endDate", moment(searchFields.endDate).format('YYYY-MM-DD'))
        }

        return this._httpClient.get<any>(`${this.baseUrl}/transaction/getTransactionMapGroupedByLot`,{ params: params }).pipe(map(response => {
            return response;
        }));
    }

    validerListcheques(payload) {
        return this._httpClient.post<any>(`${this.baseUrl}/transaction/validerListCheque`,payload);
    }
    transferToLot(payload) {
        return this._httpClient.post<any>(`${this.baseUrl}/lot/transferTransactionsToLot`,payload);
    }
    validerListTransactionsCreditsSteg(payload){
        return this._httpClient.post<any>(`${this.baseUrl}/transaction/validateListOfCreditTransactions`,payload);
    }



    getBonCommandeByUUID2(uuid: any) {
        return this._httpClient.get(`${this.baseUrl}/purshaseorder/${uuid}`).pipe(

            map((response: any) => {
                return response;
            })
        );
    }



    transferListBonLivraisonToInvoice(payload: BonLivraisonListDtoModel){
        return this._httpClient.post(`${this.baseUrl}/invoice/transfer`,payload).pipe(
            map((response: any) => {
                return response;
            })
        );

    }

    validateAuthorityForPurchase(){
        return this._httpClient.get(`${this.baseUrl}/purshaseorder/validateAuthorityForPurchase`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }



    approvePurchaseOrder(uuid: any) {
        return this._httpClient.get(`${this.baseUrl}/purshaseorder/approvePurchaseOrder/${uuid}`).pipe(

            map((response: any) => {
                return response;
            })
        );
    }

    addAdvance(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/advance/create`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    getCostAvance(tierName?: any, startDate?: string, endDate?: string,sens?: any): Observable<any> {
        let params = new HttpParams();
        if (tierName) {
            params = params.set('tierName', tierName);
        }
        if (sens) {
            params = params.set('sens', sens);
        }
        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        return this._httpClient.get<FactureModel[]>(`${this.baseUrl}/advance/getcost`, {params: params})
    }



    getAllAllAdvancePageableByName(options: any, tierName?: any , startDate?: string, endDate?: string ,sens?: any , typeTier? : string , isFiltering?: boolean): Observable<any> {
        let params = new HttpParams();
        if (sens) {
            params = params.set('sens', sens);
        }
        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        if (typeTier) {
            params = params.set('typeTier', typeTier);
        }
        params = params.set('pageSize', options.pageSize);
        params = params.set('pageIndex', options.pageIndex);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);
        if (tierName) {
            params = params.set('tierName', tierName);
        }
        params = params.set('isFiltering', isFiltering);

        return this._httpClient.get<FactureModel[]>(`${this.baseUrl}/advance/perPage`, {params: params})
    }

    addTareTrack(tare:any):Observable<any>{
        return this._httpClient.post<any>(`${this.baseUrl}/tare-track/addTare-track`, tare)
    }

    getAllTrackPageable(options: any): Observable<any> {
        let params = new HttpParams();
        params = params.set('pageNum', options.pageNum);
        params = params.set('pageSize', options.pageSize);
        params = params.set('sortField', options.sortField);
        params = params.set('sortDirection', options.sortDirection);

        return this._httpClient.get<FactureModel[]>(`${this.baseUrl}/tare-track/perPage`, {params: params})
    }


    getTotalNombresTares( costumer?: any,tare?: any,sens?: any, startDate?: string, endDate?: string) :Observable<any> {
        let params = new HttpParams();
        if (tare) {
            params = params.set('tare', tare);
        }
        if (costumer) {
            params = params.set('costumer', costumer);
        }
        if (sens) {
            params = params.set('sens', sens);
        }
        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        return this._httpClient.get<any>(`${this.baseUrl}/tare-track/getTotalNombresTares`,{params: params})
    }


    generateTareTrackReport( costumer?: any,tare?: string,sens?: any, startDate?: string, endDate?: string): Observable<Blob> {
        let params = new HttpParams();
        if (tare) {
            params = params.set('tare', tare);
        }
        if (costumer) {
            params = params.set('costumer', costumer);
        }
        if (sens) {
            params = params.set('sens', sens);
        }
        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        return this._httpClient.get(`${this.baseUrl}/tare-track/generate-tare-track-report`, {
            params: params,
            responseType: 'blob'
        });
    }
    generateTareTrackReportByType( costumer?: any,tare?: string,sens?: any, startDate?: string, endDate?: string): Observable<Blob> {
        let params = new HttpParams();
        if (tare) {
            params = params.set('tare', tare);
        }
        if (costumer) {
            params = params.set('costumer', costumer);
        }
        if (sens) {
            params = params.set('sens', sens);
        }
        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        return this._httpClient.get(`${this.baseUrl}/tare-track/generate-tare-track-report-by-type-sp`, {
            params: params,
            responseType: 'blob'
        });
    }
    generateTransactionsReport(tierName?: any, typeTier?: any, startDate?: Date, endDate?: Date,payType?:string): Observable<Blob> {
        let params = new HttpParams();
        if (tierName) {
            params = params.set('tierName', tierName);
        }
        if (typeTier) {
            params = params.set('typeTier', typeTier);
        }

        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        if(payType){
            params = params.set('payType',payType )
        }
        return this._httpClient.get(`${this.baseUrl}/transaction/generate-transactions-report`, {
            params: params,
            responseType: 'blob'
        });
    }
    updateDevisLine(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/devis/updateDevis`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    generateRetenuePDF(rSourceId: number): Observable<Blob> {
        let params = new HttpParams()
        .set('rSourceId', rSourceId)

        return this._httpClient.get(`${this.baseUrl}/r-source/generateRSourcePdf`, {
            params: params,
            responseType: 'blob'
        });
    }

    generateRSourceImage(retenueId: any){
        return this._httpClient.post(`${this.baseUrl}/r-source/generateRSourceImage`, retenueId,{
            responseType:'blob'
        })
    }

    getTemplateDocumentPerPartner(partnerUuid: any): Observable<any> {
        let params = new HttpParams();

        params = params.set('partnerUuid', partnerUuid);

        return this._httpClient.get<BillTemplateModel>(`${this.baseUrl}/bill-template/getBillTemplateByPartner`, {params: params})
    }


    updateBillTemplatePartner(billTemplateDto: BillTemplateDto) {
        return this._httpClient.put<any>(`${this.baseUrl}/bill-template/updateBillTemplatePartner`,billTemplateDto);
    }


    getTemplateDocumentPerCurrentPartner(): Observable<any> {
        return this._httpClient.get<BillTemplateModel>(`${this.baseUrl}/bill-template/getBillTemplateByCurrentPartner`)
    }






    annulerFactureGeneralSP(uuid: any) {
        return this._httpClient.post(`${this.baseUrl}/invoice/annulerInvoiceGeneralSP/${uuid}`,{}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    generatePDF(payload:any){
        return this._httpClient.post(`${this.baseUrl}/transaction/generateBordereauPDF`,payload, {
            responseType:'blob'
        })
    }

    getRapportAvoirs(reference?: string, tierName?: string, startDate?: string, endDate?: string){
        let params = new HttpParams();
        if (reference) {
            params = params.set('reference', reference);
        }
        if (tierName) {
            params = params.set('tierUuid', tierName);
        }
        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }

        return this._httpClient.get(`${this.baseUrl}/avoir/getRapportAvoirs`, {
            params: params,
            responseType:'blob'
        })
    }

    generateRapportRevendeurs(tierName?: string, typeTier?: string, startDate?: string, endDate?: string,type?:any){
        let params = new HttpParams();
        if (tierName) {
            params = params.set('tierName', tierName);
        }
        if (typeTier) {
            params = params.set('nature', typeTier);
        }
        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        if (type) {
            params = params.set('type', type);
        }
        return this._httpClient.get(`${this.baseUrl}/invoice/RapportRevendeurs`, {
            params: params,
            responseType:'blob'
        })


    }
    generatePaiementReport(invoiceUUID: string, refInvoice: string): Observable<Blob> {
        const headers = new HttpHeaders({
            'Accept': 'application/pdf'
        });

        const params = new HttpParams().set('invoiceUUID', invoiceUUID).set('refInvoice', refInvoice);

        return this._httpClient.post<Blob>(`${this.baseUrl}/invoice/generate-paiement-report`, null, {
            headers: headers,
            params: params,
            responseType: 'blob' as 'json'
        });
    }
    cancelLocationOrder(uuid):Observable<any>{
        return this._httpClient.put(`${this.baseUrl}/location-order/cancelLocationOrder/${uuid}`,{}).pipe(map(response => {
            return response;
        }));
    }


    changeStatuslineLocationOrder(uuid,status):Observable<any>{
        return this._httpClient.put(`${this.baseUrl}/line-location-order/changeStatuslineLocationOrder/${uuid}/${status}`,{}).pipe(map(response => {
            return response;
        }));
    }

    transfertMontant(source: string, cible: string, montant: number , tierNameCible? : string , tierNameSource?:string): Observable<any> {
        const params = new HttpParams()
        .set('source', source)
        .set('cible', cible)
        .set('montant', montant.toString())
        .set('tierNameCible', tierNameCible)
        .set('tierNameSource', tierNameSource);
        return this._httpClient.post(`${this.baseUrl}/transaction/transfert`, null, { params });
    }
    getAllDatesReservedProduct(uuid):Observable<any>{
        return this._httpClient.get(`${this.baseUrl}/line-location-order/getAllDatesReservedProduct/${uuid}`,{}).pipe(map(response => {
            return response;
        }));
    }

    getBCReferencesByInvoiceTypeAndModuleType(invoicetype: any, moduleType: any): Observable<any> {
        return this._httpClient.get<any>(`${this.baseUrl}/purshaseorder/get-references-by-invoice-type-and-module-type/${invoicetype}/${moduleType}`).pipe(
            map(response => {
                return response;
            })
        );
    }

    getBLReferencesByInvoiceTypeAndModuleType(invoicetype: any, moduleType: any): Observable<any> {
        return this._httpClient.get<any>(`${this.baseUrl}/delivery-form/get-references-by-invoice-type-and-module-type/${invoicetype}/${moduleType}`).pipe(
            map(response => {
                return response;
            })
        );
    }


    getLineModelsByPurshaseOrder(id: Number): Observable<LineModel[]> {
        return this._httpClient.get<LineModel[]>(`${this.baseUrl}/line-purshase/${id}/line-models`);
    }

    getLineModelsByDeliveryId(id: Number): Observable<LineModel[]> {
        return this._httpClient.get<LineModel[]>(`${this.baseUrl}/line-delivery/${id}/line-models`);
    }
    getLineModelsByInvoiceId(id: Number): Observable<LineModel[]> {
        return this._httpClient.get<LineModel[]>(`${this.baseUrl}/line-model/${id}/line-models`);
    }
    getLineModelsByDevisId(id: Number): Observable<LineModel[]> {
        return this._httpClient.get<LineModel[]>(`${this.baseUrl}/line-devis/${id}/line-models`);
    }
    updateInvoiceModel(updateInvoiceModelDto: UpdateInvoiceModel): Observable<any> {
        return this._httpClient.put<any>(`${this.baseUrl}/invoice/update-model`, updateInvoiceModelDto);
    }

    createCounter(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/counter`, payload);
    }
    updateCounter(payload: CounterModel): Observable<any> {
        return this._httpClient.put<any>(`${this.baseUrl}/counter/update`, payload);
    }
    getCounterPerPage(options: ViewOptions):Observable<CounterModel> {
        let params = new HttpParams();
        params = params.set('pageSize', options.pageSize);
        params = params.set('pageIndex', options.pageNum);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);
        return this._httpClient.get<CounterModel>(`${this.baseUrl}/counter/perPage`, {params: params})
    }
    deleteCounter(uuid: string) {
        return this._httpClient.delete(`${this.baseUrl}/counter/delete/${uuid}`);
    }
    getAllPartnerPayment(options: ViewOptions, tierName?: string, startDate?: string, endDate?: string): Observable<any> {
        let params = new HttpParams();
        if (tierName) {
            params = params.set('tierName', tierName);
        }
        if (startDate) {
            params = params.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            params = params.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }

        params = params.set('pageSize', options.pageSize);
        params = params.set('pageIndex', options.pageNum);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);
        return this._httpClient.get<FactureModel[]>(`${this.baseUrl}/invoice/getAllPartnerPayment`, {params: params})
    }

    getReviewsBilling(uuidPartner:string){
        let params = new HttpParams();
        params = params.set('uuidPartner', uuidPartner);
        return this._httpClient.get(`${this.baseUrl}/common-apis-billing/check-mvt-depot-using-bc`, {params: params});
    }

    generateVerification(payload) {
        return this._httpClient.post(`${this.baseUrl}/common-apis-billing/get-missing-mvt-for-bc`, payload);
    }

    addMiisedMvt(payload: { rapportBillingDtos: SubscriptionModel[] }) {
        return this._httpClient.post(`${this.baseUrl}/common-apis-billing/create-missing-mvt-for-bc`, payload);
    }

    addNewCurrencyExchangeRate(type:string, nature: string,source: string, target:string[] , rate? : string , rateType? : string): Observable<any>{
        let params = new HttpParams()
        if (type) {
            params = params.append('type',type)
        }
        if (nature) {
            params = params.append('nature', nature);
        }
        if (rate) {
            params = params.append('rate', rate);
        }
        if (rateType) {
            params = params.append('rateType', rateType);
        }
        params = params.append('source', source);
        params = params.append('target',target.join(','))
        return this._httpClient.post<any>(`${this.baseUrl}/currency`,{},{params})
    }

    getCurrenciesPerPage(options: ViewOptions , rateType : string): Observable<any>{
        let params = new HttpParams();
        params = params.set('pageSize', options.pageSize);
        params = params.set('pageIndex', options.pageNum);
        params = params.set('sortDirection', options.sortDirection);
        params = params.set('sortField', options.sortField);
        if(rateType){
            params = params.set('rateType', rateType);
        }
        return this._httpClient.get<CounterModel>(`${this.baseUrl}/currency/perPage`, {params: params})
    }
    updateRateType(rateType: any): Observable<any> {
        const params = new HttpParams().set('rateType', rateType.toString());
        return this._httpClient.put<any>(`${this.baseUrl}/setting/updateRateType`, null, { params });
    }
    getRateType(): Observable<any> {
        return this._httpClient.get<any>(`${this.baseUrl}/setting/rateType`);
    }
    deleteCurrency(uuid: string) {
        return this._httpClient.delete(`${this.baseUrl}/currency/delete/${uuid}`);
    }
    getLastCurrency(rateType : string , source : string ,target:string): Observable<any>{
        let params = new HttpParams();
        if(rateType){
            params = params.set('rateType', rateType);
        }
        params = params.append('source', source);
        params = params.append('target',target)
        return this._httpClient.get<CounterModel>(`${this.baseUrl}/currency/getLastCurrency`, {params: params})
    }
    getRegulationsByPage(payload: any): Observable<any> {
        let params = new HttpParams()
            .set('pageIndex', payload.pageIndex)
            .set('pageSize', payload.pageSize)
            .set('sortField', payload.sortField)
            .set('sortDirection', payload.sortDirection);

        if (payload.startDate) {
            params = params.set('startDate', payload.startDate);
        }
        if (payload.endDate) {
            params = params.set('endDate', payload.endDate);
        }
        if (payload.regulationType) {
            params = params.set('regulationType', payload.regulationType);
        }
        if (payload.reference) {
            params = params.set('reference', payload.reference);
        }
        if (payload.invoicetype)
        {
            params = params.set('invoicetype', payload.invoicetype);

        }
        if (payload.tierUuid){
            params = params.set('tierUuid', payload.tierUuid);
        }
        return this._httpClient.get(`${this.baseUrl}/regulation/perPage`, { params: params });
    }

    generateReference(type : string): Observable<string> {
        return this._httpClient.get(`${this.baseUrl}/regulation/generateReference/${type}`, { responseType: 'text' });
    }

    createRegulation(regulation: any): Observable<any> {
        return this._httpClient.post(`${this.baseUrl}/regulation`, regulation);
    }

    deleteRegulation(regulationId: number): Observable<any> {
        return this._httpClient.delete(`${this.baseUrl}/regulation/${regulationId}`);
    }

    getInvoicesRegulationByRegulation(regulationId: number): Observable<any> {
        return this._httpClient.get(`${this.baseUrl}/regulation/${regulationId}/invoices`);
    }

    getRegulationsByInvoiceUuid(invoiceUuid: string,selectionType : string): Observable<any[]> {
        let params = new HttpParams()
        .set('invoiceUuid', invoiceUuid)
        .set('selectionType', selectionType)
        return this._httpClient.get<any[]>(`${this.baseUrl}/regulation/invoice-regulations/`,{ params: params });
    }
    deleteTax(payload: any) {
        return this._httpClient.delete(`${this.baseUrl}/tax/delete-tax`, {
            params: payload
        }).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    private selectedTabIndex: number = 0;

    setSelectedTab(index: number) {
        this.selectedTabIndex = index;
    }

    getSelectedTab(): number {
        return this.selectedTabIndex;
    }

    getDeliveryReferences(uuids: string[]): Observable<{ uuid: string; reference: string }[]> {
        return this._httpClient.get<{ uuid: string; reference: string }[]>(`${this.baseUrl}/delivery-form/invoiceNumber`, {
          params: new HttpParams().set('uuids', uuids.join(',')),
        });
      }

    getProductsBySeller(sellerUuid: string, options: ViewOptions, reference?: string, startDate?: string, endDate?: string): Observable<any> {
        let queryParams = new HttpParams()
        if (reference) {
            queryParams = queryParams.set('productReference', reference);
        }
        if (startDate) {
            queryParams = queryParams.set('startDate', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            queryParams = queryParams.set('endDate', moment(endDate).format('YYYY-MM-DD'));
        }
        queryParams = queryParams.set('pageNum', options.pageNum);
        queryParams = queryParams.set('pageSize', options.pageSize);
        queryParams = queryParams.set('sortDirection', options.sortDirection);
        queryParams = queryParams.set('sortField', options.sortField);
        return this._httpClient.get<ProductGroupDto[]>(`${this.baseUrl}/line-location-order/${sellerUuid}/products`, { params: queryParams });
    }

    getTotalAmountByMonth(sellerUuid: string, year: number): Observable<any> {
        return this._httpClient.get<any>(`${this.baseUrl}/line-location-order/total-amount-by-month`, {
            params: { sellerUuid, year: year.toString() }
        });
    }

    createNoteRepport(payload: any) {
        return this._httpClient.post(`${this.baseUrl}/noterepport/create`, payload).pipe(
            timeout(60000),
            catchError((e, c) => {
                return [];
            }),
            map((response: any) => {
                return response;
            })
        );
    }
    getNoteByTemplateId(templateId: number): Observable<any> {

        return this._httpClient.get<any>(`${this.baseUrl}/noterepport/getNotecheckedByTemplateId/${templateId}`).pipe(map(response => {
            return response;
        }));
    }

    getAllNoteByTemplateId(templateId: number): Observable<any> {

        return this._httpClient.get<any>(`${this.baseUrl}/noterepport/getAllByTemplateId/${templateId}`).pipe(map(response => {
            return response;
        }));
    }
    getNoteByTemplateIdAndFormId(templateId: number,formId:number): Observable<any> {

        return this._httpClient.get<any>(`${this._httpClient}/noterepport/getNoteByTemplateIdAndFormId/${templateId}/${formId}`).pipe(map(response => {
            return response;
        }));
    }
    updateRewardClaimedStatus(sellerUuid: string, year: number , selectedMonth : number): Observable<any> {
        return this._httpClient.put<any>(`${this.baseUrl}/line-location-order/update-reward-claimed`, {}, {
            params: {
                sellerUuid: sellerUuid,
                year: year.toString(),
                selectedMonth : selectedMonth
            }
        });
    }

    getRewardClaimedStatus(sellerUuid: string, year: number, month: number): Observable<boolean> {
        const params = new HttpParams()
            .set('sellerUuid', sellerUuid)
            .set('year', year.toString())
            .set('month', month.toString());

        return this._httpClient.get<boolean>(`${this.baseUrl}/line-location-order/reward-claimed-status`, { params });
    }
    getAllLocationOrdersForRegulation(options: any,  reference? : string ):Observable<any> {
        let queryParams = new HttpParams();
        if(reference){
            queryParams = queryParams.set('reference', reference);
        }
        queryParams = queryParams.append("pageIndex", options.pageIndex);
        queryParams = queryParams.append("pageSize", options.pageSize);
        if (options.sortField) (queryParams = queryParams.append("sortField", options.sortField));
        if (options.sortDirection) queryParams = queryParams.append("sortDirection", options.sortDirection);
        return this._httpClient.get(`${this.baseUrl}/location-order/getLocationOrderWithInvoiceDto`, {params: queryParams});
    }

}
